import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import settingsService from '../../services/settingsService'
import { setCurrencies, setLanguages } from '../../slices/settingsSlice'
import { setUserCurrency, setUserLang } from '../../slices/userSlice'
import TranslatedContent from '../translations/translatedContent'

const StdSettingsPanel = (props) => {

  const user = useSelector(state => state.user)
  const settings = useSelector(state => state.settings)

  const dispatch = useDispatch()

  const [configLangs, setConfigLangs] = useState(settings.languages)
  const [configCurrs, setConfigCurrs] = useState(settings.currencies)

  const [selectedLang, setSelectedLang] = useState(user.lang)
  const [selectedCurr, setSelectedCurr] = useState(user.currency)

  useEffect(() => {
    if (props.open) {
      if (!Array.isArray(settings.languages) || settings.languages.length === 0) {
        settingsService.getLanguages(user.token, dispatch)
          .then(res => {
            dispatch(setLanguages(res.data.data))
          })
          .catch(err => console.error(err))
      }
      if (!Array.isArray(settings.currencies) || settings.currencies.length === 0) {
        settingsService.getCurrencies(user.token, dispatch)
          .then(res => {
            dispatch(setCurrencies(res.data.data))
          })
          .catch(err => console.error(err))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token, props.open])

  useEffect(() => {
    setSelectedLang(user.lang)
  }, [user.lang])

  useEffect(() => {
    setSelectedCurr(user.currency)
  }, [user.currency])

  useEffect(() => {
    setConfigLangs(settings.languages)
    setConfigCurrs(settings.currencies)
  }, [settings])

  const handleApplySettingsClicked = () => {
    settingsService.setLanguage(user.token, dispatch, selectedLang)
      .then((res) => {
        if (res.status === 200)
          dispatch(setUserLang(selectedLang))
          window.location.reload(false);
      })
      .catch(err => console.error(err))
    settingsService.setCurrency(user.token, dispatch, selectedCurr)
      .then((res) => {
        if (res.status === 200)
          dispatch(setUserCurrency(selectedCurr))
      })
      .catch(err => console.error(err))
    props.close()
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', mt: '8rem' }}>
        <Typography sx={{ color: 'white', fontSize: '3rem', fontWeight: 600 }}>
          <TranslatedContent code='settings' defaultValue='Settings' />
        </Typography>
        <IconButton sx={{ alignSelf: 'flex-end' }}
          onClick={(e) => {
            e.preventDefault()
            props.close()
          }}>
          <CloseIcon sx={{ color: 'white', fontSize: '5rem' }} />
        </IconButton>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2, my: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', height: '100%', mb: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
            <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 600 }}>
              <TranslatedContent code='language' defaultValue='Language' />
            </Typography>
            {configLangs.map((l, idx) => {
              let active = (l.code === selectedLang)
              return (
                <Button disabled key={idx} startIcon={<img src={'/img/common/languages/' + l.code + '.gif'} alt={l.code} height='18px' width='27px' />}
                  sx={{
                    color: active ? 'black' : 'white',
                    backgroundColor: active ? 'white' : 'gainsboro',
                    border: '1px solid white',
                    borderRadius: 25,
                    width: '40%',
                    my: .5,
                    '&:hover': {
                      color: active ? 'black' : 'white',
                      backgroundColor: active ? 'white' : 'transparent',
                    },
                    '&:disabled': {
                      color: active ? 'black' : 'darkgrey'
                    }
                  }}
                  onClick={() => setSelectedLang(l.code)}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {l.description}
                  </Typography>
                </Button>
              )
            })}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
            <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 600 }}>
              <TranslatedContent code='currency' defaultValue='Currency' />
            </Typography>
            {configCurrs.map((c, idx) => {
              let active = (c.code === selectedCurr)
              return (
                <Button  key={idx}
                  sx={{
                    color: 'black',
                    backgroundColor: active ? 'white' : 'grey',
                    border: '1px solid white',
                    borderRadius: 25,
                    width: '40%',
                    my: .5,
                    '&:hover': {
                      color: active ? 'black' : 'white',
                      backgroundColor: active ? 'white' : 'transparent',
                    },
                    '&:disabled': {
                      color: active ? 'black' : 'darkgrey'
                    }
                  }}
                  onClick={() => setSelectedCurr(c.code)}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {c.code} | {c.name}
                  </Typography>
                </Button>
              )
            })}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button sx={{
            color: 'black',
            fontSize: '1.3rem',
            backgroundColor: '#A1CBC2',
            height: 30,
            width: 150,
            borderRadius: 28,
            m: .5,
            '&:hover': {
              backgroundColor: '#A1CBC2',
            }
          }}
            onClick={handleApplySettingsClicked}>
            <TranslatedContent code='applySettings' defaultValue='Apply Settings' />
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}

export default StdSettingsPanel