import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { AppBar, Box, ClickAwayListener, Container, Drawer, IconButton, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Fragment, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BurgerIcon } from '../../../icons/burger.svg'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import { ReactComponent as SettingsIcon } from '../../../icons/settings.svg'
import { ReactComponent as LastUpdateIcon } from '../../../icons/time.svg'
import StdSearchPanel from '../../data/stdSearchPanel'
import StdSettingsPanel from '../../data/stdSettingsPanel'
import StdTicker from '../../data/stdTicker'
import TranslatedContent from '../../translations/translatedContent'
import StdMenuContent from '../stdMenuContent'

const StdAppBar = () => {

  const settings = useSelector((state) => state.settings)

  const navigate = useNavigate()
  const isBreakpointUpMd = useMediaQuery(theme => theme.breakpoints.up('md'))

  const [searchOverlayOpen, setSearchOverlayOpen] = useState(false)
  const [settingsOverlayOpen, setSettingsOverlayOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)

  const toggleSearchOverlayOpen = (e) => {
    e.stopPropagation()
    setMobileMenuOpen(false)
    setSettingsOverlayOpen(false)
    setSearchOverlayOpen(!searchOverlayOpen)
  }

  const toggleSettingsOverlayOpen = (e) => {
    e.stopPropagation()
    setMobileMenuOpen(false)
    setSearchOverlayOpen(false)
    setSettingsOverlayOpen(!settingsOverlayOpen)
  }

  const toggleMobileMenuOpen = (e) => {
    e.stopPropagation()
    setSettingsOverlayOpen(false)
    setSearchOverlayOpen(false)
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const toggleFullScreenMode = (e) => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
      setFullscreen(false)
    } else {
      document.body.requestFullscreen()
      setFullscreen(true)
    }
  }

  useLayoutEffect(() => {
    document.onfullscreenchange = () =>
      setFullscreen(document[getBrowserFullscreenElementProp()] != null)
    return () => { document.onfullscreenchange = undefined }
  })

  const getBrowserFullscreenElementProp = () => {
    if (typeof document.fullscreenElement !== 'undefined') {
      return 'fullscreenElement'
    } else if (typeof document.mozFullScreenElement !== 'undefined') {
      return 'mozFullScreenElement'
    } else if (typeof document.msFullscreenElement !== 'undefined') {
      return 'msFullscreenElement'
    } else if (typeof document.webkitFullscreenElement !== 'undefined') {
      return 'webkitFullscreenElement'
    } else {
      throw new Error('fullscreenElement is not supported by this browser')
    }
  }

  return (
    <Fragment>
      <AppBar sx={{
        position: 'fixed',
        backgroundColor: '#31495A',
        height: '65px'
      }}>
        <Container maxWidth='false' sx={{ height: '100%', px: { xs: 2, sm: 4 } }}>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, minWidth: '94px' }}>
                <IconButton onClick={toggleMobileMenuOpen}>
                  <BurgerIcon stroke='#FFFFFF' />
                </IconButton>
                <IconButton
                  id='stdSearchBtn'
                  sx={{
                    borderRadius: 50,
                    height: 50,
                    width: 50,
                    backgroundColor: '#04A8A3',
                    mx: 1,
                    padding: 1,
                    '&:hover': {
                      backgroundColor: '#04A8A3'
                    }
                  }}
                  onClick={toggleSearchOverlayOpen}>
                  <SearchIcon id='stdSearchIcon' fill='white' viewBox='-4 -3 35 35' />
                </IconButton>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'center',
                  mr: 1
                }}
                onClick={() => navigate('/dashboard')}>
                <img src='/img/common/stdLogo.svg' alt='StdFootball' height='50px' />
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: 1
                }}>
                <Tooltip placement='bottom'>
                  <LastUpdateIcon fill='#A1CBC2' style={{ marginRight: '.8rem' }} />
                </Tooltip>
                <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                  <Typography noWrap sx={{ textTransform: 'uppercase', color: '#A1CBC2', fontSize: '1.5rem' }}>
                    <TranslatedContent code='lastUpdate' defaultValue='Last Update' />:
                  </Typography>
                  <Typography sx={{ mx: 1, textTransform: 'uppercase', color: '#A1CBC2', fontSize: '1.5rem' }}>
                    {settings.lastUpdate}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ flex: 6, width: '60%' }}>
              {isBreakpointUpMd && <StdTicker />}
              <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'none' }, justifyContent: 'center' }}>
                <img src='/img/common/stdLogo.svg' alt='StdFootball' height='50px' />
              </Box>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }}>
                <img src='/img/common/stdLogoSmall.png' alt='StdFootball' height='50px' />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', minWidth: '94px' }}>
              <IconButton
                id='stdSearchBtn'
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  borderRadius: 50,
                  height: 50,
                  width: 50,
                  backgroundColor: '#04A8A3',
                  mx: 1,
                  padding: 1,
                  '&:hover': {
                    backgroundColor: '#04A8A3'
                  }
                }}
                onClick={toggleSearchOverlayOpen}>
                <SearchIcon id='stdSearchIcon' fill='white' viewBox='-4 -3 35 35' />
              </IconButton>
              {/* ICONA NOTIFICHE (usa mui badge per il numero di notifiche in piccolo)
               <IconButton sx={{
                borderRadius: 50,
                height: 50,
                width: 50,
                backgroundColor: 'white',
                mx: 1,
                padding: 1,
                '&:hover': {
                  backgroundColor: 'white'
                }
              }}>
                <NotificationsNoneIcon sx={{ color: '#04a8a3' }} />
              </IconButton>
              */}
              <IconButton
                sx={{
                  borderRadius: 50,
                  height: 50,
                  width: 50,
                  backgroundColor: '#15222B',
                  mx: 1,
                  padding: 1,
                  '&:hover': {
                    backgroundColor: '#15222B'
                  }
                }}
                onClick={toggleSettingsOverlayOpen}>
                <SettingsIcon fill='#FFFFFF' />
              </IconButton>
              <IconButton sx={{
                display: { xs: 'none', md: 'block' },
                height: 50,
                width: 50
              }}
                onClick={toggleFullScreenMode}>
                {
                  fullscreen ?
                    <FullscreenExitIcon sx={{ color: '#FFFFFF', width: 50, height: 50 }} />
                    :
                    <FullscreenIcon sx={{ color: '#FFFFFF', width: 50, height: 50 }} />
                }
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ClickAwayListener onClickAway={() => {
        if (searchOverlayOpen) {
          setSearchOverlayOpen(false)
        }
      }}>
        <Box sx={{
          position: 'fixed',
          right: { xs: 'unset', md: searchOverlayOpen ? 0 : '-100vw' },
          left: { xs: searchOverlayOpen ? 0 : '-100vw', md: 'unset' },
          zIndex: 500,
          display: 'flex',
          flexDirection: 'column',
          height: { xs: 'calc(100% - 65px)', md: '100%' },
          width: { xs: '100%', sm: 500 },
          backgroundColor: '#0D0D0D',
          transition: 'right .2s ease-in-out, left .2s ease-in-out',
          px: 5
        }}>
          <StdSearchPanel open={searchOverlayOpen} close={() => setSearchOverlayOpen(false)} />
        </Box>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={() => {
        if (settingsOverlayOpen) {
          setSettingsOverlayOpen(false)
        }
      }}>
        <Box sx={{
          position: 'fixed',
          right: settingsOverlayOpen ? 0 : '-100vw',
          zIndex: 500,
          display: 'flex',
          flexDirection: 'column',
          height: { xs: 'calc(100% - 65px)', md: '100%' },
          width: { xs: '100%', sm: 500 },
          backgroundColor: '#31495A',
          transition: 'right .2s ease-in-out',
          px: 5
        }}>
          <StdSettingsPanel open={settingsOverlayOpen} close={() => setSettingsOverlayOpen(false)} />
        </Box>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={() => {
        if (mobileMenuOpen) {
          setMobileMenuOpen(false)
        }
      }}>
        <Drawer
          variant='persistent'
          open={mobileMenuOpen}>
          <StdMenuContent open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
        </Drawer>
      </ClickAwayListener>
    </Fragment >
  )
}

export default StdAppBar