import { Box, Grid, MenuItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../services/filtersService'
import statisticsService from '../../services/statisticsService'
import { setClubsTotal } from '../../slices/clubsSlice'
import { setLeagueGroups, setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import {
  currencyUomRenderer,
  decimalValueWithPercRenderer,
  integerNumberRenderer,
  integerValueWithPercRenderer,
  monthsToYearsMonthsUomRenderer,
  tierRenderer,
  yearsToYearsMonthsUomRenderer
} from '../data/formatters/dataFormatters'
import StdVerticalGrid from '../data/stdVerticalGrid'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import LeagueLogo from './leagueLogo'
import LeagueLogoSmall from './leagueLogoSmall'

const LeagueProfile = (props) => {

  const leaguesTotal = useSelector(state => state.leagues.total)
  const leagueGroups = useSelector(state => state.leagues.leagueGroups)

  const [numOfLeagues, setNumOfLeagues] = useState(leaguesTotal)
  const [numOfLeagueGroups, setNumOfLeagueGroups] = useState(leagueGroups.length)
  const [leagueSalaryPercDeviation, setLeagueSalaryPercDeviation] = useState(0)
  const [shareOfForeignPlayers, setShareOfForeignPlayers] = useState(0)
  const [shareOfU23Players, setShareOfU23Players] = useState(0)

  const emptyGeneralData = [
    { field: 'name', name: 'leagueOfficialName', value: '-' },
    { field: 'confederation', name: 'leagueConfederation', value: '-' },
    { field: 'memberAssociation', name: 'leagueMemberAssociation', value: '-' },
    { field: 'fifaRank', name: 'leagueFifaRanking', value: '-' },
    { field: 'country', name: 'leagueCountry', value: '-' },
    { field: 'cluster', name: 'leagueTier', value: '-', valueRenderer: tierRenderer(numOfLeagueGroups) },
    { field: 'numOfClubs', name: 'leagueNumOfClubs', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfPlayers', name: 'leagueNumOfPlayers', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfForeignPlayers', name: 'leagueNumOfForeignPlayers', value: '-', valueRenderer: integerValueWithPercRenderer(shareOfForeignPlayers) },
    { field: 'numOfU23Players', name: 'leagueNumOfU23Players', value: '-', valueRenderer: integerValueWithPercRenderer(shareOfU23Players) },
    { field: 'avgAge', name: 'leagueAvgAge', value: '-', valueRenderer: yearsToYearsMonthsUomRenderer },
    { field: 'avgContractDuration', name: 'leagueAvgContractDuration', value: '-', valueRenderer: monthsToYearsMonthsUomRenderer },
    { field: 'avgContractResidualDuration', name: 'leagueAvgContractResidualDuration', value: '-', valueRenderer: monthsToYearsMonthsUomRenderer },
    { field: 'eligibleForCups', name: 'leagueEligibleForCups', value: '-' },
    { field: 'relegations', name: 'leagueRelegation', value: '-' },
    { field: 'promotions', name: 'leaguePromotion', value: '-', valueRenderer: ({ value }) => value === 0 ? 'N/A' : value }
  ]

  const emptySplitGeneralData1 = [
    { field: 'name', name: 'leagueOfficialName', value: '-' },
    { field: 'confederation', name: 'leagueConfederation', value: '-' },
    { field: 'memberAssociation', name: 'leagueMemberAssociation', value: '-' },
    { field: 'fifaRank', name: 'leagueFifaRanking', value: '-' },
    { field: 'country', name: 'leagueCountry', value: '-' }
  ]

  const emptySplitGeneralData2 = [
    { field: 'numOfClubs', name: 'leagueNumOfClubs', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfPlayers', name: 'leagueNumOfPlayers', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfForeignPlayers', name: 'leagueNumOfForeignPlayers', value: '-', valueRenderer: integerValueWithPercRenderer(shareOfForeignPlayers) },
    { field: 'numOfU23Players', name: 'leagueNumOfU23Players', value: '-', valueRenderer: integerValueWithPercRenderer(shareOfU23Players) },
    { field: 'avgAge', name: 'leagueAvgAge', value: '-', valueRenderer: yearsToYearsMonthsUomRenderer }
  ]

  const emptySplitGeneralData3 = [
    { field: 'cluster', name: 'leagueTier', value: '-', valueRenderer: tierRenderer(numOfLeagueGroups) },
    { field: 'eligibleForCups', name: 'leagueEligibleForCups', value: '-' },
    { field: 'relegations', name: 'leagueRelegation', value: '-' },
    { field: 'promotions', name: 'leaguePromotion', value: '-', valueRenderer: ({ value }) => value === 0 ? 'N/A' : value },
    { field: 'avgContractDuration', name: 'leagueAvgContractDuration', value: '-', valueRenderer: monthsToYearsMonthsUomRenderer }
  ]

  const emptyValueData = [
    { field: 'potentialPrice', name: 'leagueFairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankByValue', name: 'leagueRankByValue', value: '-', valueRenderer: tierRenderer(numOfLeagues) },
    { field: 'portfolioCost', name: 'leagueAcquisitionCost', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankByAcquisition', name: 'leagueRankByAcquisition', value: '-', valueRenderer: tierRenderer(numOfLeagues) },
    { field: 'residualBookValue', name: 'leagueResidualBookValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'residualValue', name: 'leagueResidualValue', value: '-', valueRenderer: currencyUomRenderer }
  ]

  const emptySalaryData = [
    { field: 'salaryGross', name: 'leagueSalaryGross', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankBySalary', name: 'leagueRankBySalary', value: '-', valueRenderer: tierRenderer(numOfLeagues) },
    { field: 'salaryNet', name: 'leagueSalaryNet', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryOptimal', name: 'leagueSalaryFair', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryAbsoluteDeviation', name: 'leagueSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(leagueSalaryPercDeviation) }
  ]

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [displayState, setDisplayState] = useState('general')
  const [generalData, setGeneralData] = useState(emptyGeneralData)
  const [splitGeneralData1, setSplitGeneralData1] = useState(emptySplitGeneralData1)
  const [splitGeneralData2, setSplitGeneralData2] = useState(emptySplitGeneralData2)
  const [splitGeneralData3, setSplitGeneralData3] = useState(emptySplitGeneralData3)
  const [valueData, setValueData] = useState(emptyValueData)
  const [salaryData, setSalaryData] = useState(emptySalaryData)

  useEffect(() => {
    setNumOfLeagues(leaguesTotal)
  }, [leaguesTotal])

  useEffect(() => {
    setNumOfLeagueGroups(leagueGroups.length)
  }, [leagueGroups])

  useEffect(() => {
    if (props.league) {
      setShareOfForeignPlayers(props.league.shareOfForeignPlayers)
      setShareOfU23Players(props.league.shareOfU23Players)
      setLeagueSalaryPercDeviation(props.league.salaryPercDeviation)
    }
  }, [props.league])

  useEffect(() => {
    if (leaguesTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (!Array.isArray(leagueGroups) || leagueGroups.length === 0) {
      filtersService.getLeagueGroups(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeagueGroups(res.data.data.groups))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (props.league) {
      if (Object.keys(props.league).length > 0) {
        let tmpGeneralData = []
        let sourceGeneralDataArray = [...emptyGeneralData]
        let tmpSplitGeneralData1 = []
        let sourceSplitGeneralData1Array = [...emptySplitGeneralData1]
        let tmpSplitGeneralData2 = []
        let sourceSplitGeneralData2Array = [...emptySplitGeneralData2]
        let tmpSplitGeneralData3 = []
        let sourceSplitGeneralData3Array = [...emptySplitGeneralData3]
        let tmpValueData = []
        let sourceValueData = [...emptyValueData]
        let tmpSalaryData = []
        let sourceSalaryData = [...emptySalaryData]

        sourceGeneralDataArray.forEach((row) => {
          tmpGeneralData.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setGeneralData(tmpGeneralData)

        sourceSplitGeneralData1Array.forEach((row) => {
          tmpSplitGeneralData1.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSplitGeneralData1(tmpSplitGeneralData1)

        sourceSplitGeneralData2Array.forEach((row) => {
          tmpSplitGeneralData2.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSplitGeneralData2(tmpSplitGeneralData2)

        sourceSplitGeneralData3Array.forEach((row) => {
          tmpSplitGeneralData3.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSplitGeneralData3(tmpSplitGeneralData3)

        sourceValueData.forEach((row) => {
          tmpValueData.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setValueData(tmpValueData)

        sourceSalaryData.forEach((row) => {
          tmpSalaryData.push({ field: row.field, name: row.name, value: props.league[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSalaryData(tmpSalaryData)
      } else {
        setGeneralData(emptyGeneralData)
        setSplitGeneralData1(emptySplitGeneralData1)
        setSplitGeneralData2(emptySplitGeneralData2)
        setSplitGeneralData3(emptySplitGeneralData3)
        setValueData(emptyValueData)
        setSalaryData(emptySalaryData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.league, numOfLeagues, numOfLeagueGroups, shareOfForeignPlayers, shareOfU23Players, leagueSalaryPercDeviation])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          backgroundColor: '#31495A',
          borderRadius: '20px',
          p: { xs: 2, md: 4 },
          //height: 250,
          transition: 'height .2s ease-in-out',
          boxShadow: '0 15px 3px rgba(0, 0, 0, .3)'
        }}>
        <Box sx={{ flex: .3, height: '100%', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', mx: { xs: 0, md: 2 } }}>
          <Typography sx={{ display: { xs: 'none', xl: 'block' }, color: '#FFFFFF', textTransform: 'uppercase', fontSize: '2.5rem', fontWeight: 600, mb: 1 }}>
            <TranslatedContent code='profile' defaultValue='Profile' />
          </Typography>
          <LeagueLogo leagueId={props.league?.leagueId} show={props.league?.leagueId && props.league?.leagueId !== ''} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 'auto', alignItems: 'center', height: '100%' }}>
          <Box sx={{ display: { xs: 'flex', xl: 'none' }, justifyContent: 'space-between', alignItems: 'center', height: 55, width: '100%', px: 2, my: 1 }}>
            <Typography sx={{ display: { xs: 'none', md: 'block', xl: 'none' }, color: '#FFFFFF', textTransform: 'uppercase', fontSize: '2.5rem', fontWeight: 600 }}>
              <TranslatedContent code='profile' defaultValue='Profile' />
            </Typography>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <LeagueLogoSmall leagueId={props.league?.leagueId} show={props.league?.leagueId && props.league?.leagueId !== ''} />
            </Box>
            <StdSelect
              value={displayState}
              onChange={(e) => setDisplayState(e.target.value)}
              sx={{
                display: { xs: 'inherit', xl: 'none' },
                width: { xs: 150, sm: 250 }
              }}>
              <MenuItem id='general' key='general' value='general'>
                <TranslatedContent code='general' defaultValue='General' />
              </MenuItem>
              <MenuItem id='value' key='value' value='value'>
                <TranslatedContent code='value' defaultValue='Value' />
              </MenuItem>
              <MenuItem id='salary' key='salary' value='salary'>
                <TranslatedContent code='salary' defaultValue='Salary' />
              </MenuItem>
            </StdSelect>
          </Box>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xl={4} sx={{ display: { xs: displayState === 'general' ? 'block' : 'none', xl: 'none' } }}>
              <StdVerticalGrid title={<TranslatedContent code='general' defaultValue='General' />} data={generalData} />
            </Grid>
            <Grid item xl={4} sx={{ display: { xs: 'none', xl: 'flex', alignItems: 'flex-end' } }}>
              <StdVerticalGrid title={<TranslatedContent code='general' defaultValue='General' />} data={splitGeneralData1} />
            </Grid>
            <Grid item xl={4} sx={{ display: { xs: 'none', xl: 'flex', alignItems: 'flex-end' } }}>
              <StdVerticalGrid data={splitGeneralData2} />
            </Grid>
            <Grid item xl={4} sx={{ display: { xs: 'none', xl: 'flex', alignItems: 'flex-end' } }}>
              <StdVerticalGrid data={splitGeneralData3} />
            </Grid>
            <Grid item xl={4} sx={{ display: { xs: displayState === 'value' ? 'block' : 'none', xl: 'block' } }}>
              <StdVerticalGrid title={<TranslatedContent code='value' defaultValue='value' />} data={valueData} />
            </Grid>
            <Grid item xl={4} sx={{ display: { xs: displayState === 'salary' ? 'block' : 'none', xl: 'block' } }}>
              <StdVerticalGrid title={<TranslatedContent code='salary' defaultValue='Salary' />} data={salaryData} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default LeagueProfile