import { Box, ClickAwayListener } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { styled, useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import StdMenuContent from './stdMenuContent'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const StdMenuDrawer = () => {

  const theme = useTheme()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open) {
      document.getElementById('stdMenu').scroll(0, 0)
    }
  }, [open])

  return (
    <ClickAwayListener onClickAway={() => {
      if (open) {
        setOpen(false)
      }
    }}>
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          display: { xs: 'none', md: 'block' },
          zIndex: '1000',
          position: 'absolute'
        }}>
        <Box sx={{
          ...theme.mixins.toolbar
        }}>
        </Box>
        <StdMenuContent open={open} setOpen={setOpen} />
      </Drawer >
    </ClickAwayListener>
  )
}

export default StdMenuDrawer