import { Box, Button, MenuItem } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'

const PlayerComparablesBarChartButtons = (props) => {

  const userCurrency = useSelector(state => state.user.currency)

  const [btnState, setBtnState] = useState('potentialPrice')

  useEffect(() => {
    switch (btnState) {
      case 'potentialPrice':
      case 'salaryNet':
        props.onChange({ param: btnState, uom: getCurrencySymbol(userCurrency) })
        break
      case 'age':
        props.onChange({ param: btnState, uom: 'Y' })
        break
      case 'latestMicroVolatility':
        props.onChange({ param: btnState, uom: '%' })
        break
      case 'latestUniqueness':
      case 'performance':
        props.onChange({ param: btnState, uom: '' })
        break
      default:
        console.warn('[PlayerComparablesBarChartButtons] unhandled button state: ' + btnState)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrency, btnState])

  return (
    <Stack sx={{ mt: { xs: 0, md: 3 } }}>
      <Stack spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button id='potentialPriceBtn' variant='stdTab' className={btnState === 'potentialPrice' ? 'stdActive' : ''} onClick={() => setBtnState('potentialPrice')}>
          <TranslatedContent code='potentialPrice' defaultValue='Fair Value' />
        </Button>
        <Button id='ageBtn' variant='stdTab' className={btnState === 'age' ? 'stdActive' : ''} onClick={() => setBtnState('age')}>
          <TranslatedContent code='age' defaultValue='Age' />
        </Button>
        <Button id='performanceBtn' variant='stdTab' className={btnState === 'performance' ? 'stdActive' : ''} onClick={() => setBtnState('performance')}>
          <TranslatedContent code='performance' defaultValue='Performance' />
        </Button>
        <Button id='salaryNetBtn' variant='stdTab' className={btnState === 'salaryNet' ? 'stdActive' : ''} onClick={() => setBtnState('salaryNet')}>
          <TranslatedContent code='salary' defaultValue='Salary' />
        </Button>
        <Button id='latestMicroVolatilityBtn' variant='stdTab' className={btnState === 'latestMicroVolatility' ? 'stdActive' : ''} onClick={() => setBtnState('latestMicroVolatility')}>
          <TranslatedContent code='volatility' defaultValue='Volatility' />
        </Button>
        <Button id='latestUniquenessBtn' variant='stdTab' className={btnState === 'latestUniqueness' ? 'stdActive' : ''} onClick={() => setBtnState('latestUniqueness')}>
          <TranslatedContent code='uniqueness' defaultValue='Uniqueness' />
        </Button>
      </Stack>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'center' }}>
        <StdSelect
          value={btnState}
          onChange={(e) => setBtnState(e.target.value)}
          sx={{
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            fontSize: '1.2rem',
            height: 25,
            width: 200,
            border: '1px solid #04A8A3',
            borderRadius: '25px',
            '&:hover': {
              color: '#04A8A3'
            }
          }}>
          <MenuItem id='potentialPrice' key='potentialPrice' value='potentialPrice'>
            <TranslatedContent code='potentialPrice' defaultValue='Fair Value' />
          </MenuItem>
          <MenuItem id='age' key='age' value='age'>
            <TranslatedContent code='age' defaultValue='Age' />
          </MenuItem>
          <MenuItem id='performance' key='performance' value='performance'>
            <TranslatedContent code='performance' defaultValue='Performance' />
          </MenuItem>
          <MenuItem id='salaryNet' key='salaryNet' value='salaryNet'>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </MenuItem>
          <MenuItem id='latestMicroVolatility' key='latestMicroVolatility' value='latestMicroVolatility'>
            <TranslatedContent code='volatility' defaultValue='Volatility' />
          </MenuItem>
          <MenuItem id='latestUniqueness' key='latestUniqueness' value='latestUniqueness'>
            <TranslatedContent code='uniqueness' defaultValue='Uniqueness' />
          </MenuItem>
        </StdSelect>
      </Box>
    </Stack>
  )
}

export default PlayerComparablesBarChartButtons