import Slider from 'react-infinite-logo-slider'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import LeagueLogo from './leagueLogo'

const LeagueSlider = (props) => {

  const navigate = useNavigate()
  const [customLogoSx, setCustomLogoSx] = useState({})
  
  
  const handleCardClick = (leagueId) => {
    // e.preventDefault()
    navigate('/leagues/' + leagueId, { relative: true })
  }

  return (
    <> 
    {props?.leagues.length >= 7 ?
      <Slider duration={120} pauseOnHover={true} blurBorders={false} blurBoderColor={'#fff'}>
      {props?.leagues.map((league) => {
        return (
          <Slider.Slide key={league.leagueId} onClick={() => handleCardClick(league.leagueId)}>
            <LeagueLogo leagueId={league.leagueId} show={true} sx={customLogoSx} />
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', textAlign: 'center', ml: 1, mr: 1 }}>
              <span style={{ fontWeight: 600 }}>{league.name}</span>
              <br />
              <span style={{ fontWeight: 400, opacity: 0.65 }}>{league.country}</span>
            </Typography>
          </Slider.Slide>
        );
      })}
    </Slider> 
    :
      <>
      {props?.leagues.map((league) => 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => handleCardClick(league.leagueId)} key={league.leagueId}>
          <LeagueLogo leagueId={league.leagueId} show={true} sx={customLogoSx} />
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', textAlign: 'center', ml: 1, mr: 1 }}>
            <span style={{ fontWeight: 600 }}>{league.name}</span>
            <br />
            <span style={{ fontWeight: 400, opacity: 0.65 }}>{league.country}</span>
          </Typography>
        </Box>
      )}
      </>
    }
    </>
  );
}

export default LeagueSlider