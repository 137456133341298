import { Box } from '@mui/material'
import StdTicker from '../../data/stdTicker'

const StdAppFooter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#31495A',
        height: '65px',
        alignItems: 'center',
        zIndex: 1100
      }}>
      <StdTicker sx={{ width: '100%' }} />
    </Box>
  )
}

export default StdAppFooter