import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from './navbar'

const Layout = ({ children }) => {

  const location = useLocation()
  const [showHeader, setShowHeader] = useState(false)

  useEffect(() => {
    const protectedRoutes = ['/dashboard', '/leagues', '/clubs', '/players', '/watchlists', '/settings', '/contacts', '/faqs']
    if (protectedRoutes.some(r => location.pathname.startsWith(r))) {
      setShowHeader(true)
    } else {
      setShowHeader(false)
    }
  }, [location])

  return (
    showHeader ?
      <Navbar>{children}</Navbar>
      :
      children
  )
}

export default Layout