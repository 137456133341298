import TuneIcon from '@mui/icons-material/Tune'
import { Box, Divider, Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdCountryFilter from '../../components/data/filters/stdCountryFilter'
import StdLeagueClusterFilter from '../../components/data/filters/stdLeagueClusterFilter'
import StdFilter from '../../components/data/filters/stdFilter'
import StdSelect from '../../components/form/stdSelect'
import LeagueCard from '../../components/leagues/leagueCard'
import TranslatedContent from '../../components/translations/translatedContent'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import { PAGE_ALL } from '../../services/commonService'
import filtersService from '../../services/filtersService'
import leaguesService from '../../services/leaguesService'
import { setLeagueGroups } from '../../slices/leaguesSlice'
import StdLeaguesTable from '../../components/leagues/stdLeaguesTable'
import LeagueSlider from '../../components/leagues/leagueSlider'

const LeaguesPage = () => {

  const initialPage = {
    page: 1, 
    per_page: 44
  }

  const defaultSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  const dispatch = useDispatch()

  const countryFilterRef = useRef()
  const nameSearchRef = useRef()
  const clusterFilterRef = useRef()
  const leagueFilterRef = useRef()
  const revenueFilterRef = useRef()
  
  const userToken = useSelector(state => state.user.token)
  const leagueGroups = useSelector(state => state.leagues.leagueGroups)

  const [leagues, setLeagues] = useState([])
  const [leaguesSlider, setLeaguesSlider] = useState([])
  const [selectedLeagueGroup, setSelectedLeagueGroup] = useState(-1)
  const [filterOpen, setFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})
  const [filterSummary, setFilterSummary] = useState([])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 })
  const [sorting, setSorting] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    filter: {},
    page: {page: 1, per_page : 44},
    sort: { field: 'potentialPrice', sort: 'desc'}
  })

  useEffect(() => {
    if (!Array.isArray(leagueGroups) || leagueGroups.length === 0) {
      filtersService.getLeagueGroups(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeagueGroups(res.data.data.groups))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    leaguesService.getLeagues(userToken, dispatch, queryParams?.page, queryParams?.filter, queryParams?.sort)
      .then(res => {
        if (res.status === 200) {
          setLeagues(res.data.data)
          setRowCount(res.data.meta.total)
        } else {
          setLeagues([])
          setRowCount(0)
        }
      })
      .catch(err => console.error(err))

  }, [userToken, queryParams])

  useEffect(() => {
    let newSort
    if (sorting && sorting.length > 0) {
      newSort = { field: sorting[0].id, sort: sorting[0].desc ? 'desc' : 'asc' }
    } else {
      newSort = defaultSort
    }
    setQueryParams(q => {
      return {
        ...q,
        sort: newSort,
        page: initialPage
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  useEffect(() => {
    setQueryParams(q => {
      return {
        ...q,
        page: { page: pagination.pageIndex + 1, per_page: pagination.pageSize }
      }
    })
  }, [pagination.pageIndex, pagination.pageSize])

  useEffect(() => {
    let cumulativeFilter = { ...filter }
    if (selectedLeagueGroup !== -1) {
      cumulativeFilter = {
        ...cumulativeFilter,
        cluster: selectedLeagueGroup
      }
    }
    const sort = {
      field: 'potentialPrice',
      sort: 'desc'
    }
   
    leaguesService.getLeagues(userToken, dispatch, PAGE_ALL, cumulativeFilter, sort)
      .then(res => {
        if (res.status === 200) {
          setLeagues(res.data.data)
          setLeaguesSlider(res.data.data)
          setRowCount(res.data.meta.total)
        } else {
          setLeagues([])
          setRowCount(0)
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, selectedLeagueGroup, filter])

  const handleLeagueGroupsSelectChange = (e) => {
    e.preventDefault()
    setSelectedLeagueGroup(e.target.value)
  }

  const toggleFilterOpen = (e) => {
    e.preventDefault()
    setFilterOpen(prevState => !prevState)
  }

  const handleApplyFilters = () => {
    let newFilter = {}
    filterSummary.forEach((fs) => {
      newFilter = {
        ...newFilter,
        ...fs.filterContent
      }
    })
    setFilter(newFilter)
  }

  const handleDeleteFilters = () => {
    setFilter({})
    setFilterSummary([])
    countryFilterRef.current.reset()
  }

  const handleFilterChange = (newFilter) => {
    let newFilterSummary = [...filterSummary]
    if (newFilter) {
      let filterIndex = filterSummary.findIndex((fs) => fs.filterName === newFilter.filterName)
      if (newFilter.filterValues && newFilter.filterValues.length > 0) {
        if (filterIndex === -1) {
          newFilterSummary.push(newFilter)
        } else {
          newFilterSummary[filterIndex].filterValues = newFilter.filterValues
          newFilterSummary[filterIndex].filterContent = newFilter.filterContent
        }
      } else {
        if (filterIndex !== -1) {
          newFilterSummary.splice(filterIndex, 1)
        }
      }
    }
    setFilterSummary(newFilterSummary)
  }

  const handleFilterByName = (searchText) => {
    if (searchText && searchText !== '') {
      setFilterSummary([])
      countryFilterRef.current.reset()
      clusterFilterRef.current.reset()
      leagueFilterRef.current.reset()
      revenueFilterRef.current.reset()
      setQueryParams({
        ...queryParams,
        page: initialPage,
        filter: { name: searchText }
      })
    } else {
      setQueryParams({
        page: initialPage,
        filter: {},
        sort: defaultSort
      })
    }
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'left', fontWeight: 'bold' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{
              fontSize: '3rem',
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: '600',
              mr: 2
            }}>
              <TranslatedContent code='leagues' defaultValue='Leagues' />
            </Typography>
            <Tooltip title={<TranslatedContent code='leaguesInfo' description />}>
              <InfoIcon fill='#FFFFFF' height='20px' />
            </Tooltip>
          </Box>
          {/* <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <StdSelect
                value={selectedLeagueGroup}
                onChange={handleLeagueGroupsSelectChange}
                renderValue={(value) => (
                  <Fragment>
                    {value === -1 ?
                      <TranslatedContent code='allLeagueGroups' defaultValue='All League Groups' />
                      :
                      <><TranslatedContent code='leagueGroup' defaultValue='League Group' /> {value}</>}
                  </Fragment>
                )}>
                <MenuItem id={-1} key={-1} value={-1}>
                  <TranslatedContent code='allLeagueGroups' defaultValue='All League Groups' />
                </MenuItem>
                {leagueGroups.map((group, idx) => (
                  <MenuItem id={group} key={idx} value={group}>
                    <TranslatedContent code='leagueGroup' defaultValue='League Group' /> {group}
                  </MenuItem>
                ))}
              </StdSelect>
            </Box>
            <IconButton onClick={toggleFilterOpen} sx={{ backgroundColor: '#FFFFFF', borderRadius: '50%', p: 1, ml: 3, '&:hover': { backgroundColor: '#FFFFFF' } }}>
              <TuneIcon sx={{ color: '#04A8A3' }} />
            </IconButton>
          </Box> */}
        </Box>
        <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />
        {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'center' }}>
          <StdSelect
            value={selectedLeagueGroup}
            onChange={handleLeagueGroupsSelectChange}
            renderValue={(value) => (
              <Fragment>
                {value === -1 ?
                  <TranslatedContent code='allLeagueGroups' defaultValue='All League Groups' />
                  :
                  <><TranslatedContent code='leagueGroup' defaultValue='League Group' /> {value}</>}
              </Fragment>
            )}>
            <MenuItem id={-1} key={-1} value={-1}>
              <TranslatedContent code='allLeagueGroups' defaultValue='All League Groups' />
            </MenuItem>
            {leagueGroups.map((group, idx) => (
              <MenuItem id={group} key={idx} value={group}>
                <TranslatedContent code='leagueGroup' defaultValue='League Group' /> {group}
              </MenuItem>
            ))}
          </StdSelect>
        </Box> */}
        <Grid container spacing={1}
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            my: 1,
            '&::after': {
              content: '""',
              position: 'sticky',
              bottom: 0,
              height: 50,
              pointerEvents: 'none',
              width: '100%',
              backgroundImage: 'linear-gradient(to bottom, transparent, #15222B)',
            }
          }}>
          <LeagueSlider leagues={leaguesSlider} />
          
          {/* {leagues.map((league, index) => {
            return (
              <Grid item key={index} xs={4} sm={3} md={2} lg={1.5} xl={1.2} sx={{ my: 1 }}>
                <LeagueCard
                  leagueId={league.leagueId}
                  name={league.name}
                  country={league.country}
                />
              </Grid>
            )
          })} */}
        </Grid>
      </Box>
      <StdFilter open={filterOpen} setOpen={setFilterOpen} filterSummary={filterSummary} onApply={handleApplyFilters} onDelete={handleDeleteFilters}>
        <StdCountryFilter ref={countryFilterRef} onChange={handleFilterChange} filterName='countryCode' sx={{ my: 2 }} />
        <StdLeagueClusterFilter ref={clusterFilterRef} onChange={handleFilterChange} filterName='cluster' sx={{ my: 2 }} />
      </StdFilter>
      <StdLeaguesTable 
        ref={nameSearchRef}
        clubs={leagues}
        enablePagination
        manualPagination
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        page={queryParams.page.page - 1}
        pageSize={44}
        rowCount={rowCount}
        filterByName={handleFilterByName}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
      />
    </Fragment>
  )
}

export default LeaguesPage