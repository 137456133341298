import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auth: false,
  infoLoaded: false,
  token: '',
  name: '',
  email: '',
  currency: '',
  lang: '',
  myclub: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.name = action.payload.name
      state.email = action.payload.email
      state.currency = action.payload.currency
      state.lang = action.payload.lang
      state.myclub = action.payload.myclub
      state.infoLoaded = true
    },
    setAuth(state, action) {
      state.auth = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setUserLang(state, action) {
      state.lang = action.payload
    },
    setUserCurrency(state, action) {
      state.currency = action.payload
    },
    clearSession(state, _action) {
      state.auth = false
      state.infoLoaded = false
      state.token = ''
      state.name = ''
      state.email = ''
      state.currency = ''
      state.lang = ''
      state.myclub = null
    },
  },
})

export const {
  setUserInfo,
  setAuth,
  setToken,
  setUserLang,
  setUserCurrency,
  clearSession
} = userSlice.actions