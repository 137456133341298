import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../../services/authService'
import filtersService from '../../services/filtersService'
import settingsService from '../../services/settingsService'
import statisticsService from '../../services/statisticsService'
import translationsService from '../../services/translationsService'
import watchlistsService from '../../services/watchlistsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import { setLastUpdate } from '../../slices/settingsSlice'
import { addClusterStatistics, setClusterStatsLoaded, setStatistics } from '../../slices/statisticsSlice'
import { setDictionary } from '../../slices/translationsSlice'
import { setAuth, setToken, setUserInfo } from '../../slices/userSlice'
import { setPersonalWatchlists } from '../../slices/watchlistsSlice'
import StdFootballLoadingPage from '../layout/stdFootballLoadingPage'

const AuthGuard = ({ children }) => {

  const user = useSelector(state => state.user)
  const settings = useSelector(state => state.settings)
  const translations = useSelector(state => state.translations)
  const watchlists = useSelector(state => state.watchlists)
  const statistics = useSelector(state => state.statistics)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!user.auth || !user.token) {
      const cookieToken = authService.getCookieToken()
      if (cookieToken) {
        settingsService.getLastUpdate(cookieToken, dispatch)
          .then(res => {
            if (res.status === 200) {
              let lastUpdateDate = new Date(res.data.data.lastUpdate)
              dispatch(setLastUpdate(lastUpdateDate.toLocaleDateString('en-US', { hourCycle: 'h24', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })))
              dispatch(setAuth(true))
              dispatch(setToken(cookieToken))
            } else {
              setLoading(false)
            }
          })
          .catch(err => {
            setLoading(false)
            console.error(err)
          })
      } else {
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (user.token && !user.infoLoaded) {
      settingsService.getUserInfo(user.token, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setUserInfo(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
  }, [user.token])

  useEffect(() => {
    if (user.token && (!settings.lastUpdate || settings.lastUpdate.length === 0)) {
      settingsService.getLastUpdate(user.token, dispatch)
        .then(res => {
          if (res.status === 200) {
            let lastUpdateDate = new Date(res.data.data.lastUpdate)
            dispatch(setLastUpdate(lastUpdateDate.toLocaleDateString('en-US', { hourCycle: 'h24', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })))
          }
        })
        .catch(err => console.error(err))
    }
  }, [user.token])

  useEffect(() => {

    const getUserPersonalWatchlists = (token) => {
      if (!watchlists.loaded) {
        watchlistsService.getWatchlists(token, dispatch)
          .then(res => {
            if (res.status === 200) {
              dispatch(setPersonalWatchlists(res.data.data))
            }
          })
          .catch(err => console.error(err))
      }
    }

    const getSuperStats = (token) => {
      if (!statistics.superStatsLoaded) {
        statisticsService.getStatistics(token, dispatch)
          .then(res => {
            if (res.status === 200) {
              dispatch(setStatistics(res.data.data))
              dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
              dispatch(setClubsTotal(res.data.data.numOfClubs))
              dispatch(setPlayersTotal(res.data.data.numOfPlayers))
            }
          })
          .catch(err => console.error(err))
      }
    }

    if (user.token) {
      getUserPersonalWatchlists(user.token)
      getSuperStats(user.token)
    }
  }, [user.token])

  useEffect(() => {
    if (user.token && user.lang) {
      translationsService.getTranslations(user.token, dispatch, user.lang)
        .then(res => {
          if (res.status === 200) {
            dispatch(setDictionary(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
  }, [user.token, user.lang])

  useEffect(() => {

    const getClubClustersStatistics = (clubClusters) => {
      if (Array.isArray(clubClusters) && clubClusters.length !== 0) {
        statisticsService.getClusterStats(user.token, dispatch, { entityId: clubClusters })
          .then(res => {
            if (res.status === 200) {
              res.data.data.forEach(cs => dispatch(addClusterStatistics(cs)))
              dispatch(setClusterStatsLoaded(true))
            }
          })
          .catch(err => console.error(err))
      }
    }

    if (user.token && Array.isArray(clubClusters) && clubClusters.length === 0) {
      filtersService.getClubClusters(user.token, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
            getClubClustersStatistics(res.data.data.clusters)
          }
        })
        .catch(err => console.error(err))
    }
  }, [user.token, clubClusters])

  useEffect(() => {
    if (user.infoLoaded && settings.lastUpdate && translations.translationsLoaded && watchlists.loaded && statistics.superStatsLoaded && statistics.clusterStatsLoaded) {
      setLoading(false)
    }
  }, [user.infoLoaded, settings.lastUpdate, translations.translationsLoaded, watchlists.loaded, statistics.superStatsLoaded, statistics.clusterStatsLoaded])

  return (isLoading ? <StdFootballLoadingPage /> : children
  )
}

export default AuthGuard