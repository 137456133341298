import { Box, Button, Typography } from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'
import StdMillionCurrencyFormat from '../../form/stdMillionCurrencyFormat'
import StdNumericField from '../../form/stdNumericField'
import TranslatedContent from '../../translations/translatedContent'

const StdRevenueFilter = forwardRef((props, ref) => {

  const MIN_ABSOLUTE_REVENUE = 0
  const MAX_ABSOLUTE_REVENUE = 1000

  const [selectedMinRevenue, setSelectedMinRevenue] = useState(MIN_ABSOLUTE_REVENUE)
  const [selectedMaxRevenue, setSelectedMaxRevenue] = useState(MAX_ABSOLUTE_REVENUE)

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedMinRevenue(MIN_ABSOLUTE_REVENUE)
      setSelectedMaxRevenue(MAX_ABSOLUTE_REVENUE)
    }
  }))

  const handleChangeMinRevenue = (newValue) => {
    setSelectedMinRevenue(newValue)
    props.onChange(prepareFilter(newValue.toString(), selectedMaxRevenue))
  }

  const handleChangeMaxRevenue = (newValue) => {
    setSelectedMaxRevenue(newValue)
    props.onChange(prepareFilter(selectedMinRevenue, newValue.toString()))
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setSelectedMinRevenue(MIN_ABSOLUTE_REVENUE)
    setSelectedMaxRevenue(MAX_ABSOLUTE_REVENUE)
    props.onChange(prepareFilter(MIN_ABSOLUTE_REVENUE, MAX_ABSOLUTE_REVENUE))
  }

  const prepareFilter = (min, max) => {
    let filterContent = {}
    if (min > MIN_ABSOLUTE_REVENUE) {
      filterContent[props.filterName + 'From'] = min
    }
    if (max < MAX_ABSOLUTE_REVENUE) {
      filterContent[props.filterName + 'To'] = max
    }
    let filterValues = ''
    if (filterContent[props.filterName + 'From'] || filterContent[props.filterName + 'To']) {
      filterValues = min + ' - ' + max
    }
    let filter = {
      filterName: 'revenues',
      filterValues: filterValues,
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='revenue' defaultValue='Revenue' />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <StdNumericField
          label={<TranslatedContent code='from' defaultValue='From' />}
          value={selectedMinRevenue}
          minValue={MIN_ABSOLUTE_REVENUE}
          maxValue={MAX_ABSOLUTE_REVENUE}
          onChange={handleChangeMinRevenue}
          inputComponent={StdMillionCurrencyFormat}
          step={100}
        />
        <StdNumericField
          label={<TranslatedContent code='to' defaultValue='To' />}
          value={selectedMaxRevenue}
          minValue={MIN_ABSOLUTE_REVENUE}
          maxValue={MAX_ABSOLUTE_REVENUE}
          onChange={handleChangeMaxRevenue}
          inputComponent={StdMillionCurrencyFormat}
          step={100}
        />
      </Box>
      <Box sx={{ display: selectedMinRevenue > MIN_ABSOLUTE_REVENUE || selectedMaxRevenue < MAX_ABSOLUTE_REVENUE ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleResetFilter}>
          <TranslatedContent code='resetFilter' defaultValue='Reset Filter' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdRevenueFilter