import { Box, CircularProgress, Divider, Tooltip, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import statisticsService from '../../services/statisticsService'
import { addClusterStatistics } from '../../slices/statisticsSlice'
import StdLeagueBarChart from '../charts/stdLeagueBarChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import TranslatedContent from '../translations/translatedContent'

const LeagueComparablesPotentialPrice = (props) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const clusterStats = useSelector(state => state.statistics.clusters)

  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()

  const [data, setData] = useState([])
  const [leagueClubs, setLeagueClubs] = useState([])
  const [leagueClubsCluster, setLeagueClubsCluster] = useState([])
  const [currency, setCurrency] = useState('')

  useEffect(() => {
    setCurrency(getCurrencySymbol(userCurrency))
  }, [userCurrency])

  useEffect(() => {
    if (props.league && props.league.Clubs.length > 0) {
      setLeagueClubs([...props.league.Clubs].sort((a, b) => b.potentialPrice - a.potentialPrice))
    }
  }, [props.league])

  useEffect(() => {
    let clusters = []
    leagueClubs.forEach((club) => {
      if (!clusters.includes(club.cluster)) {
        clusters.push(club.cluster)
      }
    })
    setLeagueClubsCluster(clusters)
  }, [leagueClubs])

  useEffect(() => {
    leagueClubsCluster.forEach(cluster => {
      if (!clusterStats[cluster]) {
        statisticsService.getClusterStatsById(userToken, dispatch, cluster)
          .then(res => {
            if (res.status === 200) {
              dispatch(addClusterStatistics(res.data.data))
            }
          })
          .catch(err => console.error(err))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, leagueClubsCluster])

  useEffect(() => {
    if (props.league) {
      startTransition(() => {
        let tmpData = []
        leagueClubs.forEach(club => {
          tmpData.push({
            id: club.clubId,
            club: club.shortName,
            cluster: club.cluster,
            icon: '/img/clubs/logos/' + club.clubId + '.png',
            potentialPrice: club.potentialPrice,
            tierAvgPotentialPrice: Math.round(clusterStats[club.cluster]?.totalPotentialPrice / clusterStats[club.cluster]?.numOfClubs * 100) / 100,
            leagueAvgPotentialPrice: Math.round(props.league.potentialPrice / props.league.numOfClubs * 100) / 100
          })
        })
        setData(tmpData)
      })
    }
  }, [props.league, clusterStats, leagueClubs])

  return (
    <Box sx={{ width: '100%', mt: 5, mb: 3 }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '2rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='leagueClubValue' defaultValue='League Club Value' />
      </Typography>
      <Divider sx={{ backgroundColor: '#04A8A3', height: '.2rem', width: '100%' }} />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', xl: 'column' },
          alignItems: { xs: 'flex-end', sm: 'center', xl: 'flex-end' },
          justifyContent: { xs: 'space-around', xl: 'center' },
          width: '100%',
          zIndex: 2,
          px: { xs: 0, xl: 2 },
          mt: 2,
          mb: { xs: 0, xl: -10 }
        }}
      >
        <Tooltip title={<TranslatedContent code='clubFairValue' defaultValue='Club Fair Value' description />} placement='left'>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: { xs: 1, xl: 3 } }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 600, mr: 1 }}>
              <TranslatedContent code='clubFairValue' defaultValue='Club Fair Value' />
            </Typography>
            <Box sx={{ backgroundColor: '#04A8A3', width: 10, height: 10, borderRadius: '50%' }} />
          </Box>
        </Tooltip>
        <Tooltip title={<TranslatedContent code='tierAverageFairValue' defaultValue='Tier Average Fair Value' description />} placement='left'>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: { xs: 1, xl: 3 } }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 600, mr: 1 }}>
              <TranslatedContent code='tierAverageFairValue' defaultValue='Tier Average Fair Value' />
            </Typography>
            <Box sx={{ backgroundColor: '#F4D35E', width: 10, height: 10, borderRadius: '50%' }} />
          </Box>
        </Tooltip>
        <Tooltip title={<TranslatedContent code='leagueAverageFairValue' defaultValue='League Average Fair Value' description />} placement='left'>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: { xs: 1, xl: 3 } }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 600, mr: 1 }}>
              <TranslatedContent code='leagueAverageFairValue' defaultValue='League Average Fair Value' />
            </Typography>
            <Box sx={{ backgroundColor: '#FFFFFF', width: 10, height: 10, borderRadius: '50%' }} />
          </Box>
        </Tooltip>
      </Box>
      {isPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
        <StdLeagueBarChart chartName='leaguePotentialPrice' data={data} uom={currency} numOfClubs={props.league?.numOfClubs} />}
    </Box>
  )
}

export default LeagueComparablesPotentialPrice