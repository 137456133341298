import { configureStore } from '@reduxjs/toolkit'
import { clubsSlice } from '../slices/clubsSlice'
import { filtersSlice } from '../slices/filtersSlice'
import { leaguesSlice } from '../slices/leaguesSlice'
import { playersSlice } from '../slices/playersSlice'
import { settingsSlice } from '../slices/settingsSlice'
import { statisticsSlice } from '../slices/statisticsSlice'
import { tickerSlice } from '../slices/tickerSlice'
import { translationsSlice } from '../slices/translationsSlice'
import { userSlice } from '../slices/userSlice'
import { watchlistsSlice } from '../slices/watchlistsSlice'

const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [translationsSlice.name]: translationsSlice.reducer,
    [settingsSlice.name]: settingsSlice.reducer,
    [leaguesSlice.name]: leaguesSlice.reducer,
    [clubsSlice.name]: clubsSlice.reducer,
    [playersSlice.name]: playersSlice.reducer,
    [statisticsSlice.name]: statisticsSlice.reducer,
    [watchlistsSlice.name]: watchlistsSlice.reducer,
    [filtersSlice.name]: filtersSlice.reducer,
    [tickerSlice.name]: tickerSlice.reducer
  }
})

export default store