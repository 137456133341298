import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PlayerComparables from '../../components/players/playerComparables'
import PlayerProfile from '../../components/players/playerProfile'
import SimilarPlayers from '../../components/players/similarPlayers'
import playersService from '../../services/playersService'

const PlayerPage = () => {

  const { id } = useParams()
  const dispatch = useDispatch()

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)

  const [currentPlayer, setCurrentPlayer] = useState()

  const [similarPlayersMaxSalaryNet, setSimilarPlayersMaxSalaryNet] = useState(0)
  const [similarPlayersAvgSalaryNet, setSimilarPlayersAvgSalaryNet] = useState(0)

  useEffect(() => {
    playersService.getPlayer(userToken, dispatch, id)
      .then(res => {
        setCurrentPlayer(res.data.data)
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, userCurrency, id])

  const similarPlayersCallback = (similarPlayers) => {
    if (Array.isArray(similarPlayers) && similarPlayers.length > 0) {
      let maxNetSalary = 0
      let totalNetSalary = 0
      similarPlayers.forEach(player => {
        maxNetSalary = Math.max(maxNetSalary, player.salaryNet)
        totalNetSalary += player.salaryNet
      })
      setSimilarPlayersMaxSalaryNet(maxNetSalary)
      setSimilarPlayersAvgSalaryNet(totalNetSalary / similarPlayers.length)
    }
  }

  return (
    <Stack className='stdScrollable' sx={{ width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
      <PlayerProfile player={currentPlayer} similarPlayersSalaryNet={{ max: similarPlayersMaxSalaryNet, avg: similarPlayersAvgSalaryNet }} sx={{ zIndex: 1 }} />
      <PlayerComparables player={currentPlayer} sx={{ my: 5 }} />
      <SimilarPlayers playerId={currentPlayer?.playerId} similarPlayersCallback={similarPlayersCallback} sx={{ my: 5 }} />
    </Stack>
  )
}

export default PlayerPage