import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clubsService from '../../services/clubsService'
import statisticsService from '../../services/statisticsService'
import { addClusterStatistics, addLeagueStatistics, setStatistics } from '../../slices/statisticsSlice'
import StdGaugeChart from '../charts/stdGaugeChart'
import StdGaugeSpectrumChart from '../charts/stdGaugeSpectrumChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import StdGridCarousel from '../layout/stdGridCarousel'
import TranslatedContent from '../translations/translatedContent'
import { transitionWrapper } from '../../services/transitionWrapper'

const ClubComparablesIndicators = (props) => {

  const oneResultPage = {
    page: 1,
    per_page: 1
  }

  const potentialPriceSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  const residualBookValueSort = {
    field: 'residualBookValue',
    sort: 'desc'
  }

  const expectedPriceSort = {
    field: 'expectedPrice',
    sort: 'desc'
  }

  const salaryNetSort = {
    field: 'salaryNet',
    sort: 'desc'
  }

  const salaryPotentialSort = {
    field: 'salaryPotential',
    sort: 'desc'
  }

  const salaryOptimalSort = {
    field: 'salaryOptimal',
    sort: 'desc'
  }

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const statistics = useSelector(state => state.statistics)

  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()

  const [maxPotentialPricePerClub, setMaxPotentialPricePerClub] = useState(0)
  const [maxResidualBookValuePerClub, setMaxResidualBookValuePerClub] = useState(0)
  const [maxExpectedPricePerClub, setMaxExpectedPricePerClub] = useState(0)
  const [maxSalaryNetPerClub, setMaxSalaryNetPerClub] = useState(0)
  const [maxSalaryOptimalPerClub, setMaxSalaryOptimalPerClub] = useState(0)
  const [maxSalaryPotentialPerClub, setMaxSalaryPotentialPerClub] = useState(0)

  const [superStats, setSuperStats] = useState({})
  const [leagueStats, setLeagueStats] = useState({})
  const [clusterStats, setClusterStats] = useState({})

  const [btnState, setBtnState] = useState('general')

  useEffect(() => {
    if (props.club?.leagueId && statistics.leagues[props.club.leagueId]) {
      setLeagueStats(statistics.leagues[props.club.leagueId])
    }
    if (props.club?.cluster && statistics.clusters[props.club.cluster]) {
      setClusterStats(statistics.clusters[props.club.cluster])
    }
    if (statistics.superStatsLoaded) {
      setSuperStats(statistics.statistics)
    }
  }, [props.club, statistics])

  useEffect(() => {
    if (props.club?.leagueId && !statistics.leagues[props.club.leagueId]) {
      statisticsService.getLeagueStats(userToken, dispatch, props.club.leagueId)
        .then(res => {
          if (res.status === 200) {
            dispatch(addLeagueStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    if (props.club?.cluster && !statistics.clusters[props.club.cluster]) {
      statisticsService.getClusterStatsById(userToken, dispatch, props.club.cluster)
        .then(res => {
          if (res.status === 200) {
            dispatch(addClusterStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    if (!statistics.superStatsLoaded) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.club])

  useEffect(() => {
    if (props.club) {
      startTransition(() => {
        transitionWrapper(
          clubsService.getClubs(userToken, dispatch, oneResultPage, null, potentialPriceSort),
          res => {
            if (res.status === 200) {
              setMaxPotentialPricePerClub(res.data.data[0].potentialPrice)
            }
          })
      })
      startTransition(() => {
        transitionWrapper(
          clubsService.getClubs(userToken, dispatch, oneResultPage, null, residualBookValueSort),
          res => {
            if (res.status === 200) {
              setMaxResidualBookValuePerClub(res.data.data[0].residualBookValue)
            }
          })
      })
      startTransition(() => {
        transitionWrapper(clubsService.getClubs(userToken, dispatch, oneResultPage, null, expectedPriceSort),
          res => {
            if (res.status === 200) {
              setMaxExpectedPricePerClub(res.data.data[0].expectedPrice)
            }
          })
      })
      startTransition(() => {
        transitionWrapper(clubsService.getClubs(userToken, dispatch, oneResultPage, null, salaryNetSort),
          res => {
            if (res.status === 200) {
              setMaxSalaryNetPerClub(res.data.data[0].salaryNet)
            }
          })
      })
      startTransition(() => {
        transitionWrapper(clubsService.getClubs(userToken, dispatch, oneResultPage, null, salaryPotentialSort),
          res => {
            if (res.status === 200) {
              setMaxSalaryPotentialPerClub(res.data.data[0].salaryPotential)
            }
          })
      })
      startTransition(() => {
        transitionWrapper(clubsService.getClubs(userToken, dispatch, oneResultPage, null, salaryOptimalSort),
          res => {
            if (res.status === 200) {
              setMaxSalaryOptimalPerClub(res.data.data[0].salaryOptimal)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.club])

  const handleBtnClick = (e) => {
    switch (e.target.id) {
      case 'generalBtn':
        setBtnState('general')
        break
      case 'valueBtn':
        setBtnState('value')
        break
      case 'salaryBtn':
        setBtnState('salary')
        break
      case 'marketBtn':
        setBtnState('market')
        break
      default:
        console.warn('[ClubComparablesIndicators] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ width: '100%', height: { xs: 350, md: 700, lg: 350 }, my: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '2rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}>
          <TranslatedContent code='indicators' defaultValue='Indicators' />
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button id='generalBtn' variant='stdTab' className={btnState === 'general' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='general' defaultValue='General' />
          </Button>
          <Button id='valueBtn' variant='stdTab' className={btnState === 'value' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='value' defaultValue='Value' />
          </Button>
          <Button id='salaryBtn' variant='stdTab' className={btnState === 'salary' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: '100%', mt: 2, mb: -2 }}>
        <StdSelect
          value={btnState}
          onChange={(e) => setBtnState(e.target.value)}>
          <MenuItem id='general' key='general' value='general'>
            <TranslatedContent code='general' defaultValue='General' />
          </MenuItem>
          <MenuItem id='value' key='value' value='value'>
            <TranslatedContent code='value' defaultValue='Value' />
          </MenuItem>
          <MenuItem id='salary' key='salary' value='salary'>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </MenuItem>
        </StdSelect>
      </Box>
      <StdGridCarousel
        sx={{ display: btnState === 'general' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } },

        }}>
        <StdGaugeChart
          loading={isPending}
          chartName='potentialPriceClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.potentialPrice,
              cluster: Math.round(clusterStats.totalPotentialPrice / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalPotentialPrice / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxPotentialPricePerClub - props.club?.potentialPrice
            }
          ]}
          fullScale={maxPotentialPricePerClub}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='ageClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.avgAge,
              cluster: clusterStats.avgAge,
              league: leagueStats.avgAge
            },
            {
              main: superStats.maxAge - props.club?.avgAge
            }
          ]}
          fullScale={superStats.maxAge}
          uom='Y'
          yearsMonths />
        <StdGaugeChart
          loading={isPending}
          chartName='remainingMonthsOfContractClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.avgContractResidualDuration,
              cluster: clusterStats.avgRemainingMonthsOfContract,
              league: leagueStats.avgRemainingMonthsOfContract
            },
            {
              main: 120 - props.club?.avgContractResidualDuration
            }
          ]}
          fullScale={120}
          uom='M'
          round />
        <StdGaugeChart
          loading={isPending}
          chartName='effectivenessClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.avgEffectiveness,
              cluster: clusterStats.avgEffectiveness,
              league: leagueStats.avgEffectiveness
            },
            {
              main: 100 - props.club?.avgEffectiveness
            }
          ]}
          fullScale={100}
          uom=''
        />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'value' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}>
        <StdGaugeSpectrumChart
          loading={isPending}
          chartName='priceSpectrumClub'
          data={[
            {
              residualBookValue: props.club?.residualBookValue,
              expectedPrice: props.club?.expectedPrice,
              potentialPrice: props.club?.potentialPrice
            }
          ]}
          values={['residualBookValue', 'expectedPrice', 'potentialPrice']}
          fullScale={Math.max(props.club?.residualBookValue, props.club?.expectedPrice, props.club?.potentialPrice)}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='residualBookValueClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.residualBookValue,
              cluster: Math.round(clusterStats.totalResidualBookValue / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalResidualBookValue / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxResidualBookValuePerClub - props.club?.residualBookValue
            }
          ]}
          fullScale={maxResidualBookValuePerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
        <StdGaugeChart
          loading={isPending}
          chartName='potentialPriceClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.potentialPrice,
              cluster: Math.round(clusterStats.totalPotentialPrice / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalPotentialPrice / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxPotentialPricePerClub - props.club?.potentialPrice
            }
          ]}
          fullScale={maxPotentialPricePerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
        <StdGaugeChart
          loading={isPending}
          chartName='expectedPriceClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.expectedPrice,
              cluster: Math.round(clusterStats.totalExpectedPrice / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalExpectedPrice / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxExpectedPricePerClub - props.club?.expectedPrice
            }
          ]}
          fullScale={maxExpectedPricePerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'salary' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}>
        <StdGaugeSpectrumChart
          loading={isPending}
          chartName='salarySpectrumClub'
          data={[
            {
              salaryNet: props.club?.salaryNet,
              salaryOptimal: props.club?.salaryOptimal,
              salaryPotential: props.club?.salaryPotential
            }
          ]}
          values={['salaryNet', 'salaryOptimal', 'salaryPotential']}
          fullScale={Math.max(props.club?.salaryNet, props.club?.salaryOptimal, props.club?.salaryPotential)}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryNetClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.salaryNet,
              cluster: Math.round(clusterStats.totalSalaryNet / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalSalaryNet / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxSalaryNetPerClub - props.club?.salaryNet
            }
          ]}
          fullScale={maxSalaryNetPerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryOptimalClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.salaryOptimal,
              cluster: Math.round(clusterStats.totalSalaryOptimal / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalSalaryOptimal / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxSalaryOptimalPerClub - props.club?.salaryOptimal
            }
          ]}
          fullScale={maxSalaryOptimalPerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryPotentialClubIndicator'
          type='club'
          data={[
            {
              main: props.club?.salaryPotential,
              cluster: Math.round(clusterStats.totalSalaryPotential / clusterStats.numOfClubs * 100) / 100,
              league: Math.round(leagueStats.totalSalaryPotential / leagueStats.numOfClubs * 100) / 100
            },
            {
              main: maxSalaryPotentialPerClub - props.club?.salaryPotential
            }
          ]}
          fullScale={maxSalaryPotentialPerClub}
          uom={getCurrencySymbol(userCurrency)}
        />
      </StdGridCarousel>
    </Box>
  )
}

export default ClubComparablesIndicators