import { Box, Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdLineGraph from '../charts/stdLineGraph'
import { integerNumberRenderer, translatedHeaderRenderer } from '../data/formatters/dataFormatters'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import clubsService from '../../services/clubsService'
import DashboardVerticalGrid from './dashboardVerticalGrid'

const DashboardStats = (props) => {

  const emptyData = [
    { field: 'numOfLeagues', name: 'numOfLeagues', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfClubs', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'numOfPlayers', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'under23NumOfPlayers', name: 'under23NumOfPlayers', value: '-', valueRenderer: integerNumberRenderer },
  ]

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [comparableClubs, setComparableClubs] = useState([])
  const [selectedComparableClub, setSelectedComparableClub] = useState('')
  const [data, setData] = useState(emptyData)
  const [graphTimeInterval, setGraphTimeInterval] = useState(3)

  return (
    <Stack sx={{ ...props.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title={<TranslatedContent code='comparableClubs' defaultValue='Comparable Clubs' description />}>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
            <TranslatedContent code={props?.title} defaultValue={props?.title} />
          </Typography>
        </Tooltip>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <DashboardVerticalGrid data={props?.stats} sx={{ my: 2 }} />
      {false && <StdSelect
        value={graphTimeInterval}
        onChange={(e) => setGraphTimeInterval(e.target.value)}
        sx={{ display: 'none', alignSelf: 'center', width: '50%' }}>
        <MenuItem id='3months' value={3}>
          3 <TranslatedContent code='months' defaultValue='Months' />
        </MenuItem>
      </StdSelect>}
      {/* <StdLineGraph chartName='dashboardComparableClubsIndicators' sx={{ display: 'none', height: '25rem', my: 1 }} /> */}
    </Stack >
  )
}

export default DashboardStats