import { Box, Divider, Grid, Typography } from '@mui/material'
import { MarketCard, MarketCardContent } from '../data/marketCard'
import TranslatedContent from '../translations/translatedContent'

const ClubMarket = (props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='market' defaultValue='Market' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%' }} />
      <Grid container spacing={8} sx={{ my: 'calc(30px - 64px)', pb: 5 }}>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Last Market Expenditure' value='36,9 K' text1='01.08.22' text2='31.08.22' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Last Market Income' value='56,5 K' text1='01.08.22' text2='31.08.22' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Trade Balance' value='19,6 K' text1='01.08.22' text2='31.08.22' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Top Release' value='29,1 K' text1='Junior' text2='Messias' isPlayerCard textLink='' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Top Engagement' value='29,1 K' text1='Zlatan' text2='Ibrahimovic' isPlayerCard textLink='' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard>
            <MarketCardContent title='Salary Report' value='36,9 K' />
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard sx={{ color: '#FFFFFF', fontSize: '5rem' }}>
            ...
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard sx={{ color: '#FFFFFF', fontSize: '5rem' }}>
            ...
          </MarketCard>
        </Grid>
        <Grid item xs={4}>
          <MarketCard sx={{ color: '#FFFFFF', fontSize: '5rem' }}>
            ...
          </MarketCard>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClubMarket