import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { Fragment, useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { transitionWrapper } from '../../services/transitionWrapper'
import watchlistsService from '../../services/watchlistsService'
import { setRecommendedWatchlists } from '../../slices/watchlistsSlice'
import TranslatedContent from '../translations/translatedContent'
import DynamicWatchlist from './dynamicWatchlist'

const EntityDynamicWatchlists = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()
  const [_, startBestEffortTransition] = useTransition()

  const [dynamicWatchlists, setDynamicWatchlists] = useState([])

  useEffect(() => {
    if (userToken && props.entityId) {
      startTransition(() => {
        transitionWrapper(
          watchlistsService.getEntityDynamicWatchlists(userToken, dispatch, props.entityType, props.entityId),
          res => {
            if (res.status === 200) {
              setDynamicWatchlists(res.data.data)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.entityType, props.entityId])

  useEffect(() => {
    if (userToken && props.entityId) {
      startBestEffortTransition(() => {
        watchlistsService.getAttachedDynamicWatchlists(userToken, dispatch)
          .then(res => {
            if (res.status === 200) {
              dispatch(setRecommendedWatchlists(res.data.data))
            }
          })
          .catch(err => console.error(err))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  return (
    <Box sx={{ width: '100%', ...props.sx }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='recommendedWathclists' defaultValue='Recommended Watchlists' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%', mb: 2 }} />
      <Fragment>
        <Box sx={{ display: isPending ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
        <Box sx={{ display: isPending ? 'none' : 'block' }}>
          {dynamicWatchlists.map((watchlist, idx) => (
            <Box key={idx}>
              <DynamicWatchlist watchlist={watchlist} entityType={props.entityType} entityId={props.entityId} />
            </Box>
          ))}
        </Box>
      </Fragment>
    </Box>
  )
}

export default EntityDynamicWatchlists