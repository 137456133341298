import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Select } from '@mui/material'

const StdSelect = (props) => {

  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      renderValue={props.renderValue}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            borderRadius: 0
          },
          '& ul': {
            color: '#FFFFFF',
            backgroundColor: '#04A8A3',
            fontWeight: 500,
            textTransform: 'uppercase',
            '& li.Mui-selected': {
              color: '#04A8A3',
              backgroundColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#F5F5F5'
              },
              '&:focus': {
                backgroundColor: '#F5F5F5'
              }
            }
          }
        }
      }}
      sx={{
        color: '#FFFFFF',
        fontSize: '1.6rem',
        fontWeight: 500,
        textAlign: 'left',
        textTransform: 'uppercase',
        height: 35,
        width: 250,
        border: 0,
        borderRadius: 0,
        borderBottom: '1px solid #04A8A3',
        '& fieldset': {
          border: '0'
        },
        '& fieldset:hover': {
          border: '0'
        },
        '& svg': {
          fill: '#FFFFFF'
        },
        ...props.sx
      }}>
      {props.children}
    </Select>
  )
}

export default StdSelect