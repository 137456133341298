import { Box, Slider } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'

const StdRangeSlider = (props) => {

  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    let calculatedNewValue = []

    if (newValue[1] - newValue[0] < props.step) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], props.maxValue - props.step)
        calculatedNewValue = [clamped, clamped + props.step]
        setValue(calculatedNewValue)
      } else {
        const clamped = Math.max(newValue[1], props.step)
        calculatedNewValue = [clamped - props.step, clamped]
        setValue(calculatedNewValue)
      }
    } else {
      calculatedNewValue = newValue
      setValue(calculatedNewValue)
    }

    props.onChange(calculatedNewValue)
  }

  return (
    <Box sx={{ width: '100%', mx: 4 }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay='on'
        step={props.step}
        marks={props.marks}
        min={props.minValue}
        max={props.maxValue}
        disableSwap
      />
    </Box>
  )
}

export default StdRangeSlider