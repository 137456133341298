import NorthIcon from '@mui/icons-material/North'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SouthIcon from '@mui/icons-material/South'
import { Box, CircularProgress, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState, useTransition } from 'react'
import NewsTicker from 'react-advanced-news-ticker'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tickerService from '../../services/tickerService'
import { transitionWrapper } from '../../services/transitionWrapper'
import { setTickerData } from '../../slices/tickerSlice'
import { currencyUomRenderer, decimalNumberRenderer } from './formatters/dataFormatters'

const StdTicker = (props) => {

  const userToken = useSelector(state => state.user.token)
  const tickerSlice = useSelector(state => state.ticker)
  const [isPending, startTransition] = useTransition()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tickerRef = useRef()

  let isBreakpointDownXxl = useMediaQuery(theme => theme.breakpoints.down('xxl'))
  let isBreakpointDownXl = useMediaQuery(theme => theme.breakpoints.down('xl'))
  let isBreakpointDownLg = useMediaQuery(theme => theme.breakpoints.down('lg'))
  let isBreakpointDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [running, setRunning] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (tickerSlice.data.length === 0 && userToken) {
      startTransition(() => {
        transitionWrapper(tickerService.getTickerPlayers(userToken, dispatch),
          res => {
            if (res.status === 200) {
              dispatch(setTickerData(res.data.data))
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (tickerSlice.data.length > 0) {
      if (tickerRef.current)
        tickerRef.current.stop()

      let tmpData = []

      let chunkSize = 0
      if (isBreakpointDownSm) {
        chunkSize = 1
      } else if (isBreakpointDownLg) {
        chunkSize = 2
      } else if (isBreakpointDownXl) {
        chunkSize = 3
      } else if (isBreakpointDownXxl) {
        chunkSize = 4
      } else {
        chunkSize = 5
      }

      for (let i = 0; i < tickerSlice.data.length; i += chunkSize) {
        const chunk = tickerSlice.data.slice(i, i + chunkSize)
        tmpData.push(chunk)
      }
      setData(tmpData)
    }
  }, [tickerSlice, isBreakpointDownSm, isBreakpointDownLg, isBreakpointDownXl, isBreakpointDownXxl])

  useEffect(() => {
    if (data && data.length > 0 && tickerRef.current) {
      tickerRef.current.start()
    }
  }, [data, isPending])

  useEffect(() => {
    if (running) {
      tickerRef.current.unPause()
    } else {
      tickerRef.current.pause()
    }
  }, [running])

  return (
    <Box sx={{
      backgroundColor: '#15222B',
      height: 50,
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      mx: 2,
      ...props.sx
    }}>
      {isPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}><CircularProgress /></Box> :
        <NewsTicker
          id='ticker'
          ref={tickerRef}
          autoStart={false}
          duration={5000}
          maxRows={1}
          style={{ listStyleType: 'none', padding: 0, width: '100%' }}
        >
          {!data ?
            <Box sx={{ display: 'none' }}>placeholder</Box>
            :
            data.map((entry, idx) => (
              <Box key={idx} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'space-around', xxl: 'space-between' } }}>
                {entry.map((element, idx) => (
                  <Box key={idx} sx={{ display: 'flex', flexDirection: 'row', mx: { xl: 0, xxl: 2 }, alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Typography
                      onClick={() => navigate('/players/' + element.playerId)}
                      sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 600, cursor: 'pointer', mx: .5 }}>
                      {element.player}
                    </Typography>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, mx: .5 }}>{currencyUomRenderer({ value: element.price })}</Typography>
                    <Typography sx={{ color: element.variation >= 0 ? 'lightgreen' : 'red', fontSize: '1.4rem', fontWeight: 500, ml: 1, mr: -1 }}>{decimalNumberRenderer({ value: element.variation })}&nbsp;%</Typography>
                    {element.variation >= 0 ? <NorthIcon sx={{ color: 'lightgreen', height: 18 }} /> : <SouthIcon sx={{ color: 'red', height: 18 }} />}
                  </Box>
                ))}
              </Box>
            ))}
        </NewsTicker>}
      <IconButton sx={{
        color: '#000000',
        backgroundColor: '#A1CBC2',
        outline: '5px solid #31495A',
        borderRadius: 25,
        height: 50,
        width: 50,
        '&:hover': {
          backgroundColor: '#A1CBC2'
        }
      }}
        onClick={() => setRunning(prevState => !prevState)}>
        {running ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
    </Box>
  )
}

export default StdTicker