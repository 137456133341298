import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdAgeFilter from '../../components/data/filters/stdAgeFilter'
import StdClusterFilter from '../../components/data/filters/stdClusterFilter'
import StdExpectedPriceFilter from '../../components/data/filters/stdExpectedPriceFilter'
import StdExpectedSalaryFilter from '../../components/data/filters/stdExpectedSalaryFilter'
import StdFilter from '../../components/data/filters/stdFilter'
import StdLeagueFilter from '../../components/data/filters/stdLeagueFilter'
import StdPassportFilter from '../../components/data/filters/stdPassportFilter'
import StdResidualContractFilter from '../../components/data/filters/stdResidualContractFilter'
import StdRoleFilter from '../../components/data/filters/stdRoleFilter'
import StdPlayersTable from '../../components/players/stdPlayersTable'
import playersService from '../../services/playersService'

const PlayersPage = () => {

  const initialPage = {
    page: 1,
    per_page: 20
  }

  const defaultSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const nameSearchRef = useRef()

  const roleFilterRef = useRef()
  // const positionFilterRef = useRef()
  const ageFilterRef = useRef()
  const expectedPriceFilterRef = useRef()
  const expectedSalaryFilterRef = useRef()
  const passportFilterRef = useRef()
  const clusterFilterRef = useRef()
  const leagueFilterRef = useRef()
  const residualContractFilterRef = useRef()


  const [queryParams, setQueryParams] = useState({
    page: initialPage,
    filter: {},
    sort: defaultSort
  })

  const [players, setPlayers] = useState([])
  const [filterOpen, setFilterOpen] = useState(false)
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 })
  const [sorting, setSorting] = useState([])
  const [filterSummary, setFilterSummary] = useState([])
  const [rowCount, setRowCount] = useState(0)

  useEffect(() => {
    playersService.getPlayers(userToken, dispatch, queryParams.page, queryParams.filter, queryParams.sort)
      .then(res => {
        if (res.status === 200 && res.data.success) {
          setPlayers(res.data.data)
          setRowCount(res.data.meta.total)
        } else {
          setPlayers([])
          setRowCount(0)
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, queryParams])

  useEffect(() => {
    let newSort
    if (sorting && sorting.length > 0) {
      newSort = { field: sorting[0].id, sort: sorting[0].desc ? 'desc' : 'asc' }
    } else {
      newSort = defaultSort
    }
    setQueryParams(q => {
      return {
        ...q,
        sort: newSort,
        page: initialPage
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  useEffect(() => {
    setQueryParams(q => {
      return {
        ...q,
        page: { page: pagination.pageIndex + 1, per_page: pagination.pageSize }
      }
    })
  }, [pagination])

  const handleApplyFilters = () => {
    nameSearchRef.current.resetNameFilter()
    let newFilter = {}
    filterSummary.forEach((fs) => {
      newFilter = {
        ...newFilter,
        ...fs.filterContent
      }
    })
    setQueryParams({
      ...queryParams,
      filter: newFilter,
      page: initialPage
    })
  }

  const deleteFilters = () => {
    setQueryParams({
      ...queryParams,
      page: initialPage,
      filter: {}
    })
    setFilterSummary([])
    roleFilterRef.current.reset()
    // positionFilterRef.current.reset()
    ageFilterRef.current.reset()
    expectedPriceFilterRef.current.reset()
    expectedSalaryFilterRef.current.reset()
    passportFilterRef.current.reset()
    clusterFilterRef.current.reset()
    leagueFilterRef.current.reset()
    residualContractFilterRef.current.reset()
  }

  const handleFilterChange = (newFilter) => {
    let newFilterSummary = [...filterSummary]
    if (newFilter) {
      let filterIndex = filterSummary.findIndex((fs) => fs.filterName === newFilter.filterName)
      if (newFilter.filterValues && newFilter.filterValues.length > 0) {
        if (filterIndex === -1) {
          newFilterSummary.push(newFilter)
        } else {
          newFilterSummary[filterIndex].filterValues = newFilter.filterValues
          newFilterSummary[filterIndex].filterContent = newFilter.filterContent
        }
      } else {
        if (filterIndex !== -1) {
          newFilterSummary.splice(filterIndex, 1)
        }
      }
    }
    setFilterSummary(newFilterSummary)
  }

  const handleFilterByName = (searchText) => {
    if (searchText && searchText !== '') {
      setFilterSummary([])
      roleFilterRef.current.reset()
      // positionFilterRef.current.reset()
      ageFilterRef.current.reset()
      expectedPriceFilterRef.current.reset()
      expectedSalaryFilterRef.current.reset()
      passportFilterRef.current.reset()
      clusterFilterRef.current.reset()
      leagueFilterRef.current.reset()
      residualContractFilterRef.current.reset()
      setQueryParams({
        ...queryParams,
        page: initialPage,
        filter: { name: searchText }
      })
    } else {
      setQueryParams({
        page: initialPage,
        filter: {},
        sort: defaultSort
      })
    }
  }

  return (
    <Fragment>
      <StdPlayersTable
        ref={nameSearchRef}
        players={players}
        enablePagination
        manualPagination
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        page={queryParams.page.page - 1}
        pageSize={20}
        rowCount={rowCount}
        filterByName={handleFilterByName}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
      />
      <StdFilter open={filterOpen} setOpen={setFilterOpen} filterSummary={filterSummary} onApply={handleApplyFilters} onDelete={deleteFilters}>
        <StdRoleFilter ref={roleFilterRef} onChange={handleFilterChange} filterName='currentRole' sx={{ my: 2 }} />
        {/* <StdPositionFilter ref={positionFilterRef} onChange={handleFilterChange} filterName='currentPosition' sx={{ my: 2 }} /> */}
        <StdAgeFilter ref={ageFilterRef} onChange={handleFilterChange} filterName='age' sx={{ my: 2 }} />
        <StdExpectedPriceFilter ref={expectedPriceFilterRef} onChange={handleFilterChange} filterName='expectedPrice' sx={{ my: 2 }} />
        <StdExpectedSalaryFilter ref={expectedSalaryFilterRef} onChange={handleFilterChange} filterName='salaryPotential' sx={{ my: 2 }} />
        <StdPassportFilter ref={passportFilterRef} onChange={handleFilterChange} filterName='nationalityCode' sx={{ my: 2 }} />
        <StdClusterFilter ref={clusterFilterRef} onChange={handleFilterChange} filterName='currentClubCluster' sx={{ my: 2 }} />
        <StdLeagueFilter ref={leagueFilterRef} onChange={handleFilterChange} filterName='currentLeagueId' sx={{ my: 2 }} />
        <StdResidualContractFilter ref={residualContractFilterRef} onChange={handleFilterChange} filterName='remainingYearsOfContract' sx={{ my: 2 }} />
      </StdFilter>
    </Fragment>
  )
}

export default PlayersPage