import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import leaguesService from '../../services/leaguesService'
import statisticsService from '../../services/statisticsService'
import { addLeagueClustersStatistics, setStatistics } from '../../slices/statisticsSlice'
import StdGaugeChart from '../charts/stdGaugeChart'
import StdGaugeSpectrumChart from '../charts/stdGaugeSpectrumChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import StdGridCarousel from '../layout/stdGridCarousel'
import TranslatedContent from '../translations/translatedContent'

const LeagueComparablesIndicators = (props) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const statistics = useSelector(state => state.statistics)

  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()

  const [leagues, setLeagues] = useState([])

  const [maxNumOfPlayersPerLeague, setMaxNumOfPlayersPerLeague] = useState(0)
  const [maxNumOfForeignPlayersPerLeague, setMaxNumOfForeignPlayersPerLeague] = useState(0)
  const [maxNumOfU23PlayersPerLeague, setMaxNumOfU23PlayersPerLeague] = useState(0)
  const [maxPotentialPricePerLeague, setMaxPotentialPricePerLeague] = useState(0)
  const [maxPortfolioCostPerLeague, setMaxPortfolioCostPerLeague] = useState(0)
  const [maxExpectedPricePerLeague, setMaxExpectedPricePerLeague] = useState(0)
  const [maxResidualBookValuePerLeague, setMaxResidualBookValuePerLeague] = useState(0)
  const [maxSalaryNetPerLeague, setMaxSalaryNetPerLeague] = useState(0)
  const [maxSalaryOptimalPerLeague, setMaxSalaryOptimalPerLeague] = useState(0)
  const [maxSalaryPotentialPerLeague, setMaxSalaryPotentialPerLeague] = useState(0)

  const [superStats, setSuperStats] = useState({})
  const [leagueClusterStats, setLeagueClusterStats] = useState({})

  const [btnState, setBtnState] = useState('general')

  useEffect(() => {
    if (props.league?.cluster && statistics.leagueClusters[props.league.cluster]) {
      setLeagueClusterStats(statistics.leagueClusters[props.league.cluster])
    }
    if (statistics.superStatsLoaded) {
      setSuperStats(statistics.statistics)
    }
  }, [props.league, statistics])

  useEffect(() => {
    if (props.league?.cluster && !statistics.leagueClusters[props.league.cluster]) {
      statisticsService.getLeagueClusterStats(userToken, dispatch, props.league.cluster)
        .then(res => {
          if (res.status === 200) {
            dispatch(addLeagueClustersStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    if (!statistics.superStatsLoaded) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.league])

  useEffect(() => {
    if (props.league) {
      leaguesService.getLeagues(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            setLeagues(res.data.data)
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.league])

  useEffect(() => {
    if (props.league && Array.isArray(leagues) && leagues.length > 0) {
      startTransition(() => {
        let maxNumOfPlayers = 0
        let maxNumOfForeignPlayers = 0
        let maxNumOfU23Players = 0
        let maxPotentialPrice = 0
        let maxPortfolioCost = 0
        let maxExpectedPrice = 0
        let maxResidualBookValue = 0
        let maxSalaryNet = 0
        let maxSalaryOptimal = 0
        let maxSalaryPotential = 0
        leagues.forEach(l => {
          maxNumOfPlayers = Math.max(maxNumOfPlayers, l.numOfPlayers)
          maxNumOfForeignPlayers = Math.max(maxNumOfForeignPlayers, l.numOfForeignPlayers)
          maxNumOfU23Players = Math.max(maxNumOfU23Players, l.numOfU23Players)
          maxPotentialPrice = Math.max(maxPotentialPrice, l.potentialPrice)
          maxPortfolioCost = Math.max(maxPortfolioCost, l.portfolioCost)
          maxExpectedPrice = Math.max(maxExpectedPrice, l.expectedPrice)
          maxResidualBookValue = Math.max(maxResidualBookValue, l.residualBookValue)
          maxSalaryNet = Math.max(maxSalaryNet, l.salaryNet)
          maxSalaryOptimal = Math.max(maxSalaryOptimal, l.salaryOptimal)
          maxSalaryPotential = Math.max(maxSalaryPotential, l.salaryPotential)
        })
        setMaxNumOfPlayersPerLeague(maxNumOfPlayers)
        setMaxNumOfForeignPlayersPerLeague(maxNumOfForeignPlayers)
        setMaxNumOfU23PlayersPerLeague(maxNumOfU23Players)
        setMaxPotentialPricePerLeague(maxPotentialPrice)
        setMaxPortfolioCostPerLeague(maxPortfolioCost)
        setMaxExpectedPricePerLeague(maxExpectedPrice)
        setMaxResidualBookValuePerLeague(maxResidualBookValue)
        setMaxSalaryNetPerLeague(maxSalaryNet)
        setMaxSalaryOptimalPerLeague(maxSalaryOptimal)
        setMaxSalaryPotentialPerLeague(maxSalaryPotential)
      })
    }
  }, [leagues, props.league])

  const handleBtnClick = (e) => {
    switch (e.target.id) {
      case 'generalBtn':
        setBtnState('general')
        break
      case 'valueBtn':
        setBtnState('value')
        break
      case 'salaryBtn':
        setBtnState('salary')
        break
      default:
        console.warn('[LeagueComparablesIndicators] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ width: '100%', height: { xs: 350, md: 700, lg: 350 }, mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '2rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}>
          <TranslatedContent code='indicators' defaultValue='Indicators' />
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button id='generalBtn' variant='stdTab' className={btnState === 'general' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='general' defaultValue='General' />
          </Button>
          <Button id='valueBtn' variant='stdTab' className={btnState === 'value' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='value' defaultValue='Value' />
          </Button>
          <Button id='salaryBtn' variant='stdTab' className={btnState === 'salary' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: '100%', mt: 2, mb: -2 }}>
        <StdSelect
          value={btnState}
          onChange={(e) => setBtnState(e.target.value)}>
          <MenuItem id='general' key='general' value='general'>
            <TranslatedContent code='general' defaultValue='General' />
          </MenuItem>
          <MenuItem id='value' key='value' value='value'>
            <TranslatedContent code='value' defaultValue='Value' />
          </MenuItem>
          <MenuItem id='salary' key='salary' value='salary'>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </MenuItem>
        </StdSelect>
      </Box>
      <StdGridCarousel
        sx={{ display: btnState === 'general' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } },

        }}>
        <StdGaugeChart
          loading={isPending}
          chartName='numOfPlayersLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.numOfPlayers,
              cluster: Math.round(leagueClusterStats.numOfPlayers / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxNumOfPlayersPerLeague - props.league?.numOfPlayers
            }
          ]}
          fullScale={maxNumOfPlayersPerLeague}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='numOfForeignerPlayersLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.numOfForeignPlayers,
              cluster: Math.round(leagueClusterStats.numOfForeignPlayers / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxNumOfForeignPlayersPerLeague - props.league?.numOfForeignPlayers
            }
          ]}
          fullScale={maxNumOfForeignPlayersPerLeague}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='numOfu23PlayersLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.numOfU23Players,
              cluster: Math.round(leagueClusterStats.under23NumOfPlayers / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxNumOfU23PlayersPerLeague - props.league?.numOfU23Players
            }
          ]}
          fullScale={maxNumOfU23PlayersPerLeague}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='ageLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.avgAge,
              cluster: leagueClusterStats.avgAge
            },
            {
              main: superStats.maxAge - props.league?.avgAge
            }
          ]}
          fullScale={superStats.maxAge}
          uom='Y'
          yearsMonths />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'value' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}>
        <StdGaugeChart
          loading={isPending}
          chartName='potentialPriceLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.potentialPrice,
              cluster: Math.round(leagueClusterStats.totalPotentialPrice / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxPotentialPricePerLeague - props.league?.potentialPrice
            }
          ]}
          fullScale={maxPotentialPricePerLeague}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='portfolioCostLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.portfolioCost,
              cluster: Math.round(leagueClusterStats.totalPortfolioCost / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxPortfolioCostPerLeague - props.league?.portfolioCost
            }
          ]}
          fullScale={maxPortfolioCostPerLeague}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='expectedPriceLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.expectedPrice,
              cluster: Math.round(leagueClusterStats.totalExpectedPrice / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxExpectedPricePerLeague - props.league?.expectedPrice
            }
          ]}
          fullScale={maxExpectedPricePerLeague}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='residualBookValueLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.residualBookValue,
              cluster: Math.round(leagueClusterStats.totalResidualBookValue / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxResidualBookValuePerLeague - props.league?.residualBookValue
            }
          ]}
          fullScale={maxResidualBookValuePerLeague}
          uom={getCurrencySymbol(userCurrency)} />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'salary' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}>
        <StdGaugeSpectrumChart
          loading={isPending}
          chartName='salarySpectrumLeague'
          data={[
            {
              salaryNet: props.league?.salaryNet,
              salaryOptimal: props.league?.salaryOptimal,
              salaryPotential: props.league?.salaryPotential
            }
          ]}
          values={['salaryNet', 'salaryOptimal', 'salaryPotential']}
          fullScale={Math.max(props.league?.salaryNet, props.league?.salaryOptimal, props.league?.salaryPotential)}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryNetLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.salaryNet,
              cluster: Math.round(leagueClusterStats.totalSalaryNet / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxSalaryNetPerLeague - props.league?.salaryNet
            }
          ]}
          fullScale={maxSalaryNetPerLeague}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryOptimalLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.salaryOptimal,
              cluster: Math.round(leagueClusterStats.totalSalaryOptimal / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxSalaryOptimalPerLeague - props.league?.salaryOptimal
            }
          ]}
          fullScale={maxSalaryOptimalPerLeague}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryPotentialLeagueIndicator'
          type='league'
          data={[
            {
              main: props.league?.salaryPotential,
              cluster: Math.round(leagueClusterStats.totalSalaryPotential / leagueClusterStats.numOfLeagues * 100) / 100
            },
            {
              main: maxSalaryPotentialPerLeague - props.league?.salaryPotential
            }
          ]}
          fullScale={maxSalaryPotentialPerLeague}
          uom={getCurrencySymbol(userCurrency)} />
      </StdGridCarousel>
    </Box>
  )
}

export default LeagueComparablesIndicators