import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import playersService from '../../services/playersService'
import { transitionWrapper } from '../../services/transitionWrapper'
import StdBarChart from '../charts/stdBarChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import TranslatedContent from '../translations/translatedContent'
import PlayerComparablesBarChartButtons from './playerComparablesBarChartButtons'

const PlayerComparablesTopPlayers = (props) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const dispatch = useDispatch()

  const [isPendingClusterChart, startClusterChartTransition] = useTransition()
  const [isPendingLeagueChart, startLeagueChartTransition] = useTransition()

  const [clusterPlayers, setClusterPlayers] = useState([])
  const [leaguePlayers, setLeaguePlayers] = useState([])

  const [clusterParam, setClusterParam] = useState('potentialPrice')
  const [leagueParam, setLeagueParam] = useState('potentialPrice')

  const [clusterUom, setClusterUom] = useState(getCurrencySymbol(userCurrency))
  const [leagueUom, setLeagueUom] = useState(getCurrencySymbol(userCurrency))

  const tenResultsPage = {
    page: 1,
    per_page: 10
  }

  const potentialPriceSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  useEffect(() => {
    if (props.player) {
      startClusterChartTransition(() => {
        const clusterFilter = {
          currentClubCluster: props.player.currentClubCluster,
          currentPosition: props.player.currentPosition
        }
        transitionWrapper(playersService.getPlayers(userToken, dispatch, tenResultsPage, clusterFilter, potentialPriceSort),
          res => {
            if (res.status === 200 && res.data.success) {
              let players = [...res.data.data]
              let includes = false
              players.every(p => {
                includes |= p.playerId === props.player.playerId
                return !includes
              })
              if (includes) {
                players = players.filter(p => p.playerId !== props.player.playerId)
              } else {
                players.pop()
              }
              players.reverse().push(props.player)
              setClusterPlayers(players)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.player])

  useEffect(() => {
    if (props.player) {
      startLeagueChartTransition(() => {
        const leagueFilter = {
          currentLeagueId: props.player.currentLeagueId,
          currentPosition: props.player.currentPosition
        }
        transitionWrapper(playersService.getPlayers(userToken, dispatch, tenResultsPage, leagueFilter, potentialPriceSort),
          res => {
            if (res.status === 200 && res.data.success) {
              let players = [...res.data.data]
              let includes = false
              players.every(p => {
                includes |= p.playerId === props.player.playerId
                return !includes
              })
              if (includes) {
                players = players.filter(p => p.playerId !== props.player.playerId)
              } else {
                players.pop()
              }
              players.reverse().push(props.player)
              setLeaguePlayers(players)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.player])

  const handleClusterParamChange = ({ param, uom }) => {
    setClusterParam(param)
    setClusterUom(uom)
  }

  const handleLeagueParamChange = ({ param, uom }) => {
    setLeagueParam(param)
    setLeagueUom(uom)
  }

  return (
    <Grid container spacing={2} sx={{ width: '100%', mt: 5 }}>
      <Grid item xs={12} lg={6}>
        <Box sx={{ width: '100%', height: 450 }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '2rem',
            fontWeight: 600,
            textTransform: 'uppercase'
          }}>
            <TranslatedContent code='tierTopPlayers' defaultValue='Tier Top Players' />
          </Typography>
          <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
          {isPendingClusterChart ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, height: '105%', py: { xs: 2, md: 5 } }}>
              <StdBarChart chartName='tierTopPlayers' type='player' name={clusterParam} data={clusterPlayers} categoryField='name' valueField={clusterParam} uom={clusterUom} />
              <PlayerComparablesBarChartButtons onChange={handleClusterParamChange} />
            </Box>}
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={{ width: '100%', height: 450 }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '2rem',
            fontWeight: 600,
            textTransform: 'uppercase'
          }}>
            <TranslatedContent code='leagueTopPlayers' defaultValue='League Top Players' />
          </Typography>
          <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
          {isPendingLeagueChart ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, height: '105%', py: { xs: 2, md: 5 } }}>
              <StdBarChart chartName='leagueTopPlayers' type='player' name={leagueParam} data={leaguePlayers} categoryField='name' valueField={leagueParam} uom={leagueUom} />
              <PlayerComparablesBarChartButtons onChange={handleLeagueParamChange} />
            </Box>}
        </Box>
      </Grid>
    </Grid>
  )
}

export default PlayerComparablesTopPlayers