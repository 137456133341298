import { Box, Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdLineGraph from '../charts/stdLineGraph'
import { currencyUomRenderer, percNumberRenderer } from '../data/formatters/dataFormatters'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import clubsService from '../../services/clubsService'
import DashboardVerticalGrid from './dashboardVerticalGrid'

const DashboardComparableClubsIndicators = (props) => {

  const emptyData = [
    { field: 'potentialPrice', name: 'fairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'residualValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'expectedPrice', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'avgLatestMicroVolatility', name: 'volatility', value: '-', valueRenderer: percNumberRenderer },
    //{ field: 'costPerPoint', name: 'costPerPoint', value: 'COMING SOON' }
  ]

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [comparableClubs, setComparableClubs] = useState([])
  const [selectedComparableClub, setSelectedComparableClub] = useState('')
  const [data, setData] = useState(emptyData)
  const [graphTimeInterval, setGraphTimeInterval] = useState(3)

  useEffect(() => {
    if (props.clubCluster) {
      const fiveResultsPage = {
        page: 1,
        per_page: 6
      }
      const filter = {
        cluster: props.clubCluster
      }
      const potentialPriceSort = {
        field: 'potentialPrice',
        sort: 'desc'
      }
      clubsService.getClubs(userToken, dispatch, fiveResultsPage, filter, potentialPriceSort)
        .then(res => {
          if (res.status === 200) {
            setComparableClubs(res.data.data)
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.clubCluster])

  useEffect(() => {
    if (Array.isArray(comparableClubs) && comparableClubs.length > 0) {
      setSelectedComparableClub(comparableClubs.filter(cc => cc.clubId !== props.clubId)[0])
    }
  }, [props.clubId, comparableClubs])

  useEffect(() => {
    if (Object.keys(selectedComparableClub).length > 0) {
      let tmpData = []
      emptyData.forEach((row) => {
        tmpData.push({ field: row.field, name: row.name, value: row.value === 'COMING SOON' ? row.value : selectedComparableClub[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
      })
      setData(tmpData)
    } else {
      setData(emptyData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComparableClub])

  return (
    <Stack sx={{ ...props.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title={<TranslatedContent code='comparableClubs' defaultValue='Comparable Clubs' description />}>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
            <TranslatedContent code='comparableClubs' defaultValue='Comparable Clubs' />
          </Typography>
        </Tooltip>
        <StdSelect
          value={selectedComparableClub}
          onChange={(e) => setSelectedComparableClub(e.target.value)}
          sx={{
            width: '50%',
            height: 24,
            color: '#F4D35E',
            borderBottom: 0
          }}>
          {comparableClubs
            .filter(cc => cc.clubId !== props.clubId)
            .splice(0, 5)
            .map((cc, idx) => (
              <MenuItem id={cc.clubId} key={idx} value={cc}>
                {cc.shortName}
              </MenuItem>
            ))}
        </StdSelect>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <DashboardVerticalGrid data={data} sx={{ my: 2 }} />
      {false && <StdSelect
        value={graphTimeInterval}
        onChange={(e) => setGraphTimeInterval(e.target.value)}
        sx={{ display: 'none', alignSelf: 'center', width: '50%' }}>
        <MenuItem id='3months' value={3}>
          3 <TranslatedContent code='months' defaultValue='Months' />
        </MenuItem>
      </StdSelect>}
      <StdLineGraph chartName='dashboardComparableClubsIndicators' sx={{ display: 'none', height: '25rem', my: 1 }} />
    </Stack >
  )
}

export default DashboardComparableClubsIndicators