import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
      xxl: 1800
    },
  },
  palette: {
    background: {
      default: '#15222B',
      popperPaper: '#A1CBC2'
    }
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: `'Barlow', 'Roboto', sans-serif`,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '2rem'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          border: '2px solid #000000',
          borderRadius: 35,
          '& fieldset:hover': {
            border: 0
          },
          '&.Mui-focused': {
            '&.Mui-focused fieldset': {
              border: 0,
              borderColor: '#000000'
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.stdSearchField': {
            '& ::before': {
              borderBottom: '1px solid #FFFFFF'
            },
            '& :hover:not(.Mui-disabled)::before': {
              borderBottom: '1px solid #FFFFFF'
            },
            '& ::after': {
              border: 0
            },
            '& :hover:not(.Mui-disabled)::after': {
              border: 0
            }
          },
          '&.stdFilterField': {
            '& ::before': {
              border: 0
            },
            '& :hover:not(.Mui-disabled)::before': {
              border: 0
            },
            '& ::after': {
              border: 0
            },
            '& :hover:not(.Mui-disabled)::after': {
              border: 0
            }
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          height: { xs: '5rem', md: '5rem' },
          marginY: '2rem',
        }
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'stdTab' },
          style: {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            fontSize: '1rem',
            height: '2.5rem',
            width: '12.5rem',
            border: '1px solid #04A8A3',
            borderRadius: '25px',
            '&:hover': {
              color: '#04A8A3'
            },
            '&.stdActive': {
              backgroundColor: '#04A8A3',
              '&:hover': {
                color: '#FFFFFF'
              }
            }
          },
        },
        {
          props: { variant: 'stdSim' },
          style: {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            fontSize: '1rem',
            height: '2.5rem',
            width: '12.5rem',
            border: '1px solid #04A8A3',
            borderRadius: '25px'
          },
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: '#04A8A3'
          }
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(30, 41, 51, .9)',
          fontSize: '1.4rem',
          fontWeight: 400
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {

        },
        rail: {
          backgroundColor: '#707070',
          height: 2,
          border: 0
        },
        track: {
          color: '#04A8A3',
          height: 2,
          border: 0
        },
        mark: {
          backgroundColor: '#707070',
          height: 8,
          width: 1,
          opacity: 1,
          '&.MuiSlider-markActive': {
            backgroundColor: '#04A8A3'
          }
        },
        markLabel: {
          fontWeight: 500
        },
        valueLabel: {
          fontSize: 16,
          fontWeight: 600,
          top: -3,
          backgroundColor: 'unset',
          '&:before': {
            display: 'none'
          },
          '& *': {
            background: 'transparent',
            color: '#04A8A3'
          }
        },
        thumb: {
          height: 18,
          width: 18,
          backgroundColor: '#FFFFFF',
          border: '1px solid #04A8A3',
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit'
          },
          '&:before': {
            display: 'none'
          },
        }
      }
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: 'inherit',
          textDecorationColor: 'inherit'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#A1CBC2'
        },
        bar: {
          backgroundColor: '#04A8A3'
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        sx: {
          backgroundColor: '#31495A'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '3rem'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          color: '#04A8A3',
          textTransform: 'uppercase',
          fontWeight: '600',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '40rem'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#04A8A3',
          textAlign: 'center'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#666666',
          fontSize: '1.4rem',
          textAlign: 'center'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          marginBottom: 10
        }
      }
    },
    MuiPagination: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
          }
        }
      ]
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          fontSize: '1.2rem',
          border: '#04A8A3 solid 1px',
          '&.Mui-selected': {
            backgroundColor: '#04A8A3',
            '&:hover': {
              backgroundColor: '#04A8A3'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: 'rgba(255, 255, 255, .1)'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row': {
            border: 0,
            '&.StdRowEven:hover': {
              backgroundColor: '#223441'
            },
            '&.StdRowOdd:hover': {
              backgroundColor: '#31495A'
            }
          },
          '& .MuiDataGrid-columnHeader': {
            '&:focus': {
              outline: 'none'
            },
            '&:focus-within': {
              outline: 'none'
            }
          },
          '.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'row',
            justifyContent: 'right'
          },
          '& .MuiDataGrid-cell': {
            paddingRight: 19,
            paddingLeft: 19,
            borderBottom: 0,
            '&:focus': {
              outline: 'none'
            },
            '&:focus-within': {
              outline: 'none'
            }
          }
        },
        columnHeaders: {
          border: 0
        },
        columnSeparator: {
          visibility: 'hidden'
        },
        iconButtonContainer: {
          width: 'auto',
          visibility: 'visible'
        },
        footerContainer: {
          justifyContent: 'center',
          border: 0
        }
      }
    }
  },
})

export default theme