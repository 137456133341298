import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import FontAwesomeSvgIcon from '../faSvgIcon'
import StdPasswordInput from '../form/stdPasswordInput'

const LoginForm = (props) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usrHelperText, setUsrHelperText] = useState('')
  const [pwdHelperText, setPwdHelperText] = useState('')
  const [errorMessage, setErrorMessage] = useState(props.errorMessage)

  useEffect(() => {
    setErrorMessage(props.errorMessage)
  }, [props.errorMessage])

  const validateForm = () => {
    let validation = true
    if (!username) {
      validation = false
      setUsrHelperText('* Please insert a username')
    } else {
      setUsrHelperText('')
    }
    if (!password) {
      validation = false
      setPwdHelperText('* Please insert a password')
    } else {
      setPwdHelperText('')
    }
    return validation
  }

  return (
    <form onSubmit={e => {
      e.preventDefault()
      if (validateForm()) props.onSubmit(username, password)
    }}>
      <Box component='div'
        sx={{
          display: { xs: 'flex', xl: 'none' },
          justifyContent: 'center',
          mb: '2rem'
        }}>
        <img src='/img/common/stdLogo.svg' alt='StdFootball' width='260px' height='110px' />
      </Box>
      <Typography sx={{
        color: '#04a8a3',
        fontSize: '6rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: -1,
        fontWeight: '600',
        letterSpacing: -2
      }}>
        Welcome
      </Typography>
      <Typography sx={{
        color: 'black',
        textAlign: 'center',
        textTransform: 'uppercase',
        mb: 2
      }}>
        Please log in to continue
      </Typography>
      <Box sx={{ height: 2, marginTop: 6 }}>
        <Typography sx={{ color: 'red', fontWeight: 600, textTransform: 'uppercase', textAlign: 'center' }}>{errorMessage}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 2
      }}>
        <TextField
          id='username'
          type='email'
          placeholder='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            marginY: 1,
            width: { xs: '85%', sm: 400, lg: '85%' },
            alignSelf: 'center'
          }}
          helperText={usrHelperText}
          FormHelperTextProps={{ style: { color: 'red', fontSize: '2rem' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'
                sx={{
                  color: 'rgba(0, 0, 0, 1)',
                  marginX: 1,
                  transform: 'scale(.8)'
                }}>
                <FontAwesomeSvgIcon icon={faUser} />
              </InputAdornment>
            ),
          }}
        />
        <StdPasswordInput
          id='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            marginY: 1,
            width: { xs: '85%', sm: 400, lg: '85%' },
            alignSelf: 'center'
          }}
          helperText={pwdHelperText}
          FormHelperTextProps={{ style: { color: 'red', fontSize: '2rem' } }} />
      </Box>
      <Box sx={{
        width: { xs: '100%', lg: '90%' },
        textAlign: { xs: 'center', lg: 'right' },
        fontSize: 'medium',
        marginY: 1
      }}>
        <Link href='#'>
          Forgot password?
        </Link>
      </Box>
      <FormControl sx={{
        width: '100%',
        marginY: 3
      }}>
        <Button type='submit' variant='contained' size='large'
          sx={{
            width: { xs: '85%', sm: 400, lg: '60%' },
            alignSelf: 'center',
            borderRadius: 27,
            backgroundColor: '#04a8a3',
            '&:hover': {
              backgroundColor: '#04a8a3'
            }
          }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
            Login
          </Typography>
        </Button>
      </FormControl>
      <Typography variant='h6' align='center' sx={{
        color: '#04a8a3',
        textTransform: 'uppercase',
        marginY: '1rem',
        fontSize: '2.5rem'
      }}>
        Don&apos;t have an account?
      </Typography>
      <Box sx={{
        textAlign: 'center',
        fontSize: 'medium'
      }}>
        <Link href='#'
          onClick={(e) => {
            e.preventDefault()
            window.open('mailto:contacts@stdfootball.com', 'mail')
          }}>
          Contact us
        </Link>
      </Box>
    </form>
  )
}

export default LoginForm