import Cookies from 'js-cookie'
import { api_unversioned, auth_api } from './commonService'

const login = (username, password) => {
  let formData = new FormData()
  formData.append('email', username)
  formData.append('password', password)
  return auth_api.post('/login', formData)
}

const logout = (token, dispatch) => {
  removeCookieToken()
  return api_unversioned(token, dispatch).post('/logout')
}

const getCookieToken = () => {
  const token = Cookies.get('STDSESSION')
  return token
}

const setCookieToken = (token) => {
  if (token) {
    let expiringTime = new Date()
    expiringTime.setTime(expiringTime.getTime() + (60 * 60 * 1000))
    Cookies.set('STDSESSION', token, { expires: expiringTime, sameSite: 'strict', secure: process.env.NODE_ENV === 'production' })
  }
}

const removeCookieToken = () => {
  Cookies.remove('STDSESSION')
}

const authService = {
  login,
  logout,
  getCookieToken,
  setCookieToken,
  removeCookieToken
}

export default authService