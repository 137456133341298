import { Box, CircularProgress, Divider, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import { Fragment, forwardRef, useEffect, useRef, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import filtersService from '../../services/filtersService'
import playersService from '../../services/playersService'
import { transitionWrapper } from '../../services/transitionWrapper'
import { setFiltersRoles } from '../../slices/filtersSlice'
import UserCurrency from '../currency/userCurrency'
import { positionFilterRenderer } from '../data/formatters/dataFormatters'
import StdSelect from '../form/stdSelect'
import StdGridCarousel from '../layout/stdGridCarousel'
import TranslatedContent from '../translations/translatedContent'
import WatchlistPopper from '../watchlists/watchlistPopper'

const TopPlayer = forwardRef((props, ref) => {

  const personalWatchlists = useSelector(state => state.watchlists.personal)
  const navigate = useNavigate()

  const [isInAtLeastOneWatchlist, setIsInAtLeastOneWatchlist] = useState(false)

  useEffect(() => {
    let foundInOneWatchlist = false

    personalWatchlists.every((personalWatchlist) => {
      foundInOneWatchlist |= Object.keys(personalWatchlist.players).includes(props.player.playerId)
      return !foundInOneWatchlist
    })

    setIsInAtLeastOneWatchlist(foundInOneWatchlist)
  }, [props.player.playerId, personalWatchlists])

  const handleNameClicked = (e) => {
    e.preventDefault()
    navigate('/players/' + props.player.playerId)
  }

  const handleClubLogoClicked = (e) => {
    e.preventDefault()
    navigate('/clubs/' + props.player.currentClubId)
  }

  const handleAddToWatchlistButtonClicked = (e, playerId) => {
    e.stopPropagation()
    ref.current.openWatchlistPopper(playerId, e.currentTarget)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px solid #FFFFFF',
        borderRadius: 7,
        ...props.sx
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ visibility: 'hidden', width: 45, height: 45, p: .5 }} />
        <Tooltip title={props.player.nationality} placement='right'>
          <Box sx={{ pt: 1 }}>
            <img src={'/img/common/flags/code/' + (props.player.nationalityCode ? props.player.nationalityCode : 'OLY') + '.gif'} alt='' height='18px' width='27px' />
          </Box>
        </Tooltip>
        <Tooltip title={props.player.currentClubName}>
          <Box sx={{ display: 'flex', backgroundColor: '#FFFFFF', borderRadius: '50%', alignSelf: 'flex-start', justifyContent: 'center', width: 45, height: 45, p: .5, cursor: 'pointer' }} onClick={handleClubLogoClicked}>
            <img src={'/img/clubs/logos/' + props.player.currentClubId + '.png'} alt='' height='100%' />
          </Box>
        </Tooltip>
      </Box>
      <Typography sx={{ color: '#F4D35E', fontSize: '2rem', fontWeight: 600, textAlign: 'center', cursor: 'pointer', my: 1 }} onClick={handleNameClicked}>{props.player.name}</Typography>
      <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600 }}>{props.player.currentPosition} / {props.player.age} <TranslatedContent code='years' defaultValue='Years' /></Typography>
      <Typography sx={{ color: '#04A8A3', fontWeight: 600, my: 1 }}>{props.player[`${props.param}`]}&nbsp;{props.uom}</Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: '0 0 22px 22px', borderTop: 0, mt: 2, px: 1, py: .3 }}>
        <Typography sx={{ color: '#31495A', fontSize: '1.5rem', fontWeight: 600, textTransform: 'uppercase' }}>Add to Watchlist</Typography>
        <IconButton sx={{ mx: .5 }} onClick={(e) => handleAddToWatchlistButtonClicked(e, props.player.playerId)}>
          <AddCircleIcon fill={isInAtLeastOneWatchlist ? '#04A8A3' : '#707070'} width='30px' height='30px' />
        </IconButton>
      </Box>
    </Box>
  )
})

const TopTenPlayers = (props) => {

  const userToken = useSelector(state => state.user.token)
  const roles = useSelector(state => state.filters.roles)

  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()
  const popperRef = useRef()

  const params = {
    'potentialPrice': { name: 'fairValue', uom: <UserCurrency /> },
    'salaryOptimal': { name: 'fairSalary', uom: <UserCurrency /> },
    'expectedPrice': { name: 'expectedPrice', uom: <UserCurrency /> },
    'effectiveness': { name: 'effectiveness' }
  }

  const [param, setParam] = useState(() => Object.keys(params)[0])
  const [filter, setFilter] = useState(() => { return { currentLeagueId: props.leagueId } })
  const [selectedRoles, setSelectedRoles] = useState([])
  const [players, setPlayers] = useState([])

  useEffect(() => {
    setFilter({ currentLeagueId: props.leagueId })
  }, [props.leagueId])

  useEffect(() => {
    if (userToken && Array.isArray(roles) && roles.length === 0) {
      filtersService.getRoles(userToken, dispatch)
        .then(res => {
          if (res.status === 200 && res.data.success) {
            dispatch(setFiltersRoles(res.data.data.roles))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (props.leagueId && param) {
      startTransition(() => {
        let page = { page: 1, per_page: 10 }
        let sort = { field: param, sort: 'desc' }
        transitionWrapper(
          playersService.getPlayers(userToken, dispatch, page, filter, sort),
          res => {
            if (res.status === 200) {
              setPlayers(res.data.data)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, param, filter])

  useEffect(() => {
    setFilter(prevFilter => {
      return {
        ...prevFilter,
        currentPosition: selectedRoles
      }
    })
  }, [selectedRoles])

  const handleSelectRole = (e, role) => {
    e.preventDefault()
    let tmp
    if (selectedRoles.includes(role)) {
      tmp = selectedRoles.filter(sr => sr !== role)
    } else {
      tmp = [...selectedRoles, role]
    }
    setSelectedRoles(tmp)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{
        color: 'white',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='topPlayers' defaultValue='Top Players' />
      </Typography>
      <Divider sx={{ backgroundColor: 'white', height: '.3rem', width: '100%' }} />
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, justifyContent: { xs: 'center', md: 'flex-end' }, alignItems: { xs: 'center' }, width: '100%', mt: 2, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 1, mr: { xs: 0, md: 10 }, mt: { xs: 2, md: 0 } }}>
          <Typography sx={{ display: { xs: 'none', md: 'block' }, color: 'white', fontSize: '2rem', fontWeight: 600, mr: 2 }}>
            <TranslatedContent code='selectPosition' defaultValue='Select Position' />
          </Typography>
          {roles.map((role, idx) => (
            <Box key={idx} sx={{ cursor: 'pointer', mx: 1 }} onClick={(e) => handleSelectRole(e, role)}>
              {positionFilterRenderer({ value: role, selected: selectedRoles.includes(role) })}
            </Box>
          ))}
        </Box>
        <StdSelect
          value={param}
          onChange={(e) => setParam(e.target.value)}>
          {Object.entries(params).map((e, i) => (
            <MenuItem key={i} id={e[0]} value={e[0]}>
              <TranslatedContent code={e[1].name} />
            </MenuItem>
          ))}
        </StdSelect>
      </Box>
      <Fragment>
        <Box sx={{ display: isPending ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
        <StdGridCarousel
          sx={{ display: isPending ? 'none' : 'block', width: '100%' }}
          GridContainerProps={{ spacing: 8 }}
          GridItemProps={{ md: 6, lg: 3, xl: 2.4 }}
          CarouselProps={{
            animation: 'slide',
            autoPlay: false,
            cycleNavigation: false,
            navButtonsAlwaysVisible: true,
            indicators: false
          }}>
          {players.map((player, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexDirection: 'column' }}>
              <TopPlayer ref={popperRef} player={player} param={param} uom={params[param].uom} sx={{ width: { xs: '70%', md: '100%' }, margin: 'auto', maxWidth: 400 }} />
              <Typography sx={{ display: { xs: 'block', md: 'none' }, color: '#FFFFFF', fontWeight: 600, textAlign: 'center', mt: 2 }}>
                {idx + 1} / 10
              </Typography>
            </Box>
          ))
          }
        </StdGridCarousel>
        <WatchlistPopper ref={popperRef} />
      </Fragment>
    </Box >
  )
}

export default TopTenPlayers