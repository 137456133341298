import axios from 'axios'
import { clearSession } from '../slices/userSlice'
import authService from './authService'

const API_HOST = process.env.REACT_APP_API_HOST
const API_PATH = process.env.REACT_APP_API_PATH
const API_VERSION = process.env.REACT_APP_API_VERSION

export const PAGE_ALL = { page: -1, per_page: -1 }

export const auth_api = axios.create({
  baseURL: API_HOST + API_PATH,
})

export const api_unversioned = (token, dispatch) => {
  if (!token)
    throw new Error('Empty token')
  return getApiClient(token, dispatch, API_HOST + API_PATH)
}

export const api_versioned = (token, dispatch) => {
  if (!token)
    throw new Error('Empty token')
  return getApiClient(token, dispatch, API_HOST + API_PATH + API_VERSION)
}

const getApiClient = (token, dispatch, baseUrl) => {
  let client = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    timeout: 30000
  })
  client.interceptors.response.use(
    response => {
      authService.setCookieToken(token)
      return response
    },
    error => {
      if (error.response.status === 401) {
        authService.removeCookieToken()
        dispatch(clearSession())
      }
      return error
    })
  return client
}

export const getQueryParams = (page, filter, sort) => {
  let queryParams = new URLSearchParams()
  if (page && page !== PAGE_ALL) {
    queryParams.append('page', page.page)
    queryParams.append('per_page', page.per_page)
  }
  if (filter) {
    for (let prop in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, prop)) {
        if (Array.isArray(filter[prop])) {
          filter[prop].forEach(v => {
            queryParams.append(prop + '[]', v)
          })
        } else {
          queryParams.append(prop, filter[prop])
        }
      }
    }
  }
  if (sort) {
    queryParams.append('sort', sort.field)
    queryParams.append('sortOrder', sort.sort)
  }
  return queryParams
}

export const checkImage = (path) => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve({ path, status: 'ok' })
    img.onerror = () => resolve({ path, status: 'error' })
    img.src = path
  })
}

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}
