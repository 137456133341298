import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import { Fragment, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import TranslatedContent from '../translations/translatedContent'

const StdGaugeSpectrumChart = (props) => {

  am5.addLicense('AM5C387037067')

  const chartId = crypto.randomUUID()
  const colors = ['#04A8A3', '#F4D35E', '#A1CBC2', '#787878']
  const translations = useSelector(state => state.translations.dictionary)

  useLayoutEffect(() => {

    let root = am5.Root.new(chartId)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ])

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 270,
        dx: -75,
        dy: -20
      })
    )

    let startingRadius = props.values.length === 4 ? 50 : 60
    props.values.forEach((value, idx) => {
      let serie = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: value,
          startAngle: 180,
          endAngle: ((270 - 180) * props.data[0][value] / props.fullScale) + 180,
          radius: am5.percent(startingRadius + (10 * idx) + 5),
          innerRadius: am5.percent(startingRadius + (10 * idx)),
        })
      )

      let colorSet = am5.ColorSet.new(root, {
        colors: [colors[idx], '#31495A']
      })

      serie.set('colors', colorSet)
      serie.slices.template.setAll({
        toggleKey: 'none',
        tooltipText: '{value}'
      })
      serie.slices.template.states.create('hover', { scale: 1 })
      serie.ticks.template.setAll({
        forceHidden: true,
        stroke: am5.color('#FFFFFF'),
        strokeOpacity: 1
      })
      serie.labels.template.setAll({
        forceHidden: true,
        text: '{value}',
        fontWeight: 600,
        fill: am5.color(colors[idx])
      })

      serie.data.setAll(props.data)
      serie.appear(1000, 100)
    })

    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, translations])

  return (
    <Fragment>
      <Box sx={{ display: props.loading ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        <CircularProgress />
      </Box>
      <Box sx={{ display: props.loading ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box id={chartId}
          sx={{
            width: 350,
            height: '100%'
          }} />
        <Tooltip title={<TranslatedContent code={props.chartName} description />} placement='bottom'>
          <Typography sx={{ position: 'relative', color: '#FFFFFF', fontSize: '1.4rem', lineHeight: 1.3, textAlign: 'center', whiteSpace: 'break-spaces', fontWeight: 600, zIndex: 5, top: -20 }}>
            <TranslatedContent code={props.chartName} />
          </Typography>
        </Tooltip>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', top: -10 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {props.values.map((v, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ backgroundColor: colors[idx], width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
                <Typography sx={{ color: colors[idx], fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                  <TranslatedContent code={v} />:&nbsp;{props.data[0][v]}&nbsp;{props.uom}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default StdGaugeSpectrumChart