import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Divider, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FontAwesomeSvgIcon from '../components/faSvgIcon'
import TranslatedContent from '../components/translations/translatedContent'
import settingsService from '../services/settingsService'
import { setCurrencies, setLanguages } from '../slices/settingsSlice'
import { setUserCurrency, setUserLang } from '../slices/userSlice'

const StdChangePasswordInput = (props) => {

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      autoComplete='new-password'
      value={props.value}
      onChange={props.onChange}
      endAdornment={
        <InputAdornment position='end'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}>
            {showPassword ? <FontAwesomeSvgIcon icon={faEyeSlash} /> : <FontAwesomeSvgIcon icon={faEye} />}
          </IconButton>
        </InputAdornment>}
      sx={{
        color: '#FFFFFF',
        borderRadius: '20px',
        backgroundColor: '#31495A',
        height: '40px',
        maxWidth: { xs: '100%', md: '50%', lg: '30%' },
        px: 2,
        '&.MuiInput-root:hover::before': {
          border: 0
        },
        '&::before': {
          border: 0
        },
        '&::after': {
          border: 0
        },
        'input:autofill': {
          boxShadow: '#31495A 0px 0px 0px 60px inset !important'
        }
      }} />
  )
}

const SettingsPage = () => {

  const user = useSelector((state) => state.user)
  const settings = useSelector((state) => state.settings)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [oldPwdHelperText, setOldPwdHelperText] = useState('')
  const [newPwdHelperText, setNewPwdHelperText] = useState('')
  const [confPwdHelperText, setConfPwdHelperText] = useState('')

  const [configLangs, setConfigLangs] = useState(settings.languages)
  const [configCurrs, setConfigCurrs] = useState(settings.currencies)

  const [selectedLang, setSelectedLang] = useState(user.lang)
  const [selectedCurr, setSelectedCurr] = useState(user.currency)

  useEffect(() => {
    if (!Array.isArray(settings.languages) || settings.languages.length === 0) {
      settingsService.getLanguages(user.token, dispatch)
        .then(res => {
          dispatch(setLanguages(res.data.data))
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(settings.currencies) || settings.currencies.length === 0) {
      settingsService.getCurrencies(user.token, dispatch)
        .then(res => {
          dispatch(setCurrencies(res.data.data))
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token])

  useEffect(() => {
    setSelectedLang(user.lang)
  }, [user.lang])

  useEffect(() => {
    setSelectedCurr(user.currency)
  }, [user.currency])

  useEffect(() => {
    setConfigLangs(settings.languages)
    setConfigCurrs(settings.currencies)
  }, [settings])

  const submitChangePassword = (e) => {
    e.preventDefault()
    if (validateChangePasswordForm()) {
      settingsService.resetPassword(user.token, dispatch, currentPassword, newPassword, confirmPassword)
        .then(res => {
          if (res.status === 200 && res.data.success) {
            navigate('/dashboard')
          } else {
            setAlertMessage('pwdChKo')
          }
        })
        .catch(err => console.error(err))
    }
  }

  const validateChangePasswordForm = () => {
    setAlertMessage('')
    let validation = true
    if (!currentPassword) {
      validation = false
      setOldPwdHelperText('mandatoryFieldError')
    } else {
      setOldPwdHelperText('')
    }
    if (!newPassword) {
      validation = false
      setNewPwdHelperText('mandatoryFieldError')
    } else if (newPassword.length < 8) {
      validation = false
      setNewPwdHelperText('pwdLengthError')
    } else {
      setNewPwdHelperText('')
    }
    if (!confirmPassword) {
      validation = false
      setConfPwdHelperText('mandatoryFieldError')
    } else {
      setConfPwdHelperText('')
    }
    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        validation = false
        setConfPwdHelperText('passwordMismatchError')
      } else {
        setConfPwdHelperText('')
      }
    }
    return validation
  }

  const handleApplySettingsClicked = (e) => {
    e.preventDefault()
    settingsService.setLanguage(user.token, dispatch, selectedLang)
      .then((res) => {
        if (res.status === 200)
          dispatch(setUserLang(selectedLang))
      })
      .catch(err => console.error(err))
    settingsService.setCurrency(user.token, dispatch, selectedCurr)
      .then((res) => {
        if (res.status === 200)
          dispatch(setUserCurrency(selectedCurr))
      })
      .catch(err => console.error(err))
  }

  return (
    <Box sx={{ textAlign: 'left', width: '100%' }}>
      <Typography sx={{ fontSize: '3rem', color: '#FFFFFF', textTransform: 'uppercase', fontWeight: '600' }} >
        <TranslatedContent code='settings' defaultValue='Settings' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 2, my: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
        <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 600, my: 1 }}>
          <TranslatedContent code='changePassword' defaultValue='Change password' />
        </Typography>
        <Box sx={{ width: '100%', px: { xs: 0, sm: 5 }, mt: 2 }}>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem' }}>
            <TranslatedContent code='currentPassword' defaultValue='Current Password' />
          </Typography>
          <FormControl sx={{ width: '100%', mb: 3 }}>
            <StdChangePasswordInput
              id='oldPwdInput'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <FormHelperText sx={{ color: 'red', position: 'absolute', top: 35 }}><TranslatedContent code={oldPwdHelperText} /></FormHelperText>
          </FormControl>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem' }}>
            <TranslatedContent code='newPassword' defaultValue='New Password' />
          </Typography>
          <FormControl sx={{ width: '100%', mb: 3 }}>
            <StdChangePasswordInput
              id='newPwdInput'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <FormHelperText sx={{ color: 'red', position: 'absolute', top: 35 }}><TranslatedContent code={newPwdHelperText} /></FormHelperText>
          </FormControl>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem' }}>
            <TranslatedContent code='confirmPassword' defaultValue='Confirm Password' />
          </Typography>
          <FormControl sx={{ width: '100%', mb: 3 }}>
            <StdChangePasswordInput
              id='confPwdInput'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <FormHelperText sx={{ color: 'red', position: 'absolute', top: 35 }}><TranslatedContent code={confPwdHelperText} /></FormHelperText>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', visibility: alertMessage ? 'visible' : 'hidden', opacity: alertMessage ? 1 : 0, transition: 'visibility 0s, opacity .5s ease-in-out', width: '100%', justifyContent: 'center' }}>
          <Typography sx={{ color: '#FFFFFF', position: 'absolute' }}>
            <TranslatedContent code={alertMessage} />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', mt: 2, mb: 2 }}>
          <Button sx={{
            color: 'black',
            fontSize: '1.3rem',
            backgroundColor: '#A1CBC2',
            height: 30,
            width: 150,
            borderRadius: 28,
            m: .5,
            '&:hover': {
              backgroundColor: '#A1CBC2',
            }
          }}
            onClick={submitChangePassword}>
            <TranslatedContent code='updatePassword' defaultValue='Update Password' />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: '1px', my: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 600, my: 1 }}>
          <TranslatedContent code='language' defaultValue='Language' />
        </Typography>
        <Grid container spacing={{ md: 2 }} sx={{ mt: 2, px: { xs: 0, sm: 5 } }}>
          {configLangs.map((l, idx) => {
            let active = (l.code === selectedLang)
            return (
              <Grid key={idx} item xs={12} md={4} lg={3} xl={2}>
                <Button startIcon={<img src={'/img/common/languages/' + l.code + '.gif'} alt='' height='18px' width='27px' />}
                  sx={{
                    color: active ? 'black' : 'white',
                    backgroundColor: active ? 'white' : 'transparent',
                    border: '1px solid white',
                    borderRadius: 25,
                    width: '100%',
                    my: .5,
                    '&:hover': {
                      color: active ? 'black' : 'white',
                      backgroundColor: active ? 'white' : 'transparent',
                    }
                  }}
                  onClick={() => setSelectedLang(l.code)}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {l.description}
                  </Typography>
                </Button>
              </Grid>
            )
          })}
        </Grid>
        <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 600, mt: 5, mb: 1 }}>
          <TranslatedContent code='currency' defaultValue='Currency' />
        </Typography>
        <Grid container spacing={{ md: 2 }} sx={{ mt: 2, px: { xs: 0, sm: 5 } }}>
          {configCurrs.map((c, idx) => {
            let active = (c.code === selectedCurr)
            return (
              <Grid key={idx} item xs={12} md={4} lg={3} xl={2}>
                <Button key={idx}
                  sx={{
                    color: active ? 'black' : 'white',
                    backgroundColor: active ? 'white' : 'transparent',
                    border: '1px solid white',
                    borderRadius: 25,
                    width: '100%',
                    my: .5,
                    '&:hover': {
                      color: active ? 'black' : 'white',
                      backgroundColor: active ? 'white' : 'transparent',
                    }
                  }}
                  onClick={() => setSelectedCurr(c.code)}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {c.code} | {c.name}
                  </Typography>
                </Button>
              </Grid>
            )
          })}
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', mt: 2, mb: 1 }}>
          <Button sx={{
            color: 'black',
            fontSize: '1.3rem',
            backgroundColor: '#A1CBC2',
            height: 30,
            width: 150,
            borderRadius: 28,
            m: .5,
            '&:hover': {
              backgroundColor: '#A1CBC2',
            }
          }}
            onClick={handleApplySettingsClicked}>
            <TranslatedContent code='applySettings' defaultValue='Apply Settings' />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SettingsPage