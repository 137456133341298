import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'

const StdMillionCurrencyFormat = forwardRef(function MillionCurrencyFormat(props, ref) {

  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      valueIsNumericString
      //prefix='€ '
      suffix=' M'
    />
  )
})

export default StdMillionCurrencyFormat