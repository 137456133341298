import { api_unversioned } from './commonService'

const getTickerPlayers = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/ticker')
}

const tickerService = {
  getTickerPlayers
}

export default tickerService