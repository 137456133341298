import { api_versioned, getQueryParams } from './commonService'

const getStatistics = (token, dispatch, page, filter, sort) => {
  return api_versioned(token, dispatch).get('/statistics', { params: getQueryParams(page, filter, sort) })
}

const getLeagueStats = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/entitydatastats/league/${id}`)
}

const getClubStats = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/entitydatastats/club/${id}`)
}

const getClusterStats = (token, dispatch, filter) => {
  return api_versioned(token, dispatch).get('/entitiesdatastats/cluster', { params: getQueryParams(null, filter, null) })
}

const getClusterStatsById = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/entitydatastats/cluster/${id}`)
}

const getLeagueClusterStats = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/entitydatastats/leaguecluster/${id}`)
}

const statisticsService = {
  getStatistics,
  getLeagueStats,
  getClubStats,
  getClusterStats,
  getClusterStatsById,
  getLeagueClusterStats
}

export default statisticsService