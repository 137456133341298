import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import { useLayoutEffect } from 'react'
import { Box } from '@mui/material'

const StdLineGraph = (props) => {
  
  am5.addLicense('AM5C387037067')
  
  const graphId = crypto.randomUUID()

  useLayoutEffect(() => {

    let root = am5.Root.new(graphId)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    )

    function generateData(value, date) {
      value = Math.round((Math.random() * 10 - 5) + value)
      am5.time.add(date, 'day', 1)
      return {
        date: date.getTime(),
        value: value
      }
    }

    function generateDatas(count) {
      var date = new Date()
      date.setHours(0, 0, 0, 0)
      var value = 100
      var data = []
      for (var i = 0; i < count; ++i) {
        data.push(generateData(value, date))
      }
      return data
    }

    // Create X axis
    var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit: 'day',
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, {
        fill: '#FFFFFF'
      }),
      tooltip: am5.Tooltip.new(root, {})
    }))

    // Create Y axis
    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }))

    // Add series
    var series1 = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      name: 'Club Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      stroke: am5.color('#04A8A3'),
      tooltip: am5.Tooltip.new(root, {
        labelText: '{valueY}'
      })
    }))
    series1.strokes.template.setAll({ strokeWidth: 2 })
    series1.set('fill', am5.color('#04A8A3'))

    var series2 = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      name: 'League Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      stroke: am5.color('#F4D35E'),
      tooltip: am5.Tooltip.new(root, {
        labelText: '{valueY}'
      })
    }))
    series2.strokes.template.setAll({ strokeWidth: 2 })
    series2.set('fill', am5.color('#F4D35E'))

    var series3 = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      name: 'Cluster Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      stroke: am5.color('#A1CBC2'),
      tooltip: am5.Tooltip.new(root, {
        labelText: '{valueY}'
      })
    }))
    series3.strokes.template.setAll({ strokeWidth: 2 })
    series3.set('fill', am5.color('#A1CBC2'))

    // Set data
    series1.data.setAll(generateDatas(20))
    series2.data.setAll(generateDatas(20))
    series3.data.setAll(generateDatas(20))

    // Add cursor
    var cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
      behavior: 'none'
    }))
    cursor.lineY.set('visible', false)

    if (props.legend) {
      let legend = chart.children.push(am5.Legend.new(root, {}))
      legend.data.setAll(chart.series.values)
    }

    series1.appear(1000)
    series2.appear(1000)
    series3.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
  }, [])

  return (
    <Box id={graphId}
      sx={{
        width: '100%',
        ...props.sx
      }}></Box>
  )
}

export default StdLineGraph