import { createTheme } from '@mui/material/styles'

const mrtTheme = createTheme({
  palette: {
    background: {
      default: '#15222B'
    }
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: `'Barlow', 'Roboto', sans-serif`,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '2rem'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          border: '2px solid #000000',
          borderRadius: 35,
          '& fieldset:hover': {
            border: 0
          },
          '&.Mui-focused': {
            '&.Mui-focused fieldset': {
              border: 0,
              borderColor: '#000000'
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(30, 41, 51, .8)',
          fontSize: '1.4rem',
          fontWeight: 400
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& tr:nth-of-type(even)': {
            backgroundColor: '#223441',
            '& td': {
              backgroundColor: 'inherit !important'
            }
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#31495A',
            '& td': {
              backgroundColor: 'inherit !important'
            }
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: '#15222B'
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: 70,
          opacity: 'inherit !important',
          '& .MuiIconButton-root': {
            color: 'inherit',
            opacity: 1
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'inherit',
          '&:nth-of-type(even)': {
            color: '#A1CBC2'
          },
          '&:nth-of-type(odd)': {
            color: '#FFFFFF'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          '& .MuiIconButton-root': {
            color: 'inherit'
          },
          '& .Mui-disabled': {
            color: 'inherit',
            opacity: .26
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&:hover': {
            color: 'inherit'
          },
          '&.Mui-active': {
            color: 'inherit'
          },
          '&.Mui-active .MuiTableSortLabel-icon': {
            color: 'inherit'
          }
        }
      }
    },
  }
})

export default mrtTheme