import { Box, Grid, useMediaQuery } from '@mui/material'
import { Children } from 'react'
import Carousel from 'react-material-ui-carousel'

const StdGridCarousel = (props) => {

  const arrayChildren = Children.toArray(props.children)

  const isBreakpointMd = useMediaQuery(theme => theme.breakpoints.up('md'))

  return (
    <Box sx={props.sx}>
      {isBreakpointMd ?
        <Grid container {...props.GridContainerProps}>
          {Children.map(arrayChildren, (child, idx) => {
            return (
              <Grid item key={idx} {...props.GridItemProps}>
                {child}
              </Grid>
            )
          })}
        </Grid>
        :
        <Carousel sx={{ width: '100%' }} {...props.CarouselProps}>
          {props.children}
        </Carousel>}
    </Box>
  )
}

export default StdGridCarousel