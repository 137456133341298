import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const TranslatedContent = (props) => {

  const translations = useSelector(state => state.translations)

  const [translatedText, setTranslatedText] = useState('')

  useEffect(() => {
    let translation = translations.dictionary.find((t) => t.code === props.code)
    if (translation && translation.description && props.description)
      setTranslatedText(parse(translation.description))
    else if (translation && translation.text)
      setTranslatedText(translation.text)
    else if (props.defaultValue)
      setTranslatedText(props.defaultValue)
    else
      setTranslatedText(props.code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations, props.code])

  // FIXME: temporary patch to show translations code
  //return `{code:${props.code}}`
  return translatedText
}

export default TranslatedContent