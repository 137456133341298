import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  languages: [],
  currencies: [],
  lastUpdate: null
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguages(state, action) {
      state.languages = action.payload
    },
    setCurrencies(state, action) {
      state.currencies = action.payload
    },
    setLastUpdate(state, action) {
      state.lastUpdate = action.payload
    }
  },
})

export const { setLanguages, setCurrencies, setLastUpdate } = settingsSlice.actions