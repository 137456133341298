import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import * as am5xy from '@amcharts/amcharts5/xy'
import { Box } from '@mui/material'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const StdLeagueTopClubsChart = (props) => {

  am5.addLicense('AM5C387037067')

  const navigate = useNavigate()

  const [windowInnerWidth, setWindowInnerWidth] = useState(0)

  useEffect(() => {
    const setInnerWidth = () => {
      setWindowInnerWidth(window.innerWidth)
    }
    window.addEventListener('resize', setInnerWidth)
    return () => window.removeEventListener('resize', setInnerWidth)
  }, [])

  useLayoutEffect(() => {

    const getSeriesItem = (category) => {
      for (let i = 0; i < serie.dataItems.length; i++) {
        let dataItem = serie.dataItems[i]
        if (dataItem.get('categoryY') === category) {
          return dataItem
        }
      }
    }

    const sortCategoryAxis = () => {

      serie.dataItems.sort((x, y) => x.get('valueX') - y.get('valueX'))

      am5.array.each(yAxis.dataItems, (dataItem) => {
        let seriesDataItem = getSeriesItem(dataItem.get('category'))

        if (seriesDataItem) {
          let index = serie.dataItems.indexOf(seriesDataItem)
          let deltaPosition = (index - dataItem.get('index', 0)) / serie.dataItems.length
          dataItem.set('index', index)
          dataItem.set('deltaPosition', -deltaPosition)
          dataItem.animate({
            key: 'deltaPosition',
            to: 0,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          })
        }
      })

      yAxis.dataItems.sort((x, y) => x.get('index') - y.get('index'))

      let idx = 1
      am5.array.eachReverse(yAxis.dataItems, (dataItem) => dataItem.count = idx++)
    }

    let root = am5.Root.new(props.chartName)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root)
    ])

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none'
    }))

    chart.zoomOutButton.set('forceHidden', true)

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 1
    })
    yRenderer.grid.template.setAll({
      visible: false
    })
    yRenderer.labels.template.setAll({
      text: '[#D3D3D3 fontWeight: 400]{count} [/]{category}',
      fill: am5.color('#F4D35E'),
      fontSize: '1.4rem',
      fontFamily: 'Barlow, Roboto, sans-serif',
      textAlign: 'left',
      width: .3 * chart.width(),
      maxWidth: .3 * chart.width(),
      oversizedBehavior: 'truncate',
      tooltipText: '{category}',
      fontWeight: 600,
      tooltipX: am5.p100,
      cursorOverStyle: 'pointer'
    })
    yRenderer.labels.template.events.on('click', (e) => {
      navigate('/clubs/' + e.target.dataItem.dataContext.clubId)
    })
    yRenderer.labels.template.setup = (target) => {
      target.set('background', am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 0
      }))
    }
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: 'shortName',
      renderer: yRenderer
    }))
    yAxis.labelsContainer.set('tooltip', am5.Tooltip.new(root, {
      pointerOrientation: 'left'
    }))

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0
    })
    xRenderer.grid.template.setAll({
      visible: false
    })
    xRenderer.labels.template.setAll({
      forceHidden: true
    })
    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: 0,
      extraMax: 0.1,
      renderer: xRenderer
    }))

    let serie = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: props.chartName,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: props.valueField,
      categoryYField: 'shortName',
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'left',
        labelText: '{valueX} ' + props.uom
      })
    }))
    serie.columns.template.setAll({
      height: 10
    })
    serie.columns.template.adapters.add('fill', () => am5.color('#04A8A3'))
    serie.columns.template.adapters.add('stroke', () => am5.color('#04A8A3'))

    xAxis.data.setAll(props.data)
    yAxis.data.setAll(props.data)
    serie.data.setAll(props.data)
    sortCategoryAxis()

    let cursor = am5xy.XYCursor.new(root, {
      behavior: 'none',
      xAxis: xAxis,
      yAxis: yAxis
    })

    cursor.lineX.set('visible', false)
    cursor.lineY.set('visible', false)

    chart.set('cursor', cursor)

    serie.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.valueField, props.uom, windowInnerWidth])

  return (
    <Box id={props.chartName}
      sx={{
        width: '100%',
        height: { xs: 28 * props.numOfClubs, lg: 40 * props.numOfClubs, xl: 600 },
        ...props.sx
      }}></Box>
  )
}

export default StdLeagueTopClubsChart