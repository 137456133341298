import { Box, Divider, Typography } from '@mui/material'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { currencyHeaderRenderer, decimalNumberRenderer, positionGridRenderer, translatedHeaderRenderer } from '../data/formatters/dataFormatters'
import StdDataGrid from '../data/stdDataGrid'
import '../data/stdDataGrid.css'
import { AddToWatchlistCell } from '../players/stdPlayersTable'
import TranslatedContent from '../translations/translatedContent'
import WatchlistPopper from '../watchlists/watchlistPopper'

const StdMiniWatchlistTable = (props) => {

  const nameColumn = {
    header: 'Name',
    accessorKey: 'name',
    maxSize: 140,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', className: 'StdHeader' },
    muiTableBodyCellProps: { align: 'left', className: 'StdColumn' },
    Header: () => (
      <Typography sx={{ color: '#04A8A3', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}><TranslatedContent code='players' defaultValue='Players' /></Typography>
    ),
    Cell: ({ cell }) => (
      <Typography sx={{ color: '#F4D35E', fontSize: '1.5rem', fontWeight: 600, cursor: 'pointer' }}
        onClick={() => navigate('/players/' + cell.row.id)}>
        {cell.getValue()}
      </Typography>
    )
  }

  const addToWatchlistColumn = {
    header: 'watchlist',
    maxSize: 20,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'center', className: 'StdHeader StdAddWatchlistCell' },
    muiTableBodyCellProps: { align: 'center', className: 'StdAddWatchlistCell' },
    Header: <></>,
    Cell: ({ cell }) => <AddToWatchlistCell cell={cell} popperRef={popperRef} />
  }

  const columns = useMemo(() => [
    addToWatchlistColumn,
    nameColumn,
    { header: 'position', accessorKey: 'currentPosition', enableColumnOrdering: false, enableColumnDragging: false, Cell: positionGridRenderer, Header: translatedHeaderRenderer, size: 100, enableSorting: false, enableColumnActions: false, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'fairValue', accessorKey: 'potentialPrice', enableColumnOrdering: false, enableColumnDragging: false, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, size: 100, enableSorting: false, enableColumnActions: false, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } }
  ], [])

  const popperRef = useRef()
  const navigate = useNavigate()

  const [players, setPlayers] = useState([])
  const [columnVisibility, setColumnVisibility] = useState({})

  useEffect(() => {
    const timeout = setTimeout(() => {
      let headerWrappers = document.getElementsByClassName('Mui-TableHeadCell-Content-Wrapper')
      for (let i = 0; i < headerWrappers.length; i++) {
        headerWrappers.item(i).removeAttribute('title')
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (props.isPersonalWatchlist) {
      setColumnVisibility({ watchlist: false })
    }
  }, [props.isPersonalWatchlist])

  useEffect(() => {
    if (props.watchlist && props.watchlist.players) {
      setPlayers(Object.values(props.watchlist.players))
    }
  }, [props.watchlist])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      {props.watchlist ?
        <Fragment>
          <Box sx={{ display: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: '#A1CBC2',
                  fontSize: '2rem',
                  fontWeight: '600',
                  textTransform: 'uppercase'
                }}>
                {props.watchlist && props.watchlist.name}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ display: 'none', backgroundColor: 'white', height: 2, width: '100%', mb: 1 }} />
          <StdDataGrid
            columns={columns}
            data={players}
            rowId={(player) => player.playerId}
            enablePagination={false}
            enableBottomToolbar={false}
            page={0}
            pageSize={10}
            rowCount={players.length}
            columnVisibility={columnVisibility} />
          <WatchlistPopper ref={popperRef} />
        </Fragment>
        :
        <Fragment>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '3rem', fontWeight: 700, textTransform: 'uppercase', textAlign: 'center' }}>
              <TranslatedContent code='noWatchlist' defaultValue='No Watchlist Defined' />
            </Typography>
          </Box>
        </Fragment>}
    </Box>
  )
}

export default StdMiniWatchlistTable