import { Box } from '@mui/material'
import './stdFootballLoadingPage.css'

const StdFootballLoadingPage = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        animation: 'fadeinout 2s ease-in-out infinite',
        WebkitAnimation: ''
      }}>
      <img src='/img/common/stdLogo.svg' alt='StdFootball' width='260px' height='110px' />
    </Box>
  )
}

export default StdFootballLoadingPage