import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  total: 0,
  clubClusters: []
}

export const clubsSlice = createSlice({
  name: 'clubs',
  initialState,
  reducers: {
    setClubsTotal(state, action) {
      state.total = action.payload
    },
    setClubClusters(state, action) {
      state.clubClusters = action.payload
    }
  },
})

export const { setClubsTotal, setClubClusters } = clubsSlice.actions