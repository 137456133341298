import { Box, Divider, Typography } from '@mui/material'
import TranslatedContent from '../components/translations/translatedContent'

const FaqsPage = () => {
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
      <Typography sx={{ fontSize: '3rem', color: 'white', textTransform: 'uppercase', fontWeight: '600' }} >
        <TranslatedContent code='faq' defaultValue='FAQ' />
      </Typography>
      <Divider sx={{ backgroundColor: 'white', height: 2, mt: 1 }} />
      <Box className='stdScrollable' sx={{ width: { xs: '100%', md: '70%' }, height: '100%', alignSelf: 'center' }}>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>1</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            What is the player's Fair Value?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The player's Fair Value represents the most likely economic value that he would generate under the assumption of performance continuity and standard contract duration for a club of an appropriate level, correctly informed and rationally operating on the market.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 4', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>2</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            How does Standard Football calculate a player's Fair Value?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The following diagram represents the end-to-end procedure, including sub-processing and all datasets.
          </Typography>
          <Box sx={{ gridRow: '3 / 4', width: '100%', px: 1, m: 1 }}><img src='/img/faqs/tabella_01.png' alt='' style={{ width: '100%', maxWidth: 650 }} /></Box>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 4', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>3</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Why may the player's Fair Value significantly deviate from the actual transfer price?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The Fair Value should not be confused with the market price.
            The former is a broad measure of an asset's worth and is not the same as market price, which refers to the actual price of an asset in the market.
            The Fair Value assumes that sellers and buyers are random rational actors of an open market who behave in their best interest and would not substantially overpay (in the case of buyers) or underprice (in the case of sellers), have a reasonable knowledge of the asset, are free of undue pressure to trade, and given a reasonable period for completing the transaction.
            It is common that the market price of an asset can deviate from the ex-ante Fair Value.
            This can depend on information asymmetries, specific constraints or pressures, and subjective perception, expectations, or needs at a given time.
            The same reasons can determine significant differences between the Fair Salary and the transfer price.
          </Typography>
          <Box sx={{ gridRow: '3 / 4', px: 1, m: 1 }}><img src='/img/faqs/tabella_02.png' alt='' style={{ width: '100%', maxWidth: 650 }} /></Box>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>4</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            What is the player's Residual Value?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The player's Residual Value represents the most likely economic value that he will generate for the current club during the residual contract, under the assumption of performance continuity.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>5</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            What is the player's Fair Salary?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The player's Fair Salary represents the net yearly salary payable by a club of an appropriate level for a standard contract duration, under the assumption of performance continuity.
            The Fair Salary can differ from the actual salary because of market competitiveness and contingent reasons.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>6</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            How often does SF update its estimates?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            SF ensures weekly updates of all its indicators.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>7</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Why does the Fair Value usually deviate from the asset value disclosed in the club's financial statement?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            Because unlike the “fair value” standard practice (<a href='https://www.ifrs.org/issued-standards/list-of-standards/ifrs-13-fair-value-measurement/' target='_blank' rel='noreferrer'>IFRS 13, Fair Value Measurement</a>), almost all football clubs disclose their value of intangible assets using the “at cost” principle according to IAS 38 article.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>8</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Does SF estimate sports or economic indicators?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            We focus on estimating economic indicators. Nevertheless, our procedures include the estimation of the expected player's performance as the main contribution to his economic value.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>9</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            How wide is the observation window of the player's performance?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            Our econometric analyses carried out on appropriate performance and economic time series show that 18 months is the optimal observation window to minimize risks of over and under estimations.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>10</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            How does the contract duration affect the estimates?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            The player generates value along with his economic lifespan.
            Therefore, the duration of the contract has a substantial impact on the club's economic net present value.
            Our estimates consider standard contract durations ranging from 1 to 5 years in relation to the player's age.
            In addition, only for the Player's Residual Value, SF also considers the actual remaining duration of the current contract.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>11</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            What input parameters does SF consider for its estimation?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            For its estimations, SF uses a wide set of data, including: players' characteristics, players' hard and soft skills and main positions, players' performances (241 dimensions) in the last 18 months, players' sports rights contract duration, clubs' general characteristics (league, country, ...), clubs' sports achievements for the last 5 years, clubs' revenues (including transfer markets) in the last 5 years, clubs' labor costs and players'wages costs, players' market transactions in the last 10 windows, countries' tax and social security framework, and football solidarity and training compensation obligations.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>12</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Does the estimate procedure consider any element possibly influencing the player's market price?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            We constantly work to improve our estimates by integrating additional phenomena and drivers into the models and considering an increasing number of observed data.
            At the moment, our estimates do not consider add-on and sell-on fees, player's salary bonuses, club's specific and contingent needs, or reason for not-played minutes (injury, suspension, technical).
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>13</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Can a player have a negative Fair Value?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            No, because the estimation of the Fair Value is based on the assumptions of performance continuity and Fair Salary.
            Instead, a player can have a negative Residual Value when the residual contract lifespan generates more costs (high salary) than revenues (low performance), determining a negative NPV.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>14</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Are SF estimates affected by errors?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            No estimate is error-free.
            SF adopts high-quality data and a robust estimation procedure, but all the adopted precautions do not completely prevent some estimates from being affected by accuracy errors outside the acceptability range.
            In the rare cases where this occurs, it usually depends on incorrect or out-of-date data sources.
            If you think you have encountered inaccurate estimates, you can report it to the following address: <a href='mailto:report@stdfootball.com'>report@stdfootball.com</a>.
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'start', columnGap: 2, fontWeight: 600, fontSize: '2.4rem', my: 3 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, gridRow: '1 / 3', color: '#15222B', backgroundColor: 'white', height: 40, width: 40, textAlign: 'center', borderRadius: 25 }}>15</Box>
          <Typography component='h3' sx={{ gridRow: '1 / 2', color: '#04A8A3', fontWeight: 600, fontSize: '2.4rem' }}>
            Does SF change its estimation procedures over time?
          </Typography>
          <Typography sx={{ gridRow: '2 / 3', color: 'white' }}>
            We continuously work to refine our models and procedures to uphold the highest level of excellence.
          </Typography>
        </Box>
      </Box >
    </Box >
  )
}

export default FaqsPage