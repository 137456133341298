import { Box, ClickAwayListener, Divider, Grow, IconButton, Input, Paper, Tooltip, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import watchlistsService from '../../services/watchlistsService'
import StdPopper, { StdPopperArrow } from '../layout/stdPopper'
import TranslatedContent from '../translations/translatedContent'
import CloseIcon from '@mui/icons-material/Close'
import { setPersonalWatchlists } from '../../slices/watchlistsSlice'

const PopperContent = (props) => {

  const dispatch = useDispatch()
  const userToken = useSelector(state => state.user.token)

  const [newWatchlistName, setNewWatchlistName] = useState('')
  const [newWatchlistInputError, setNewWatchlistInputError] = useState(false)
  const [addWatchlistButtonEnabled, setAddWatchlistButtonEnabled] = useState(true)

  const handleWatchlistClicked = (w) => {
    let lastPlayerPosition = 0
    w.filter.players.forEach((p) => lastPlayerPosition = Math.max(p.position, lastPlayerPosition))
    lastPlayerPosition += 1
    watchlistsService.updateWatchlist(userToken, dispatch, w.id, {
      players: JSON.stringify([...w.filter.players, { "id": `${props.playerId}`, "position": `${lastPlayerPosition}` }])
    })
      .then(res => {
        if (res.status === 200) {
          props.close()
        }
      })
  }

  const handleNewWatchlistNameChanged = (e) => {
    e.preventDefault()
    setNewWatchlistName(e.target.value)
  }

  const handleCreateWatchlist = (e) => {
    e.preventDefault()
    if (newWatchlistName !== '') {
      setNewWatchlistInputError(false)
      setAddWatchlistButtonEnabled(false)
      let lastWPosition = 0
      props.watchlists.forEach((w) => lastWPosition = Math.max(w.position, lastWPosition))
      watchlistsService.createWatchlist(userToken, dispatch, {
        name: newWatchlistName,
        position: lastWPosition + 1,
        players: `[{"id": "${props.playerId}","position": "1"}]`
      })
        .then(res => {
          if (res.status === 201) {
            props.refreshWatchlists()
            setNewWatchlistName('')
          }
          setAddWatchlistButtonEnabled(true)
        })
        .catch(err => console.error(err))
    } else {
      setNewWatchlistInputError(true)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        p: 1.5
      }}>
      <Box sx={{ overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#15222B', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
            <TranslatedContent code='addToYourWatchlist' defaultValue='Add to your watchlist' />
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon sx={{ width: '2rem', height: '2rem' }} />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: '#707070', height: '.2rem', width: '100%', my: 1 }} />
        <Box sx={{ height: '75%', overflow: 'auto' }}>
          {props.watchlists.map((w, idx) => {
            let isAlreadyPresent = Object.keys(w.players).includes(props.playerId)
            return (
              <Tooltip key={idx} title={isAlreadyPresent ? <TranslatedContent code='watchlistAlreadyPresent' defaultValue={`This player is already on the ${w.name} watchlist. To manage your lists go to the Watchlist page.`} /> : ''}>
                <Box key={idx}
                  onClick={() => { if (!isAlreadyPresent) handleWatchlistClicked(w) }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: isAlreadyPresent ? '#FFFFFF' : '#04A8A3',
                    backgroundColor: isAlreadyPresent ? '#04A8A3' : 'transparent',
                    width: '95%',
                    cursor: isAlreadyPresent ? 'auto' : 'pointer',
                    borderRadius: 2,
                    pr: 1,
                    my: .3,
                    '&:hover': {
                      color: isAlreadyPresent ? '#FFFFFF' : '#04A8A3',
                      backgroundColor: isAlreadyPresent ? '#04A8A3' : '#FFFFFF'
                    }
                  }}
                >
                  <Typography sx={{ color: 'inherit', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase', mx: 1 }}>
                    {w.name}
                  </Typography>
                  {!isAlreadyPresent && <AddCircleIcon fill='#04A8A3' />}
                </Box>
              </Tooltip>
            )
          })}
        </Box>
      </Box>
      <Box>
        <Typography sx={{ color: '#15222B', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
          <TranslatedContent code='createNewWatchlist' defaultValue='Create new watchlist' />
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          <Input
            autoFocus
            error={newWatchlistInputError}
            sx={{
              height: '2.6rem',
              backgroundColor: '#FFFFFF',
              border: '1px solid #707070',
              outline: newWatchlistInputError ? '1px solid red' : 0,
              '&:hover:not(.Mui-disabled)::before': { border: 0 },
              '&::before': { border: 0 },
              '&::after': { border: 0 }
            }}
            value={newWatchlistName}
            onChange={handleNewWatchlistNameChanged}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCreateWatchlist(e)
              }
            }}
          />
          <IconButton sx={{ position: 'relative', left: -20 }} onClick={handleCreateWatchlist}>
            <AddCircleIcon fill='#04A8A3' width='40px' height='40px' />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

const WatchlistPopper = forwardRef((props, ref) => {

  const dispatch = useDispatch()

  const userToken = useSelector(state => state.user.token)
  const personalWatchlists = useSelector(state => state.watchlists.personal)

  const [open, setOpen] = useState(false)
  const [playerId, setPlayerId] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [arrowRef, setArrowRef] = useState(null)
  const [watchlists, setWatchlists] = useState(personalWatchlists)

  useImperativeHandle(ref, () => ({
    openWatchlistPopper(playerId, anchorEl) {
      setPlayerId(playerId)
      setAnchorEl(anchorEl)
      setOpen(true)
    }
  }))

  useEffect(() => {
    setWatchlists(personalWatchlists)
  }, [personalWatchlists])

  useEffect(() => {
    if (open) {
      refreshWatchlists()
    }
  }, [open])

  const refreshWatchlists = () => {
    watchlistsService.getWatchlists(userToken, dispatch)
      .then(res => {
        if (res.status === 200) {
          dispatch(setPersonalWatchlists(res.data.data))
        }
      })
      .catch(err => console.error(err))
  }

  const handleClose = () => {
    refreshWatchlists()
    setOpen(false)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          setOpen(false)
        }
      }}>
      <StdPopper
        placement='bottom-start'
        open={open}
        anchorEl={anchorEl}
        arrow={true}
        disablePortal={false}
        transition
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: false,
              rootBoundary: 'viewport',
              padding: 8
            }
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef
            }
          }
        ]}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Box>
              <StdPopperArrow ref={setArrowRef} className='MuiPopper-arrow' />
              <Paper
                sx={{
                  backgroundColor: '#A1CBC2',
                  borderRadius: 0,
                  height: '26.5rem',
                  width: '26rem'
                }}>
                <PopperContent watchlists={watchlists} playerId={playerId} refreshWatchlists={refreshWatchlists} close={handleClose} />
              </Paper>
            </Box>
          </Grow>
        )}
      </StdPopper>
    </ClickAwayListener>
  )
})

export default WatchlistPopper