import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  total: 0,
  leagueGroups: []
}

export const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    setLeaguesTotal(state, action) {
      state.total = action.payload
    },
    setLeagueGroups(state, action) {
      state.leagueGroups = action.payload
    }
  },
})

export const { setLeaguesTotal, setLeagueGroups } = leaguesSlice.actions