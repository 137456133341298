import { Backdrop, Box, CircularProgress, Divider, Grid, MenuItem, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TranslatedContent from '../translations/translatedContent'
import { currencyUomRenderer } from '../data/formatters/dataFormatters'
import { color } from '@amcharts/amcharts5'

const Revenues = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const revenuesFormatter = (part, whole) => {
    let result = (part / whole) * 100
    return `(${result.toFixed(2)}%)`;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='financials' defaultValue='Financials' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%', mb: 2 }} />
      <Grid container spacing={{ xs: 0, lg: 5, md: 5 }} sx={{ px: { xs: 0, xl: 5 } }}>
        <Grid item xs={12} md={3} lg={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '150px', justifyContent: 'center', width: '100%', mb: 1, backgroundColor: '#31495A', borderRadius: '18px' }}>
            <Tooltip title={<TranslatedContent code='TotalRevenues' defaultValue='Total revenues' description />}>
              <Typography sx={{ color: '#04A8A3', textAlign: 'center', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                <TranslatedContent code='TotalRevenues' defaultValue='Total revenues' />
                <h4 style={{color: '#F4D35E'}}>{currencyUomRenderer({ value: props?.club?.lastRevenues })}</h4>
              </Typography>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '150px', justifyContent: 'center', width: '100%', mb: 1, backgroundColor: '#31495A', borderRadius: '18px', p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Tooltip title={<TranslatedContent code='streamOfRevenues' defaultValue='Stream of revenues' description />}>
              <Typography sx={{ color: '#04A8A3', textAlign: 'center', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                <TranslatedContent code='streamOfRevenues' defaultValue='Stream of revenues' />
              </Typography>
            </Tooltip>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3} key={1}>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip title={<TranslatedContent code='broadcasting' defaultValue='broadcasting' description />}>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    <TranslatedContent code='broadcasting' defaultValue='Broadcasting' />
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle1" sx={{ color: '#F4D35E', fontSize: '16px', fontWeight: 'bold' }}>
                  {props?.club?.broadcastingRevenues === null ? "N/A" : currencyUomRenderer({ value: props?.club?.broadcastingRevenues })}
                  <span></span>  {revenuesFormatter(props?.club?.broadcastingRevenues, props?.club?.lastRevenues)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3} key={2}>
              <Box sx={{ textAlign: 'center' }}>
              <Tooltip title={<TranslatedContent code='commercial' defaultValue='commercial' description />}>
                <Typography variant="h6" sx={{ color: '#fff' }}>
                  <TranslatedContent code='commercial' defaultValue='Commercial' />
                </Typography>
              </Tooltip>
                <Typography variant="subtitle1" sx={{ color: '#F4D35E', fontSize: '16px', fontWeight: 'bold' }}>
                  {props?.club?.commericialRevenues === null ? "N/A" : currencyUomRenderer({ value: props?.club?.commericialRevenues })}
                  <span></span>  {revenuesFormatter(props?.club?.commericialRevenues, props?.club?.lastRevenues)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3} key={3}>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip title={<TranslatedContent code='matchday' defaultValue='matchday' description />}>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    <TranslatedContent code='matchday' defaultValue='Matchday' />
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle1" sx={{ color: '#F4D35E', fontSize: '16px', fontWeight: 'bold' }}>
                  {props?.club?.matchDayRevenues === null ? "N/A" : currencyUomRenderer({ value: props?.club?.matchDayRevenues })}
                  <span></span>  {revenuesFormatter(props?.club?.matchDayRevenues, props?.club?.lastRevenues)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3} key={4}>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip title={<TranslatedContent code='otherRevenues' defaultValue='otherRevenues' description />}>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    <TranslatedContent code='otherRevenues' defaultValue='Other revenues' />
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle1" sx={{ color: '#F4D35E', fontSize: '16px', fontWeight: 'bold' }}>
                  {props?.club?.otherRevenues === null ? "N/A" : currencyUomRenderer({ value: props?.club?.otherRevenues })}
                  <span></span> {revenuesFormatter(props?.club?.otherRevenues, props?.club?.lastRevenues)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
    </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px', width: '100%', mb: 1, backgroundColor: '#31495A', borderRadius: '18px' }}>
            <Tooltip title={<TranslatedContent code='projectedRevenues' defaultValue='Projected revenues' description />}>
              <Typography sx={{ color: '#04A8A3', textAlign: 'center', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                <TranslatedContent code='projectedRevenues' defaultValue='Projected revenues' />
                <h4 style={{color: '#F4D35E'}}>{currencyUomRenderer({ value: props?.club?.revenues })}</h4>
              </Typography>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default Revenues