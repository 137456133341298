import { api_unversioned } from './commonService'

const getLeagueGroups = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/leaguegroups')
}

const getCountries = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/countries')
}

const getNationalities = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/nationalities')
}

const getClubClusters = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/clubclusters')
}

const getRoles = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/roles')
}

const getPlayerAges = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/ages')
}

const getPlayerExpectedPrices = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/expectedprices')
}

const getPlayerExpectedSalaries = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/expectedsalaries')
}

const filtersService = {
  getLeagueGroups,
  getCountries,
  getNationalities,
  getClubClusters,
  getRoles,
  getPlayerAges,
  getPlayerExpectedPrices,
  getPlayerExpectedSalaries
}

export default filtersService