export const coordinatesMap = {
  '3-4-2-1': {
    1: { bottom: 40 },
    2: { bottom: 150, left: 30 },
    3: { bottom: 150 },
    4: { bottom: 150, right: 30 },
    5: { bottom: 325, left: 0 },
    6: { bottom: 250, left: 75 },
    7: { bottom: 250, right: 75 },
    8: { bottom: 325, right: 0 },
    9: { bottom: 400, left: 75 },
    10: { bottom: 400, right: 75 },
    11: { bottom: 480 }
  },
  '3-4-3': {
    1: { bottom: 40 },
    2: { bottom: 150, left: 30 },
    3: { bottom: 150 },
    4: { bottom: 150, right: 30 },
    5: { bottom: 300, left: 0 },
    6: { bottom: 250, left: 75 },
    7: { bottom: 250, right: 75 },
    8: { bottom: 300, right: 0 },
    9: { bottom: 425, left: 20 },
    10: { bottom: 480 },
    11: { bottom: 425, right: 20 }
  },
  '3-5-2': {
    1: { bottom: 40 },
    2: { bottom: 150, left: 30 },
    3: { bottom: 150 },
    4: { bottom: 150, right: 30 },
    5: { bottom: 350, left: 0 },
    6: { bottom: 265, left: 30 },
    7: { bottom: 265 },
    8: { bottom: 265, right: 30 },
    9: { bottom: 350, right: 0 },
    10: { bottom: 480, left: 75 },
    11: { bottom: 480, right: 75 }
  },
  '4-1-2-1-2': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 250 },
    7: { bottom: 325, left: 0 },
    8: { bottom: 325, right: 0 },
    9: { bottom: 375 },
    10: { bottom: 480, left: 75 },
    11: { bottom: 480, right: 75 }
  },
  '4-1-4-1': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 250 },
    7: { bottom: 400, left: 0 },
    8: { bottom: 325, left: 75 },
    9: { bottom: 325, right: 75 },
    10: { bottom: 400, right: 0 },
    11: { bottom: 480 }
  },
  '4-2-4': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 275, left: 75 },
    7: { bottom: 275, right: 75 },
    8: { bottom: 375, left: 0 },
    9: { bottom: 480, left: 75 },
    10: { bottom: 480, right: 75 },
    11: { bottom: 375, right: 0 }
  },
  '4-3-1-2': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 325, left: 30 },
    7: { bottom: 275 },
    8: { bottom: 325, right: 30 },
    9: { bottom: 400 },
    10: { bottom: 480, left: 75 },
    11: { bottom: 480, right: 75 }
  },
  '4-3-2-1': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 300, left: 30 },
    7: { bottom: 275 },
    8: { bottom: 300, right: 30 },
    9: { bottom: 400, left: 75 },
    10: { bottom: 400, right: 75 },
    11: { bottom: 480 },
  },
  '4-3-3': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 300, left: 30 },
    7: { bottom: 275 },
    8: { bottom: 300, right: 30 },
    9: { bottom: 400, left: 20 },
    10: { bottom: 480 },
    11: { bottom: 400, right: 20 }
  },
  '4-4-2': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 350, left: 0 },
    7: { bottom: 300, left: 75 },
    8: { bottom: 300, right: 75 },
    9: { bottom: 350, right: 0 },
    10: { bottom: 480, left: 75 },
    11: { bottom: 480, right: 75 }
  },
  '4-5-1': {
    1: { bottom: 40 },
    2: { bottom: 200, left: 0 },
    3: { bottom: 150, left: 75 },
    4: { bottom: 150, right: 75 },
    5: { bottom: 200, right: 0 },
    6: { bottom: 250 },
    7: { bottom: 375, left: 0 },
    8: { bottom: 300, left: 50 },
    9: { bottom: 300, right: 50 },
    10: { bottom: 375, right: 0 },
    11: { bottom: 480 }
  },
  '5-3-2': {
    1: { bottom: 40 },
    2: { bottom: 225, left: 0 },
    3: { bottom: 150, left: 50 },
    4: { bottom: 150 },
    5: { bottom: 150, right: 50 },
    6: { bottom: 225, right: 0 },
    7: { bottom: 325, left: 30 },
    8: { bottom: 325 },
    9: { bottom: 325, right: 30 },
    10: { bottom: 480, left: 75 },
    11: { bottom: 480, right: 75 }
  }
}