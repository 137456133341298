import { useSelector } from 'react-redux'

const UserCurrency = () => {

  const userCurrency = useSelector(state => state.user.currency)

  return getCurrencySymbol(userCurrency)
}

export const getCurrencySymbol = (currencyCode) => {

  let currencySymbol

  switch (currencyCode) {
    case 'EUR':
      currencySymbol = '€'
      break
    case 'USD':
      currencySymbol = '$'
      break
    case 'GBP':
      currencySymbol = '£'
      break
    case 'CHF':
      currencySymbol = '₣'
      break
    case 'JPY':
    case 'CNY':
      currencySymbol = '¥'
      break
  }

  return 'M' + currencySymbol
}

export default UserCurrency