export const transitionWrapper = (promise, callback) => {
    return wrapPromise(promise, callback)
}

const wrapPromise = (promise, callback) => {
    let status = 'pending'
    let result
    let suspender = promise.then(
        r => {
            status = 'success'
            result = r
            callback(r)
        },
        e => {
            status = 'error'
            result = e
            console.error(e)
        }
    )
    return {
        read() {
            if (status === 'pending') {
                throw suspender
            } else if (status === 'error') {
                throw result
            } else if (status === 'success') {
                return result
            }
        }
    }
}
