import { api_versioned, getQueryParams } from './commonService'

const getLeagues = (token, dispatch, page, filter, sort) => {
  return api_versioned(token, dispatch).get('/leagues', { params: getQueryParams(page, filter, sort) })
}

const getLeague = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/leagues/${id}`)
}

const leaguesService = {
  getLeagues,
  getLeague
}

export default leaguesService