import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PAGE_ALL } from '../../../services/commonService'
import leaguesService from '../../../services/leaguesService'
import { setFiltersLeagues } from '../../../slices/filtersSlice'
import TranslatedContent from '../../translations/translatedContent'

const StdLeagueFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const leaguesSlice = useSelector(state => state.filters.leagues)
  const dispatch = useDispatch()

  const [leagues, setLeagues] = useState([])
  const [selectedLeagues, setSelectedLeagues] = useState({})

  useEffect(() => {
    if (!Array.isArray(leaguesSlice) || leaguesSlice.length === 0) {
      const sort = {
        field: 'potentialPrice',
        sort: 'desc'
      }
      leaguesService.getLeagues(userToken, dispatch, PAGE_ALL, null, sort)
        .then(res => {
          if (res.status === 200) {
            let resultData = []
            res.data.data.forEach((data) => {
              resultData.push({
                leagueId: data.leagueId,
                name: data.name,
                country: data.country
              })
            })
            dispatch(setFiltersLeagues(resultData))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (Array.isArray(leaguesSlice) && leaguesSlice.length > 0) {
      setLeagues(leaguesSlice)
    }
  }, [leaguesSlice])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedLeagues({})
    }
  }))

  const handleSelectLeague = (e, leagueId, leagueName) => {
    e.preventDefault()
    let tmpLeagues = {
      ...selectedLeagues
    }
    if (Object.keys(tmpLeagues).includes(leagueId)) {
      delete tmpLeagues[leagueId]
    } else {
      tmpLeagues[leagueId] = leagueName
    }
    setSelectedLeagues(tmpLeagues)
    props.onChange(prepareFilter(tmpLeagues))
  }

  const handleDeselectAll = (e) => {
    e.preventDefault()
    setSelectedLeagues([])
    props.onChange(prepareFilter([]))
  }

  const prepareFilter = (leagues) => {
    let filterContent = {}
    filterContent[props.filterName] = Object.keys(leagues)
    let filter = {
      filterName: 'leagues',
      filterValues: Object.values(leagues).join(', '),
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='leagues' defaultValue='Leagues' />
        </Typography>
      </Box>
      <Box sx={{
        height: 350,
        overflow: 'auto',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'sticky',
          bottom: 0,
          height: 50,
          marginTop: '-50px',
          pointerEvents: 'none',
          width: '100%',
          backgroundImage: 'linear-gradient(to bottom, transparent, #DFDFDF)',
        }
      }}>
        <Grid container sx={{ pb: 4 }}>
          {leagues.map((league, idx) => (
            <Grid item xs={12} sm={6} key={idx}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: Object.keys(selectedLeagues).includes(league.leagueId) ? '#04A8A3' : 'transparent',
                borderRadius: 25,
                cursor: 'pointer',
                my: .2,
                marginRight: 2,
                py: .3
              }}
                onClick={(e) => handleSelectLeague(e, league.leagueId, league.name)}>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    ml: 1,
                    color: Object.keys(selectedLeagues).includes(league.leagueId) ? '#FFFFFF' : '#000000'
                  }}>
                  {league.name}&nbsp;({league.country})
                </Typography>
                <Box sx={{ display: Object.keys(selectedLeagues).includes(league.leagueId) ? 'flex' : 'none', mr: 1 }}>
                  <CloseIcon sx={{ color: '#FFFFFF', width: 15, height: 15 }} />
                </Box>
              </Box>
            </Grid>
          )
          )}
        </Grid>
      </Box>
      <Box sx={{ display: Object.keys(selectedLeagues).length > 0 ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            width: 150,
            height: 30,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleDeselectAll}>
          <TranslatedContent code='deselectAll' defaultValue='Deselect All' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdLeagueFilter