import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  total: 0,
}

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setPlayersTotal(state, action) {
      state.total = action.payload
    }
  },
})

export const { setPlayersTotal } = playersSlice.actions