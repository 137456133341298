import { Divider, Tooltip, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TranslatedContent from '../../components/translations/translatedContent'
import StdMiniWatchlistTable from '../../components/watchlists/stdMiniWatchlistTable'
import watchlistsService from '../../services/watchlistsService'

const DashboardRecommendedWatchlist = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [watchlist, setWatchlist] = useState({})

  useEffect(() => {
    watchlistsService.getGenericDynamicWatchlists(userToken, dispatch, { name: props.name })
      .then(res => {
        if (res.status === 200) {
          if (Array.isArray(res.data.data) && res.data.data.length > 0) {
            setWatchlist(res.data.data[0])
          }
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  return (
    <Fragment>
      <Tooltip title={<TranslatedContent code={props.title} description />}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
          <TranslatedContent code={props.title} />
        </Typography>
      </Tooltip>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <StdMiniWatchlistTable watchlist={watchlist} sx={{ my: 3 }} />
    </Fragment>
  )
}

export default DashboardRecommendedWatchlist