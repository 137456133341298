import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import StdClubsTable from '../../components/clubs/stdClubsTable'
import TopVirtualTeam from '../../components/data/topVirtualTeam'
import LeagueComparables from '../../components/leagues/leagueComparables'
import LeagueProfile from '../../components/leagues/leagueProfile'
import TopTenPlayers from '../../components/leagues/topTenPlayers'
import EntityDynamicWatchlists from '../../components/watchlists/entityDynamicWatchlists'
import leaguesService from '../../services/leaguesService'
import LeagueRevenues from '../../components/data/leagueRevenues'

const LeaguePage = () => {

  const { id } = useParams()
  const dispatch = useDispatch()

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)

  const [currentLeague, setCurrentLeague] = useState()
  const [clubs, setClubs] = useState([])
  const [filteredClubs, setFilteredClubs] = useState(clubs)
  const [clubsNameFilter, setClubsNameFilter] = useState()
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    leaguesService.getLeague(userToken, dispatch, id)
      .then(res => {
        if (res.status === 200 && res.data.success) {
          let league = res.data.data
          setCurrentLeague(league)
          setClubs(league.Clubs.sort((a, b) => b.potentialPrice - a.potentialPrice))
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, userCurrency, id])

  useEffect(() => {
    if (clubsNameFilter) {
      let fc = clubs.filter(c => c.name.toLowerCase().includes(clubsNameFilter.toLowerCase()))
      setFilteredClubs(fc)
    } else {
      setFilteredClubs(clubs)
    }
  }, [clubs, clubsNameFilter])

  useEffect(() => {
    let sortedFc = [...filteredClubs]
    if (sorting && sorting.length > 0) {
      let sortingField = sorting[0].id
      sortedFc.sort((a, b) => {
        let order = 0
        sorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
    } else {
      sortedFc.sort((a, b) => b.potentialPrice - a.potentialPrice)
    }
    setFilteredClubs(sortedFc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  const handleClubsFilterByName = (searchText) => {
    setClubsNameFilter(searchText)
  }

  return (
    <Stack className='stdScrollable' sx={{ width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
      <LeagueProfile league={currentLeague} />
      <LeagueRevenues league={currentLeague} />
      <TopVirtualTeam entityType='league' entityId={currentLeague?.leagueId} sx={{ my: 5 }} />
      <StdClubsTable
        clubs={filteredClubs}
        rowCount={filteredClubs.length}
        filterByName={handleClubsFilterByName}
        enablePagination={false}
        sorting={sorting}
        onSortingChange={setSorting}
        page={0}
        pageSize={filteredClubs.length}
        enableBottomToolbar={false}
        disableFilters
        sx={{ my: 5 }} />
      <LeagueComparables league={currentLeague} sx={{ my: 5 }} />
      <TopTenPlayers leagueId={currentLeague?.leagueId} sx={{ my: 5 }} />
      <EntityDynamicWatchlists entityType='league' entityId={currentLeague?.leagueId} sx={{ my: 5 }} />
    </Stack>
  )
}

export default LeaguePage