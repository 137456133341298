import { Divider, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TranslatedContent from '../../components/translations/translatedContent'
import StdMiniWatchlistTable from '../../components/watchlists/stdMiniWatchlistTable'

const DashboardPersonalWatchlist = (props) => {

  const personalWatchlists = useSelector(state => state.watchlists.personal)

  const [watchlist, setWatchlist] = useState({})

  useEffect(() => {
    if (personalWatchlists.length > 0) {
      let defaultWatchlist = personalWatchlists.find(pw => pw.default)
      if (defaultWatchlist) {
        setWatchlist(defaultWatchlist)
      } else {
        setWatchlist(personalWatchlists[0])
      }
    }
  }, [personalWatchlists])

  return (
    <Stack sx={{ ...props.sx }}>
      <Tooltip title={<TranslatedContent code='mainWatchlist' defaultValue='Main Watchlist' description />}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
          <TranslatedContent code='mainWatchlist' defaultValue='Main Watchlist' />
        </Typography>
      </Tooltip>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <StdMiniWatchlistTable watchlist={watchlist} isPersonalWatchlist sx={{ my: 3 }} />
    </Stack>
  )
}

export default DashboardPersonalWatchlist