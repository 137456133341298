import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  countries: [],
  leagues: [],
  roles: [],
  age: null,
  expectedPrice: null,
  expectedSalary: null,
  nationalities: []
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersCountries(state, action) {
      state.countries = action.payload
    },
    setFiltersLeagues(state, action) {
      state.leagues = action.payload
    },
    setFiltersRoles(state, action) {
      state.roles = action.payload
    },
    setFiltersAge(state, action) {
      state.age = action.payload
    },
    setFiltersExpectedPrice(state, action) {
      state.expectedPrice = action.payload
    },
    setFiltersExpectedSalary(state, action) {
      state.expectedSalary = action.payload
    },
    setFiltersNationalities(state, action) {
      state.nationalities = action.payload
    }
  },
})

export const {
  setFiltersCountries,
  setFiltersLeagues,
  setFiltersRoles,
  setFiltersAge,
  setFiltersExpectedPrice,
  setFiltersExpectedSalary,
  setFiltersNationalities
} = filtersSlice.actions