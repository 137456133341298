import styled from '@emotion/styled'
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'
import TranslatedContent from '../../translations/translatedContent'

const StdIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: '#FFFFFF',
  'input:hover ~ &': {
    backgroundColor: '#A1CBC2',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#707070',
  },
}))

const StdCheckedIcon = styled(StdIcon)({
  backgroundColor: '#FFFFFF',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#04A8A3, #04A8A3 38%, transparent 42%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#A1CBC2',
  },
})

const StdRadio = (props) => {
  return (
    <Radio
      checkedIcon={<StdCheckedIcon />}
      icon={<StdIcon />}
      {...props} />
  )
}

const StdResidualContractFilter = forwardRef((props, ref) => {

  const [selectedResidualContract, setSelectedResidualContract] = useState(null)

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedResidualContract(null)
    }
  }))

  const handleChange = (event) => {
    setSelectedResidualContract(event.target.value)
    props.onChange(prepareFilter(event.target.value))
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setSelectedResidualContract(null)
    props.onChange(prepareFilter(null))
  }

  const prepareFilter = (residualContract) => {
    let filterContent = {}
    if (residualContract === '5+') {
      filterContent[props.filterName + 'From'] = 5
    } else if (residualContract) {
      filterContent[props.filterName] = residualContract
    } else {
      filterContent = null
    }
    let filter = {
      filterName: 'residualContract',
      filterValues: residualContract,
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='residualContract' defaultValue='Residual Contract' />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <FormControl>
          <RadioGroup row
            value={selectedResidualContract}
            onChange={handleChange}>
            <FormControlLabel value='1' control={<StdRadio />} label='1' labelPlacement='top' sx={{ mx: 1 }} />
            <FormControlLabel value='2' control={<StdRadio />} label='2' labelPlacement='top' sx={{ mx: 1 }} />
            <FormControlLabel value='3' control={<StdRadio />} label='3' labelPlacement='top' sx={{ mx: 1 }} />
            <FormControlLabel value='4' control={<StdRadio />} label='4' labelPlacement='top' sx={{ mx: 1 }} />
            <FormControlLabel value='5' control={<StdRadio />} label='5' labelPlacement='top' sx={{ mx: 1 }} />
            <FormControlLabel value='5+' control={<StdRadio />} label='5+' labelPlacement='top' sx={{ mx: 1 }} />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: selectedResidualContract ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleResetFilter}>
          <TranslatedContent code='resetFilter' defaultValue='Reset Filter' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdResidualContractFilter