import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { IconButton, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { openInNewTab } from '../../services/commonService'

const LoginFooter = () => {

  return (
    <Grid container
      sx={{
        height: '100%',
        alignItems: { xs: 'flex-start', lg: 'center' },
        justifyContent: { xs: 'center', lg: 'start' },
        px: { xs: 0, sm: 10 },
        mb: { xs: 0, sm: 4 }
      }}>
      <Grid item xs={0} lg={3}
        sx={{
          display: { xs: 'none', xl: 'block' },
          pr: { xs: 0, xl: 10 }
        }}>
        <img src='/img/common/stdLogo.svg' alt='StdFootball' width='260px' height='110px' />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}
        sx={{
          mt: { xs: 4, sm: 2, lg: 0 }
        }}>
        <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          <Typography sx={{ color: '#A1CBC2', fontWeight: 600, fontSize: '2.2rem' }}>Registered Office</Typography>
          <Typography sx={{ color: { xs: '#EEEEEE', lg: '#222222' }, fontSize: '2.2rem' }}>Via Vitorchiano, 123,<br />00198 Rome,<br />Italy</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}
        sx={{
          mt: { xs: 2, lg: 0 }
        }}>
        <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          <Typography sx={{ color: '#A1CBC2', marginBottom: { xs: 0, lg: 2 }, fontWeight: 600, fontSize: '2.2rem' }}>Email</Typography>
          <Link href='#'
            underline='none'
            onClick={(e) => {
              window.open('mailto:info@stdfootball.com', 'mail')
              e.preventDefault()
            }}>
            <Typography sx={{ color: { xs: '#EEEEEE', lg: '#222222' }, fontSize: '2.2rem', mb: 2 }}>
              info@stdfootball.com
            </Typography>
          </Link>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              mt: { sm: 4, lg: 0 },
              mb: { xs: 4, sm: 0 }
            }}>
            <Typography sx={{ color: '#A1CBC2', fontWeight: 600, fontSize: '2.2rem', mr: 1 }}>Connect</Typography>
            <IconButton sx={{ color: { xs: '#EEEEEE', lg: '#222222' }, mx: .5 }} onClick={() => openInNewTab('https://www.linkedin.com/company/71228649')}><LinkedInIcon sx={{ height: { xs: 20, lg: 30 }, width: { xs: 20, lg: 30 } }} /></IconButton>
            <IconButton sx={{ color: { xs: '#EEEEEE', lg: '#222222' }, mx: .5 }} onClick={() => openInNewTab('https://twitter.com/StdFootball')}><TwitterIcon sx={{ height: { xs: 20, lg: 30 }, width: { xs: 20, lg: 30 } }} /></IconButton>
            <IconButton sx={{ color: { xs: '#EEEEEE', lg: '#222222' }, mx: .5 }} onClick={() => openInNewTab('https://www.instagram.com/stdfootball/')}><InstagramIcon sx={{ height: { xs: 20, lg: 30 }, width: { xs: 20, lg: 30 } }} /></IconButton>
          </Box>
        </Box>
      </Grid>
    </Grid >
  )
}

export default LoginFooter