import { api_unversioned } from './commonService'

const getTranslations = (token, dispatch, lang) => {
  return api_unversioned(token, dispatch).get(`/translations/lang/${lang}`)
}

const translationsService = {
  getTranslations
}

export default translationsService