import { Box, Button, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setFiltersExpectedSalary } from '../../../slices/filtersSlice'
import { getCurrencySymbol } from '../../currency/userCurrency'
import StdRangeSlider from '../../form/stdRangeSlider'
import TranslatedContent from '../../translations/translatedContent'

const StdExpectedSalaryFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const expectedSalarySlice = useSelector(state => state.filters.expectedSalary)
  const dispatch = useDispatch()

  const [minAbsoluteExpectedSalary, setMinAbsoluteExpectedSalary] = useState()
  const [maxAbsoluteExpectedSalary, setMaxAbsoluteExpectedSalary] = useState()
  const [marks, setMarks] = useState([])
  const [selectedExpectedSalary, setSelectedExpectedSalary] = useState([0, 50])

  useEffect(() => {
    if (!expectedSalarySlice) {
      filtersService.getPlayerExpectedSalaries(userToken, dispatch)
        .then(res => {
          if (res.status === 200 && res.data.success) {
            dispatch(setFiltersExpectedSalary(res.data.data.expected))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (expectedSalarySlice) {
      setMinAbsoluteExpectedSalary(expectedSalarySlice.min_currency)
      setMaxAbsoluteExpectedSalary(expectedSalarySlice.max_currency)
      setSelectedExpectedSalary([expectedSalarySlice.min_currency, expectedSalarySlice.max_currency])
      calculateMarks(expectedSalarySlice.min_currency, expectedSalarySlice.max_currency)
    }
  }, [expectedSalarySlice])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedExpectedSalary([minAbsoluteExpectedSalary, maxAbsoluteExpectedSalary])
    }
  }))

  const handleChangeExpectedSalary = (newValue) => {
    setSelectedExpectedSalary(newValue)
    props.onChange(prepareFilter(newValue))
  }

  const calculateMarks = (min, max) => {
    let perc10 = Math.floor((max - min) * 0.1)
    let perc30 = Math.floor((max - min) * 0.3)
    let perc50 = Math.floor((max - min) * 0.5)
    let perc70 = Math.floor((max - min) * 0.7)
    let perc90 = Math.floor((max - min) * 0.9)
    let calculatedMarks = [
      {
        value: perc10,
        label: perc10.toString()
      },
      {
        value: perc30,
        label: perc30.toString()
      },
      {
        value: perc50,
        label: perc50.toString()
      },
      {
        value: perc70,
        label: perc70.toString()
      },
      {
        value: perc90,
        label: perc90.toString()
      },
    ]
    setMarks(calculatedMarks)
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setSelectedExpectedSalary([minAbsoluteExpectedSalary, maxAbsoluteExpectedSalary])
    props.onChange(prepareFilter([minAbsoluteExpectedSalary, maxAbsoluteExpectedSalary]))
  }

  const prepareFilter = (expectedSalary) => {
    let filterContent = {}
    if (expectedSalary[0] > minAbsoluteExpectedSalary) {
      filterContent[props.filterName + 'From'] = expectedSalary[0]
    }
    if (expectedSalary[1] < maxAbsoluteExpectedSalary) {
      filterContent[props.filterName + 'To'] = expectedSalary[1]
    }
    let filterValues = ''
    if (filterContent[props.filterName + 'From'] || filterContent[props.filterName + 'To']) {
      filterValues = expectedSalary[0] + ' ' + getCurrencySymbol(userCurrency) + ' - ' + expectedSalary[1] + ' ' + getCurrencySymbol(userCurrency)
    }
    let filter = {
      filterName: 'expectedSalary',
      filterValues: filterValues,
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='expectedSalaryMln' defaultValue='Expected Salary (Mln)' />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <StdRangeSlider
          value={selectedExpectedSalary}
          minValue={minAbsoluteExpectedSalary}
          maxValue={maxAbsoluteExpectedSalary}
          marks={marks}
          onChange={handleChangeExpectedSalary}
          step={1}
        />
      </Box>
      <Box sx={{ display: selectedExpectedSalary[0] > minAbsoluteExpectedSalary || selectedExpectedSalary[1] < maxAbsoluteExpectedSalary ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleResetFilter}>
          <TranslatedContent code='resetFilter' defaultValue='Reset Filter' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdExpectedSalaryFilter