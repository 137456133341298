import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ClubComparables from '../../components/clubs/clubComparables'
import ClubMarket from '../../components/clubs/clubMarket'
import ClubProfile from '../../components/clubs/clubProfile'
import TopVirtualTeam from '../../components/data/topVirtualTeam'
import Revenues from '../../components/data/revenues'
import StdPlayersTable from '../../components/players/stdPlayersTable'
import EntityDynamicWatchlists from '../../components/watchlists/entityDynamicWatchlists'
import clubsService from '../../services/clubsService'

const ClubPage = () => {

  const { id } = useParams()
  const dispatch = useDispatch()

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)

  const [currentClub, setCurrentClub] = useState()

  const [players, setPlayers] = useState([])
  const [filteredPlayers, setFilteredPlayers] = useState(players)
  const [playersNameFilter, setPlayersNameFilter] = useState()
  const [playersSorting, setPlayersSorting] = useState([])

  const [loanedPlayers, setLoanedPlayers] = useState([])
  const [filteredLoanedPlayers, setFilteredLoanedPlayers] = useState(players)
  const [loanedPlayersNameFilter, setLoanedPlayersNameFilter] = useState()
  const [loanedPlayersSorting, setLoanedPlayersSorting] = useState([])


  useEffect(() => {
    clubsService.getClub(userToken, dispatch, id)
      .then(res => {
        if (res.status === 200 && res.data.success) {
          let club = res.data.data
          setCurrentClub(club)
          setPlayers(club.Players.sort((a, b) => b.potentialPrice - a.potentialPrice))
          setLoanedPlayers(club.LoanedPlayers.sort((a, b) => b.potentialPrice - a.potentialPrice))
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, userCurrency, id])

  useEffect(() => {
    if (playersNameFilter) {
      let fp = players.filter(p => p.name.toLowerCase().includes(playersNameFilter.toLowerCase()))
      setFilteredPlayers(fp)
    } else {
      setFilteredPlayers(players)
    }
  }, [players, playersNameFilter])

  useEffect(() => {
    if (loanedPlayersNameFilter) {
      let fp = loanedPlayers.filter(p => p.name.toLowerCase().includes(loanedPlayersNameFilter.toLowerCase()))
      setFilteredLoanedPlayers(fp)
    } else {
      setFilteredLoanedPlayers(loanedPlayers)
    }
  }, [loanedPlayers, loanedPlayersNameFilter])

  useEffect(() => {
    let sortedFp = [...filteredPlayers]
    if (playersSorting && playersSorting.length > 0) {
      let sortingField = playersSorting[0].id
      sortedFp.sort((a, b) => {
        let order = 0
        playersSorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
    } else {
      sortedFp.sort((a, b) => b.potentialPrice - a.potentialPrice)
    }
    setFilteredPlayers(sortedFp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersSorting])

  useEffect(() => {
    let sortedFp = [...filteredLoanedPlayers]
    if (loanedPlayersSorting && loanedPlayersSorting.length > 0) {
      let sortingField = loanedPlayersSorting[0].id
      sortedFp.sort((a, b) => {
        let order = 0
        loanedPlayersSorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
    } else {
      sortedFp.sort((a, b) => b.potentialPrice - a.potentialPrice)
    }
    setFilteredLoanedPlayers(sortedFp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanedPlayersSorting])

  const handlePlayersFilterByName = (searchText) => {
    setPlayersNameFilter(searchText)
  }

  const handleLoanedPlayersFilterByName = (searchText) => {
    setLoanedPlayersNameFilter(searchText)
  }
  
  return (
    <Stack className='stdScrollable' sx={{ width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
      <ClubProfile club={currentClub} sx={{ zIndex: 3 }} />
      <Revenues club={currentClub} sx={{ my: 5 }} />
      <TopVirtualTeam entityType='club' entityId={currentClub?.clubId} sx={{ my: 5 }} />
      <StdPlayersTable
        title='roster'
        players={filteredPlayers}
        rowCount={filteredPlayers.length}
        filterByName={handlePlayersFilterByName}
        enablePagination={false}
        sorting={playersSorting}
        onSortingChange={setPlayersSorting}
        page={0}
        pageSize={filteredPlayers.length}
        enableBottomToolbar={false}
        disableFilters
        sx={{ my: 5 }} />
      <StdPlayersTable
        title='loanedPlayers'
        players={filteredLoanedPlayers}
        rowCount={filteredLoanedPlayers.length}
        filterByName={handleLoanedPlayersFilterByName}
        enablePagination={false}
        sorting={loanedPlayersSorting}
        onSortingChange={setLoanedPlayersSorting}
        page={0}
        pageSize={filteredLoanedPlayers.length}
        enableBottomToolbar={false}
        disableFilters
        sx={{ my: 5 }} />
      <ClubMarket club={currentClub} sx={{ display: 'none', my: 5, pb: 5 }} />
      <ClubComparables club={currentClub} sx={{ my: 5 }} />
      <EntityDynamicWatchlists entityType='club' entityId={currentClub?.clubId} sx={{ my: 5 }} />
    </Stack>
  )
}

export default ClubPage