import { Box, IconButton, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import { ReactComponent as CalculatorIcon } from '../../icons/calculator_circle.svg'
import { ReactComponent as CancelCircleIcon } from '../../icons/cancel_circle.svg'
import filtersService from '../../services/filtersService'
import statisticsService from '../../services/statisticsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeagueGroups, setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import UserCurrency from '../currency/userCurrency'
import { currencyUomRenderer, dateFormatter, decimalNumberRenderer, decimalValueWithPercRenderer, integerNumberRenderer, monthsToYearsMonthsUomRenderer, percNumberRenderer, tierRenderer } from '../data/formatters/dataFormatters'
import StdVerticalGrid from '../data/stdVerticalGrid'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import WatchlistPopper from '../watchlists/watchlistPopper'
import TransferSimulator from './transferSimulator'

const PlayerProfile = (props) => {
  
  const playersTotal = useSelector(state => state.players.total)
  const leagueGroups = useSelector(state => state.leagues.leagueGroups)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const [numOfPlayers, setNumOfPlayers] = useState(playersTotal)
  const [numOfLeagueGroups, setNumOfLeagueGroups] = useState(leagueGroups.length)
  const [numOfClubClusters, setNumOfClubClusters] = useState(clubClusters.length)

  const [salaryPercDeviation, setSalaryPercDeviation] = useState(0)
  const [percClubAvgSalaryDeviation, setPercClubAvgSalaryDeviation] = useState(0)
  const [percLeagueAvgSalaryDeviation, setPercLeagueAvgSalaryDeviation] = useState(0)
  const [percClubClusterAvgSalaryDeviation, setPercClubClusterAvgSalaryDeviation] = useState(0)

  const withdrawalClauseRenderer = ({ value }) => {
    if (value === null || value === undefined)
      return null
    if (value === 0)
      return 'NO'
    let formattedValue = Math.round(value).toLocaleString('en-US')
    return (
      <Fragment>
        <span>{formattedValue}</span>&nbsp;<span style={{ opacity: .65 }}>{<UserCurrency />}</span>
      </Fragment>
    )
  }

  const emptyGeneralData = [
    { field: 'fullName', name: 'playerName', value: '-' },
    { field: 'contractType', name: 'contractType', value: '-' },
    { field: 'birthDate', name: 'playerBirthDate', value: '-', valueFormatter: dateFormatter },
    { field: 'endContract', name: 'playerEndContract', value: '-', valueFormatter: dateFormatter },
    { field: 'remainingMonthsOfContract', name: 'playerResidualContract', value: '-', valueRenderer: monthsToYearsMonthsUomRenderer }
  ]

  const emptyPerformanceData = [
    { field: 'currentLeagueCluster', name: 'playerLeagueTier', value: '-', valueRenderer: tierRenderer(numOfLeagueGroups) },
    { field: 'currentClubCluster', name: 'playerClubTier', value: '-', valueRenderer: tierRenderer(numOfClubClusters) },
    //{ field: 'playedMatches', value: 'COMING SOON' },
    { field: 'minutes', name: 'playerPlayedMinutes', value: '-', valueRenderer: integerNumberRenderer },
    { field: 'performance', name: 'playerPerformance', value: '-', valueRenderer: decimalNumberRenderer },
    { field: 'experience', name: 'playerExperience', value: '-', valueRenderer: decimalNumberRenderer },
    { field: 'effectiveness', name: 'playerEffectiveness', value: '-', valueRenderer: decimalNumberRenderer },
    { field: 'rankByEffectiveness', name: 'playerEffectivenessRank', value: '-', valueRenderer: tierRenderer(numOfPlayers) },
    { field: 'latestUniqueness', name: 'playerUniqueness', value: '-', valueRenderer: tierRenderer(100) },
    { field: 'latestMicroVolatility', name: 'playerVolatility', value: '-', valueRenderer: percNumberRenderer }
  ]

  const emptyValueData = [
    { field: 'masterClubName', name: 'playerOwner', value: '-' },
    { field: 'endContract', name: 'playerEndContract', value: '-', valueFormatter: dateFormatter },
    { field: 'withdrawalClause', name: 'playerWithdrawalClause', value: '-', valueRenderer: withdrawalClauseRenderer },
    { field: 'transferPrice', name: 'playerTransferPrice', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'residualBookValue', name: 'playerBookValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'potentialPrice', name: 'playerFairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankByValue', name: 'playerRankByValue', value: '-', valueRenderer: tierRenderer(numOfPlayers) },
    { field: 'residualValue', name: 'playerResidualValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'perceivedExpectedPrice', name: 'playerExpectedPrice', value: '-', valueRenderer: currencyUomRenderer }
  ]

  const emptySalaryData = [
    { field: 'salaryGross', name: 'playerGrossSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryNet', name: 'playerNetSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryOptimal', name: 'playerFairSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryAbsoluteDeviation', name: 'playerFairSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(salaryPercDeviation) },
    { field: 'absClubAvgSalaryDeviation', name: 'playerClubAvgSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(percClubAvgSalaryDeviation) },
    { field: 'absLeagueAvgSalaryDeviation', name: 'playerLeagueAvgSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(percLeagueAvgSalaryDeviation) },
    { field: 'absClubCusterAvgSalaryDeviation', name: 'playerClubTierAvgSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(percClubClusterAvgSalaryDeviation) },
    { field: 'salaryPotential', name: 'playerPotentialSalary', value: '-', valueRenderer: currencyUomRenderer }
  ]

  const userToken = useSelector(state => state.user.token)
  const personalWatchlists = useSelector(state => state.watchlists.personal)

  const dispatch = useDispatch()
  const popperRef = useRef()
  const navigate = useNavigate()

  const isBreakpointXxl = useMediaQuery(theme => theme.breakpoints.down('xxl'))

  const [displayState, setDisplayState] = useState('general')
  const [generalData, setGeneralData] = useState(emptyGeneralData)
  const [performanceData, setPerformanceData] = useState(emptyPerformanceData)
  const [valueData, setValueData] = useState(emptyValueData)
  const [salaryData, setSalaryData] = useState(emptySalaryData)

  const [isInAtLeastOneWatchlist, setIsInAtLeastOneWatchlist] = useState(false)

  const [transferSimulatorOpen, setTransferSimulatorOpen] = useState(false)

  useEffect(() => {
    setNumOfPlayers(playersTotal)
  }, [playersTotal])

  useEffect(() => {
    setNumOfLeagueGroups(leagueGroups.length)
  }, [leagueGroups])

  useEffect(() => {
    setNumOfClubClusters(clubClusters.length)
  }, [clubClusters])

  useEffect(() => {
    if (props.player) {
      setSalaryPercDeviation(props.player.salaryPercDeviation)
      setPercClubAvgSalaryDeviation(props.player.percClubAvgSalaryDeviation)
      setPercLeagueAvgSalaryDeviation(props.player.percLeagueAvgSalaryDeviation)
      setPercClubClusterAvgSalaryDeviation(props.player.percClubClusterAvgSalaryDeviation)
    }
  }, [props.player])

  useEffect(() => {
    if (playersTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(leagueGroups) || leagueGroups.length === 0) {
      filtersService.getLeagueGroups(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeagueGroups(res.data.data.groups))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (props.player) {
      if (Object.keys(props.player).length > 0) {
        let tmpGeneralData = []
        emptyGeneralData.forEach((row) => {
          tmpGeneralData.push({ field: row.field, name: row.name, value: props.player[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        
        setGeneralData(tmpGeneralData)
        let tmpPerformanceData = []
        emptyPerformanceData.forEach((row) => {
          tmpPerformanceData.push({ field: row.field, name: row.name, value: row.value === 'COMING SOON' ? row.value : props.player[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setPerformanceData(tmpPerformanceData)
        let tmpValueData = []
        emptyValueData.forEach((row) => {
          tmpValueData.push({ field: row.field, name: row.name, value: row.field === "endContract" && props.player["contractType"] === 'Prestito' ? new Date(props.player["masterClubEndContract"]) : props.player[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setValueData(tmpValueData)
        let tmpSalaryData = []
        emptySalaryData.forEach((row) => {
          tmpSalaryData.push({ field: row.field, name: row.name, value: props.player[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSalaryData(tmpSalaryData)
      } else {
        setGeneralData(emptyGeneralData)
        setPerformanceData(emptyPerformanceData)
        setValueData(emptyValueData)
        setSalaryData(emptySalaryData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.player, numOfPlayers, numOfLeagueGroups, numOfClubClusters, salaryPercDeviation, percClubAvgSalaryDeviation, percLeagueAvgSalaryDeviation, percClubClusterAvgSalaryDeviation])

  useEffect(() => {
    let foundInOneWatchlist = false

    personalWatchlists.every((personalWatchlist) => {
      foundInOneWatchlist |= Object.keys(personalWatchlist.players).includes(props.player?.playerId)
      return !foundInOneWatchlist
    })

    setIsInAtLeastOneWatchlist(foundInOneWatchlist)
  }, [props.player?.playerId, personalWatchlists])

  const handleAddToWatchlistButtonClicked = (event, playerId) => {
    event.stopPropagation()
    popperRef.current.openWatchlistPopper(playerId, event.currentTarget)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row', xxl: 'column' }, width: '100%', height: 'fit-content' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', xxl: 'row' },
            alignItems: { xs: 'flex-start', xxl: 'center' },
            justifyContent: { xs: 'flex-start', xxl: 'space-between' },
            backgroundColor: '#223441',
            borderRadius: {
              xs: '20px 20px 0 0',
              md: transferSimulatorOpen ? '20px 0 0 0' : '20px 0 0 20px',
              xxl: '20px 20px 0 0'
            },
            boxShadow: { xs: '0 15px 3px -3px rgba(0, 0, 0, .3)', xxl: 'none' },
            //height: { xs: '100%', md: transferSimulatorOpen ? '100%' : 250, xxl: '100%' },
            width: { xs: 'initial', xxl: '100%' },
            px: { xs: 2, md: 3, lg: 5 },
            py: 3
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: { xs: 0, md: 2 } }}>
            <Typography
              sx={{
                color: '#F4D35E',
                fontSize: { xs: '3rem', sm: '3.5rem' },
                fontWeight: 600,
                whiteSpace: 'nowrap'
              }}>
              {props.player?.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: { xs: 'flex-start', xxl: 'center' }, width: '100%', height: { xs: 135, md: '100%' }, transition: 'height .4s ease-in-out' }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', xxl: 'row' }, whiteSpace: 'nowrap', transition: 'opacity .2s ease-in-out' }}>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='club' defaultValue='Club' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF', cursor: 'pointer' }} onClick={() => navigate('/clubs/' + props.player?.currentClubId)}>
                  {props.player?.currentClubName}
                </Box>
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='role' defaultValue='Role' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF' }}>
                  {props.player?.currentRole}
                </Box>
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='age' defaultValue='Age' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF' }}>
                  {props.player && <Fragment>{props.player?.age} Y {props.player?.months} M</Fragment>}
                </Box>
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='passport' defaultValue='Passport' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF' }}>
                  {props.player?.nationality}
                </Box>
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='nationalTeamPlayer' defaultValue='National Team' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF' }}>
                  {props.player?.nationalTeamPlayer ? <TranslatedContent code='yes' defaultValue='Yes' /> : <TranslatedContent code='no' defaultValue='No' />}
                </Box>
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 600, mr: 5 }}>
                <Box component='span' sx={{ color: '#A1CBC2', textTransform: 'uppercase' }}>
                  <TranslatedContent code='clubCluster' defaultValue='Club Tier' />:
                </Box>
                &nbsp;
                <Box component='span' sx={{ color: '#FFFFFF' }}>
                  {props.player?.currentClubCluster}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', xxl: 'row' } }}>
              {transferSimulatorOpen ?
                <Tooltip title={<TranslatedContent code='closeTransferSimulator' defaultValue='Close Transfer Simulator' />} placement='top'>
                  <IconButton sx={{ mx: 1 }} onClick={() => setTransferSimulatorOpen(false)}>
                    <CancelCircleIcon fill='#15222B' width='50px' height='50px' />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title={<TranslatedContent code='transferSimulator' defaultValue='Transfer Simulator' />} placement='top'>
                  <IconButton sx={{ mx: .5 }} onClick={() => setTransferSimulatorOpen(true)}>
                    <CalculatorIcon fill='#15222B' width='50px' height='50px' />
                  </IconButton>
                </Tooltip>}
              <Tooltip title={<TranslatedContent code='addToWatchlist' defaultValue='Add to watchlist' />} placement='top'>
                <IconButton sx={{ mx: .5 }} onClick={(e) => handleAddToWatchlistButtonClicked(e, props.player?.playerId)}>
                  <AddCircleIcon fill={isInAtLeastOneWatchlist ? '#04A8A3' : '#707070'} width='50px' height='50px' />
                </IconButton>
              </Tooltip>
              <WatchlistPopper ref={popperRef} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#31495A',
            borderRadius: {
              xs: transferSimulatorOpen ? 0 : '0 0 20px 20px',
              md: transferSimulatorOpen ? '0 20px 0 0' : '0 20px 20px 0',
              xxl: transferSimulatorOpen ? 0 : '0 0 20px 20px'
            },
            pt: { xs: 2, xxl: 4 },
            px: { xs: 2, xxl: 4 },
            pb: transferSimulatorOpen ? 0 : { xs: 2, xxl: 4 },
            width: { xs: 'inherit', xxl: '100%' },
            //height: transferSimulatorOpen ? '100%' : 250,
            transition: 'height .2s ease-in-out',
            boxShadow: transferSimulatorOpen ? 'none' : '0 15px 3px -3px rgba(0, 0, 0, .3)'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 'auto', alignItems: 'center', height: '100%' }}>
            <Box sx={{ display: { xs: 'flex', xxl: 'none' }, justifyContent: 'flex-end', alignItems: 'center', height: 55, width: '100%', px: 2, my: 1 }}>
              <StdSelect
                value={displayState}
                onChange={(e) => setDisplayState(e.target.value)}
                sx={{
                  display: { xs: 'inherit', xxl: 'none' },
                  width: { xs: 200, sm: 250 }
                }}>
                <MenuItem id='general' key='general' value='general'>
                  <TranslatedContent code='general' defaultValue='General' />
                </MenuItem>
                <MenuItem id='performance' key='performance' value='performance'>
                  <TranslatedContent code='performance' defaultValue='Performance' />
                </MenuItem>
                <MenuItem id='value' key='value' value='value'>
                  <TranslatedContent code='value' defaultValue='Value' />
                </MenuItem>
                <MenuItem id='salary' key='salary' value='salary'>
                  <TranslatedContent code='salary' defaultValue='Salary' />
                </MenuItem>
              </StdSelect>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', height: { xs: transferSimulatorOpen ? '100%' : '68%', xxl: '100%' } }}>
              <Box sx={{ display: { xs: displayState === 'general' ? 'block' : 'none', xxl: 'block' }, flex: 1, mx: { xs: 0, md: 2 }, height: '100%' }}>
                <StdVerticalGrid title={isBreakpointXxl ? null : <TranslatedContent code='general' defaultValue='General' />} data={generalData} />
              </Box>
              <Box sx={{ display: { xs: displayState === 'performance' ? 'block' : 'none', xxl: 'block' }, flex: 1, mx: { xs: 0, md: 2 }, height: '100%' }}>
                <StdVerticalGrid title={isBreakpointXxl ? null : <TranslatedContent code='performance' defaultValue='Performance' />} data={performanceData} />
              </Box>
              <Box sx={{ display: { xs: displayState === 'value' ? 'block' : 'none', xxl: 'block' }, flex: 1, mx: { xs: 0, md: 2 }, height: '100%' }}>
                <StdVerticalGrid title={isBreakpointXxl ? null : <TranslatedContent code='value' defaultValue='Value' />} data={valueData} />
              </Box>
              <Box sx={{ display: { xs: displayState === 'salary' ? 'block' : 'none', xxl: 'block' }, flex: 1, mx: { xs: 0, md: 2 }, height: '100%' }}>
                <StdVerticalGrid title={isBreakpointXxl ? null : <TranslatedContent code='salary' defaultValue='Salary' />} data={salaryData} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: transferSimulatorOpen ? 'block' : 'none', backgroundColor: '#31495A', borderRadius: '0 0 20px 20px', boxShadow: '0 15px 3px rgba(0, 0, 0, .3)', p: { xs: 2, md: 4 }, pt: 2 }}>
        <TransferSimulator player={props.player} similarPlayersSalaryNet={props.similarPlayersSalaryNet} />
      </Box>
    </Box >
  )
}

export default PlayerProfile