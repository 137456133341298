import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import * as am5xy from '@amcharts/amcharts5/xy'
import { Box, useMediaQuery } from '@mui/material'
import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const StdLeagueBarChart = (props) => {

  am5.addLicense('AM5C387037067')

  const navigate = useNavigate()
  const isBreakpointXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  useLayoutEffect(() => {

    const data = [...props.data]

    if (!isBreakpointXl) {
      data.reverse()
    }

    let root = am5.Root.new(props.chartName)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ])

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none'
    }))

    chart.zoomOutButton.set('forceHidden', true)

    let xAxis, yAxis

    if (isBreakpointXl) {
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      })
      xRenderer.grid.template.setAll({
        visible: false
      })
      xRenderer.labels.template.setAll({
        paddingTop: 25,
        text: ''
      })
      xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: 'club',
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: xRenderer
      }))
      xAxis.data.setAll(data)

      let yRenderer = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0
      })
      yRenderer.grid.template.setAll({
        visible: false
      })
      yRenderer.labels.template.setAll({
        forceHidden: true
      })
      yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yRenderer
      }))
      yAxis.data.setAll(data)
    } else {
      let xRenderer = am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0
      })
      xRenderer.grid.template.setAll({
        visible: false
      })
      xRenderer.labels.template.setAll({
        forceHidden: true
      })
      xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: xRenderer
      }))
      xAxis.data.setAll(data)

      let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 30
      })
      yRenderer.grid.template.setAll({
        visible: false
      })
      yRenderer.labels.template.setAll({
        paddingLeft: 25,
        text: ''
      })
      yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: 'club',
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: yRenderer
      }))
      yAxis.data.setAll(data)
    }

    let clubSerie = chart.series.unshift(am5xy.ColumnSeries.new(root, {
      name: 'Club Fair Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: 'potentialPrice',
      valueYField: 'potentialPrice',
      categoryXField: 'club',
      categoryYField: 'club',
      clustered: false,
      maskBullets: false,
      tooltip: am5.Tooltip.new(root, {
        labelText: '[fontSize: 14px; fontFamily: Barlow]{categoryX}: {valueY} ' + props.uom
      })
    }))
    clubSerie.columns.template.setAll({
      width: am5.percent(30),
      height: am5.percent(30),
      strokeOpacity: 0
    })
    clubSerie.columns.template.adapters.add('fill', () => am5.color('#04A8A3'))
    clubSerie.data.setAll(data)

    let avgClusterSerie = chart.series.unshift(am5xy.ColumnSeries.new(root, {
      name: 'Tier Average Fair Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: 'tierAvgPotentialPrice',
      valueYField: 'tierAvgPotentialPrice',
      categoryXField: 'club',
      categoryYField: 'club',
      clustered: false,
      maskBullets: false,
      tooltip: am5.Tooltip.new(root, {
        labelText: '[fontSize: 14px; fontFamily: Barlow]{name}: {valueY} ' + props.uom
      })
    }))
    avgClusterSerie.columns.template.setAll({
      width: am5.percent(60),
      height: am5.percent(60),
      strokeOpacity: 0
    })
    avgClusterSerie.columns.template.adapters.add('fill', () => am5.color('#F4D35E'))
    avgClusterSerie.data.setAll(data)

    let avgLeagueSerie = chart.series.push(am5xy.StepLineSeries.new(root, {
      name: 'League Average Fair Value',
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: 'leagueAvgPotentialPrice',
      valueYField: 'leagueAvgPotentialPrice',
      categoryXField: 'club',
      categoryYField: 'club',
      maskBullets: false,
      fill: am5.color('#FFFFFF'),
      stroke: am5.color('#FFFFFF'),
      tooltip: am5.Tooltip.new(root, {
        labelText: '[fontSize: 14px; fontFamily: Barlow]{name}: {valueY} ' + props.uom
      })
    }))
    avgLeagueSerie.strokes.template.setAll({
      strokeWidth: 2,
      strokeDasharray: [10, 5]
    })
    avgLeagueSerie.data.setAll(data)

    let circleTemplate = am5.Template.new({})

    clubSerie.bullets.push((root, _series, dataItem) => {
      let bulletContainer = am5.Container.new(root, {
        paddingTop: isBreakpointXl ? 25 : 0,
        paddingLeft: isBreakpointXl ? 0 : -25,
        cursorOverStyle: 'pointer',
        fill: am5.color('#FFFFFF'),
        tooltipText: '[fontSize: 14px; fontFamily: Barlow]' + dataItem.dataContext.club,
        background: am5.Circle.new(root, {
          radius: 18,
          fill: am5.color(0x000000),
          fillOpacity: 0
        })
      })
      bulletContainer.events.on('click', () => {
        navigate('/clubs/' + dataItem.dataContext.id)
      })
      bulletContainer.children.push(
        am5.Circle.new(
          root,
          {
            radius: 18,
            fill: am5.color('#FFFFFF')
          },
          circleTemplate
        )
      )
      let maskCircle = bulletContainer.children.push(
        am5.Circle.new(root, { radius: 15 })
      )
      let imageContainer = bulletContainer.children.push(
        am5.Container.new(root, {
          mask: maskCircle
        })
      )
      imageContainer.children.push(
        am5.Picture.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          height: 30,
          cursorOverStyle: 'pointer',
          src: dataItem.dataContext.icon
        })
      )
      return am5.Bullet.new(root, {
        locationY: isBreakpointXl ? 0 : undefined,
        locationX: isBreakpointXl ? undefined : 0,
        sprite: bulletContainer
      })
    })

    let cursor = am5xy.XYCursor.new(root, {
      behavior: 'none',
      xAxis: xAxis,
      yAxis: yAxis
    })

    cursor.lineX.set('visible', false)
    cursor.lineY.set('visible', false)

    chart.set('cursor', cursor)

    clubSerie.appear(1000)
    avgClusterSerie.appear(1000)
    avgLeagueSerie.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, isBreakpointXl])

  return (
    <Box id={props.chartName}
      sx={{
        width: '100%',
        height: { xs: 40 * props.numOfClubs, xl: 600 },
        ...props.sx
      }}></Box>
  )
}

export default StdLeagueBarChart