import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clubsService from '../../services/clubsService'
import { transitionWrapper } from '../../services/transitionWrapper'
import StdBarChart from '../charts/stdBarChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import TranslatedContent from '../translations/translatedContent'
import ClubComparablesBarChartButtons from './clubComprablesBarChartButtons'

const ClubComparablesTopClubs = (props) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)

  const dispatch = useDispatch()
  const [isPendingClusterChart, startClusterChartTransition] = useTransition()
  const [isPendingLeagueChart, startLeagueChartTransition] = useTransition()

  const [clusterClubs, setClusterClubs] = useState([])
  const [leagueClubs, setLeagueClubs] = useState([])

  const [clusterParam, setClusterParam] = useState('potentialPrice')
  const [leagueParam, setLeagueParam] = useState('potentialPrice')

  const [clusterUom, setClusterUom] = useState(getCurrencySymbol(userCurrency))
  const [leagueUom, setLeagueUom] = useState(getCurrencySymbol(userCurrency))

  const tenResultsPage = {
    page: 1,
    per_page: 10
  }

  const potentialPriceSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  useEffect(() => {
    if (props.club) {
      startClusterChartTransition(() => {
        const clusterFilter = {
          cluster: props.club.cluster
        }
        transitionWrapper(clubsService.getClubs(userToken, dispatch, tenResultsPage, clusterFilter, potentialPriceSort),
          res => {
            if (res.status === 200 && res.data.success) {
              let clubs = [...res.data.data]
              let includes = false
              clubs.every(c => {
                includes |= c.clubId === props.club.clubId
                return !includes
              })
              if (includes) {
                clubs = clubs.filter(c => c.clubId !== props.club.clubId)
              } else {
                clubs.pop()
              }
              clubs.reverse().push(props.club)
              setClusterClubs(clubs)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.club])

  useEffect(() => {
    if (props.club) {
      startLeagueChartTransition(() => {
        const leagueFilter = {
          leagueId: props.club.leagueId
        }
        transitionWrapper(clubsService.getClubs(userToken, dispatch, tenResultsPage, leagueFilter, potentialPriceSort),
          res => {
            if (res.status === 200 && res.data.success) {
              let clubs = [...res.data.data]
              let includes = false
              clubs.every(c => {
                includes |= c.clubId === props.club.clubId
                return !includes
              })
              if (includes) {
                clubs = clubs.filter(c => c.clubId !== props.club.clubId)
              } else {
                clubs.pop()
              }
              clubs.reverse().push(props.club)
              setLeagueClubs(clubs)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.club])

  const handleClusterParamChange = ({ param, uom }) => {
    setClusterParam(param)
    setClusterUom(uom)
  }

  const handleLeagueParamChange = ({ param, uom }) => {
    setLeagueParam(param)
    setLeagueUom(uom)
  }

  return (
    <Grid container spacing={2} sx={{ width: '100%', mt: 5 }}>
      <Grid item xs={12} lg={6}>
        <Box sx={{ width: '100%', height: 450 }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '2rem',
            fontWeight: 600,
            textTransform: 'uppercase'
          }}>
            <TranslatedContent code='tierTopClubs' defaultValue='Tier Top Clubs' />
          </Typography>
          <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
          {isPendingClusterChart ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, height: '105%', py: { xs: 2, md: 5 } }}>
              <StdBarChart chartName='tierTopClubs' type='club' name={clusterParam} data={clusterClubs} categoryField='shortName' valueField={clusterParam} uom={clusterUom} />
              <ClubComparablesBarChartButtons onChange={handleClusterParamChange} />
            </Box>}
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={{ width: '100%', height: 450 }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '2rem',
            fontWeight: 600,
            textTransform: 'uppercase'
          }}>
            <TranslatedContent code='leagueTopClubs' defaultValue='League Top Clubs' />
          </Typography>
          <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
          {isPendingLeagueChart ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, height: '105%', py: { xs: 2, md: 5 } }}>
              <StdBarChart chartName='leagueTopClubs' type='club' name={leagueParam} data={leagueClubs} categoryField='shortName' valueField={leagueParam} uom={leagueUom} />
              <ClubComparablesBarChartButtons onChange={handleLeagueParamChange} />
            </Box>}
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClubComparablesTopClubs