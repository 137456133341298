import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { checkImage } from '../../services/commonService'

const ClubLogoSmall = (props) => {

  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {

    if (props.clubId) {
      let computedImgSrc = '/img/clubs/logos/' + props.clubId + '.png'

      checkImage(computedImgSrc)
        .then(res => {
          if (res.status === 'ok') {
            setImgSrc(computedImgSrc)
          }
        })
        .catch(err => console.error(err))
    }
  }, [props.clubId])

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        height: 50,
        width: 50,
        display: props.show ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx
      }}>
      <img src={imgSrc} height='75%' />
    </Box>
  )
}

export default ClubLogoSmall