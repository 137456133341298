import { api_unversioned } from './commonService'

const getVirtualTeam = (token, dispatch, filter, orderBy, module, under23) => {
  let queryParams = new URLSearchParams()
  queryParams.append(filter.filterName, filter.filterValue)
  queryParams.append('orderBy', orderBy)
  queryParams.append('module', module)
  if (under23) queryParams.append('under23', 1)
  return api_unversioned(token, dispatch).get('/vteams', { params: queryParams })
}

const miscService = {
  getVirtualTeam
}

export default miscService