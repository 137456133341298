import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setFiltersCountries } from '../../../slices/filtersSlice'
import TranslatedContent from '../../translations/translatedContent'

const StdCountryFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const countries = useSelector(state => state.filters.countries)
  const dispatch = useDispatch()

  const [selectedCountries, setSelectedCountries] = useState([])

  useEffect(() => {
    if (!Array.isArray(countries) || countries.length === 0) {
      filtersService.getCountries(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setFiltersCountries(res.data.data.countries))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedCountries([])
    }
  }))

  const handleSelectCountry = (e, countryCode) => {
    e.preventDefault()
    let tmpCountries
    if (selectedCountries.includes(countryCode)) {
      tmpCountries = selectedCountries.filter(sc => sc !== countryCode)
    } else {
      tmpCountries = [...selectedCountries, countryCode]
    }
    setSelectedCountries(tmpCountries)
    props.onChange(prepareFilter(tmpCountries))
  }

  const handleDeselectAll = (e) => {
    e.preventDefault()
    setSelectedCountries([])
    props.onChange(prepareFilter([]))
  }

  const prepareFilter = (countries) => {
    let filterContent = {}
    filterContent[props.filterName] = countries
    let filter = {
      filterName: 'country',
      filterValues: countries.join(', '),
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='country' defaultValue='Country' />
        </Typography>
      </Box>
      <Box sx={{
        overflow: 'auto',
      }}>
        <Grid container>
          {countries.map((country, idx) => (
            <Grid item xs={6} key={idx}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: selectedCountries.includes(country.code) ? '#04A8A3' : 'transparent',
                borderRadius: 25,
                cursor: 'pointer',
                my: .2,
                marginRight: 2,
                py: .3
              }}
                onClick={(e) => handleSelectCountry(e, country.code)}>
                <Box sx={{ display: 'flex' }}>
                  <img src={'/img/common/flags/code/' + (country.code ? country.code : 'OLY') + '.gif'} alt='' height='18px' width='27px' style={{ marginLeft: 8 }} />
                  <Typography
                    sx={{
                      fontSize: '1.4rem',
                      ml: 1,
                      color: selectedCountries.includes(country.code) ? '#FFFFFF' : '#000000'
                    }}>
                    {country.name}
                  </Typography>
                </Box>
                <Box sx={{ display: selectedCountries.includes(country.code) ? 'flex' : 'none', mr: 1 }}>
                  <CloseIcon sx={{ color: '#FFFFFF', width: 15, height: 15 }} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: selectedCountries.length > 0 ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleDeselectAll}>
          <TranslatedContent code='deselectAll' defaultValue='Deselect All' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdCountryFilter