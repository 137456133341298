import { Box, Button, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setFiltersExpectedPrice } from '../../../slices/filtersSlice'
import { getCurrencySymbol } from '../../currency/userCurrency'
import StdRangeSlider from '../../form/stdRangeSlider'
import TranslatedContent from '../../translations/translatedContent'

const StdExpectedPriceFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const expectedPriceSlice = useSelector(state => state.filters.expectedPrice)
  const dispatch = useDispatch()

  const [minAbsoluteExpectedPrice, setMinAbsoluteExpectedPrice] = useState()
  const [maxAbsoluteExpectedPrice, setMaxAbsoluteExpectedPrice] = useState()
  const [marks, setMarks] = useState([])
  const [selectedExpectedPrice, setSelectedExpectedPrice] = useState([0, 500])

  useEffect(() => {
    if (!expectedPriceSlice) {
      filtersService.getPlayerExpectedPrices(userToken, dispatch)
        .then(res => {
          if (res.status === 200 && res.data.success) {
            dispatch(setFiltersExpectedPrice(res.data.data.expected))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (expectedPriceSlice) {
      setMinAbsoluteExpectedPrice(expectedPriceSlice.min_currency)
      setMaxAbsoluteExpectedPrice(expectedPriceSlice.max_currency)
      setSelectedExpectedPrice([expectedPriceSlice.min_currency, expectedPriceSlice.max_currency])
      calculateMarks(expectedPriceSlice.min_currency, expectedPriceSlice.max_currency)
    }
  }, [expectedPriceSlice])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedExpectedPrice([minAbsoluteExpectedPrice, maxAbsoluteExpectedPrice])
    }
  }))

  const calculateMarks = (min, max) => {
    let perc10 = Math.floor((max - min) * 0.1)
    let perc30 = Math.floor((max - min) * 0.3)
    let perc50 = Math.floor((max - min) * 0.5)
    let perc70 = Math.floor((max - min) * 0.7)
    let perc90 = Math.floor((max - min) * 0.9)
    let calculatedMarks = [
      {
        value: perc10,
        label: perc10.toString()
      },
      {
        value: perc30,
        label: perc30.toString()
      },
      {
        value: perc50,
        label: perc50.toString()
      },
      {
        value: perc70,
        label: perc70.toString()
      },
      {
        value: perc90,
        label: perc90.toString()
      },
    ]
    setMarks(calculatedMarks)
  }

  const handleChangeExpectedPrice = (newValue) => {
    setSelectedExpectedPrice(newValue)
    props.onChange(prepareFilter(newValue))
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setSelectedExpectedPrice([minAbsoluteExpectedPrice, maxAbsoluteExpectedPrice])
    props.onChange(prepareFilter([minAbsoluteExpectedPrice, maxAbsoluteExpectedPrice]))
  }

  const prepareFilter = (expectedPrice) => {
    let filterContent = {}
    if (expectedPrice[0] > minAbsoluteExpectedPrice) {
      filterContent[props.filterName + 'From'] = expectedPrice[0]
    }
    if (expectedPrice[1] < maxAbsoluteExpectedPrice) {
      filterContent[props.filterName + 'To'] = expectedPrice[1]
    }
    let filterValues = ''
    if (filterContent[props.filterName + 'From'] || filterContent[props.filterName + 'To']) {
      filterValues = expectedPrice[0] + ' ' + getCurrencySymbol(userCurrency) + ' - ' + expectedPrice[1] + ' ' + getCurrencySymbol(userCurrency)
    }
    let filter = {
      filterName: 'expectedPrice',
      filterValues: filterValues,
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='expectedPriceMln' defaultValue='Expected Price (Mln)' />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <StdRangeSlider
          value={selectedExpectedPrice}
          minValue={minAbsoluteExpectedPrice}
          maxValue={maxAbsoluteExpectedPrice}
          marks={marks}
          onChange={handleChangeExpectedPrice}
          step={10}
        />
      </Box>
      <Box sx={{ display: selectedExpectedPrice[0] > minAbsoluteExpectedPrice || selectedExpectedPrice[1] < maxAbsoluteExpectedPrice ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleResetFilter}>
          <TranslatedContent code='resetFilter' defaultValue='Reset Filter' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdExpectedPriceFilter