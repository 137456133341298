import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as InfoCircleIcon } from '../../icons/info_circle.svg'

export const MarketCard = (props) => {
  return (
    <Box sx={{
      border: '2px solid #04A8A3',
      borderRadius: '18px',
      ...props.sx
    }}>
      <Box sx={{ position: 'relative', top: -4, right: -4, display: 'flex', justifyContent: 'flex-end' }}>
        <InfoCircleIcon fill='#04A8A3' width='40px' height='40px' />
      </Box>
      <Box sx={{ px: 3, mb: 2 }}>
        {props.children}
      </Box>
    </Box>
  )
}

export const MarketCardContent = (props) => {
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '3rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          lineHeight: '1.5em',
          height: '3em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical'
        }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={6}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '7rem',
          fontWeight: 700
        }}>
          {props.value}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ alignSelf: 'center' }}>
        <Typography
          sx={{
            color: props.isPlayerCard ? '#F4D35E' : '#FFFFFF',
            fontSize: props.isPlayerCard ? '3rem' : '2.5rem',
            fontWeight: props.isPlayerCard ? 700 : 0,
            fontStyle: props.isPlayerCard ? 'normal' : 'italic',
            textTransform: 'uppercase',
            textAlign: 'right',
            m: -.5
          }}>
          {props.text1}
        </Typography>
        <Typography
          sx={{
            color: props.isPlayerCard ? '#F4D35E' : '#FFFFFF',
            fontSize: props.isPlayerCard ? '3rem' : '2.5rem',
            fontWeight: props.isPlayerCard ? 700 : 0,
            fontStyle: props.isPlayerCard ? 'normal' : 'italic',
            textTransform: 'uppercase',
            textAlign: 'right',
            m: -.5
          }}>
          {props.text2}
        </Typography>
      </Grid>
    </Grid >
  )
}
