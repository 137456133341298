import { Box, CircularProgress } from '@mui/material'
import { Fragment, useEffect, useRef, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PersonalWatchlist from '../../components/watchlists/personalWatchlist'
import { transitionWrapper } from '../../services/transitionWrapper'
import watchlistsService from '../../services/watchlistsService'
import { deletePersonalWatchlist, setPersonalWatchlists } from '../../slices/watchlistsSlice'

const PersonalWatchlists = () => {

  const userToken = useSelector(state => state.user.token)
  const personalWatchlists = useSelector(state => state.watchlists.personal)
  const dispatch = useDispatch()

  const [isPending, startTransition] = useTransition()

  const [watchlists, setWatchlists] = useState([])
  const watchlistsStateRef = useRef()
  watchlistsStateRef.current = watchlists

  useEffect(() => {
    setWatchlists(personalWatchlists)
  }, [personalWatchlists])

  useEffect(() => {
    reloadWatchlists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  const deleteWatchlist = (watchlistId) => {
    watchlistsService.deleteWatchlist(userToken, dispatch, watchlistId)
      .then(res => {
        if (res.status === 200) {
          dispatch(deletePersonalWatchlist(watchlistId))
        }
      })
      .catch(err => console.error(err))
  }

  const reloadWatchlists = () => {
    startTransition(() => {
      transitionWrapper(watchlistsService.getWatchlists(userToken, dispatch),
        res => {
          if (res.status === 200) {
            dispatch(setPersonalWatchlists(res.data.data))
          }
        })
    })
  }

  return (
    <Fragment>
      {isPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
        <Box sx={{ height: '100%', overflow: 'auto', mt: 1, pr: 2 }}>
          {watchlistsStateRef.current.map((w, idx) => (
            <PersonalWatchlist key={idx} watchlist={w} deleteWatchlist={deleteWatchlist} reloadWatchlists={reloadWatchlists} sx={{ my: 2 }} />
          ))}
        </Box>}
    </Fragment>
  )
}

export default PersonalWatchlists