import { Backdrop, Box, CircularProgress, Divider, Grid, MenuItem, Tooltip, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BestTeamIcon } from '../../icons/tvt-best-team.svg'
import { ReactComponent as EmergingTeamIcon } from '../../icons/tvt-emerging-team.svg'
import { ReactComponent as GoldenTeamIcon } from '../../icons/tvt-golden-team.svg'
import miscService from '../../services/miscService'
import { transitionWrapper } from '../../services/transitionWrapper'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import { coordinatesMap } from './topVirtualTeamCoordinatesMap'

const TopVirtualTeamPlayer = (props) => {

  const userCurrency = useSelector(state => state.user.currency)
  const navigate = useNavigate()

  const [showNationality, setShowNationality] = useState(false)
  const [paramValue, setParamValue] = useState('')

  useEffect(() => {
    if (props.entityType) {
      setShowNationality(props.entityType === 'club')
    }
  }, [props.entityType])

  useEffect(() => {
    if (props.paramToShow) {
      switch (props.paramToShow) {
        case 'performance':
          setParamValue((Math.round(props.player?.performance * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2 }))
          break
        case 'potentialPrice':
          setParamValue((Math.round(props.player?.potentialPrice * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2 }) + ' ' + getCurrencySymbol(userCurrency))
          break
        case 'age':
          setParamValue(props.player?.age?.toLocaleString('en-US') + ' Y / ' + (Math.round(props.player?.potentialPrice * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2 }) + ' ' + getCurrencySymbol(userCurrency))
          break
        default:
          console.warn('[TopVirtualTeam] unhandled param to show: ' + props.paramToShow)
          break
      }
    }
  }, [userCurrency, props.player, props.paramToShow])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 110,
        position: 'absolute',
        cursor: 'pointer',
        ...props.sx
      }}
      onClick={() => navigate('/players/' + props.player.playerId)}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: showNationality ? 'transparent' : '#FFFFFF', width: 35, height: 35, borderRadius: 15 }}>
        {showNationality ?
          <img src={'/img/common/flags/code/' + (props.player.nationalityCode ? props.player.nationalityCode : 'OLY') + '.gif'} alt={props.player.nationalityCode} width='100%' />
          :
          <img src={'/img/clubs/logos/' + props.player.currentClubId + '.png'} alt='' height='80%' />}
      </Box>
      <Typography sx={{ color: '#FFFFFF', fontSize: '1rem', textAlign: 'center', lineHeight: 1 }}>{showNationality ? props.player.nationality : props.player.currentClubName}</Typography>
      <Typography sx={{ color: '#F4D35E', fontSize: '1.2rem', textAlign: 'center', fontWeight: 500, lineHeight: 1 }}>{props.player.name}</Typography>
      <Typography sx={{ color: '#04A8A3', fontSize: '1.2rem', textAlign: 'center', fontWeight: 500, lineHeight: 1 }}>{paramValue}</Typography>
    </Box>
  )
}

const TopVirtualTeamPitch = (props) => {

  const [players, setPlayers] = useState([])
  const [playersCoordinates, setPlayersCoordinates] = useState({})

  useEffect(() => {
    setPlayers(props.players.team)
    setPlayersCoordinates(coordinatesMap[props.players.module])
  }, [props.players])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 378,
          minHeight: 615,
          backgroundImage: 'url(/img/common/soccer_field.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}>
        {players?.map((player, idx) => (
          <TopVirtualTeamPlayer entityType={props.entityType} player={player} sx={playersCoordinates[player.number]} paramToShow={props.paramToShow} />
        ))}
        <Backdrop
          open={props.loading}
          sx={{ position: 'absolute' }}>
          <CircularProgress
            sx={{
              zIndex: 1
            }}
          />
        </Backdrop>
      </Box>
    </Box>
  )
}

const TopVirtualTeamModulesSelect = (props) => {

  const modules = [
    '3-4-2-1',
    '3-4-3',
    '3-5-2',
    '4-1-2-1-2',
    '4-1-4-1',
    '4-2-4',
    '4-3-1-2',
    '4-3-2-1',
    '4-3-3',
    '4-4-2',
    '4-5-1',
    '5-3-2'
  ]

  return (
    <StdSelect
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      sx={{ width: { xs: '30%', xl: '25%' }, ...props.sx }}>
      <MenuItem id='Module' value='Module' disabled sx={{ display: 'none' }}>
        Module
      </MenuItem>
      {modules.map((mod, idx) => (
        <MenuItem id={mod} key={idx} value={mod}>
          {mod}
        </MenuItem>
      ))}
    </StdSelect>
  )
}

const TopVirtualTeam = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [isPendingBestTeam, startBestTeamTransition] = useTransition()
  const [isPendingGoldenTeam, startGoldenTeamTransition] = useTransition()
  const [isPendingEmergingTeam, startEmerginTeamTransition] = useTransition()

  const [displayState, setDisplayState] = useState('bestTeam')

  const [bestTeamModule, setBestTeamModule] = useState('4-2-4')
  const [bestTeamPlayers, setBestTeamPlayers] = useState([])

  const [goldenTeamModule, setGoldenTeamModule] = useState('4-2-4')
  const [goldenTeamPlayers, setGoldenTeamPlayers] = useState([])

  const [emergingTeamModule, setEmergingTeamModule] = useState('4-2-4')
  const [emergingTeamPlayers, setEmergingTeamPlayers] = useState([])

  useEffect(() => {
    if (props.entityId && bestTeamModule && bestTeamModule !== '' && bestTeamModule !== 'Module') {
      let filterName = props.entityType === 'league' ? 'leagueId' : 'clubId'
      startBestTeamTransition(() => {
        transitionWrapper(
          miscService.getVirtualTeam(userToken, dispatch, { filterName: filterName, filterValue: props.entityId }, 'performance', bestTeamModule),
          res => {
            if (res.status === 200 && res.data.data && res.data.data.length > 0) {
              setBestTeamPlayers(res.data.data[0])
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.entityId, bestTeamModule])

  useEffect(() => {

    if (props.entityId && goldenTeamModule && goldenTeamModule !== '' && goldenTeamModule !== 'Module') {
      let filterName = props.entityType === 'league' ? 'leagueId' : 'clubId'
      startGoldenTeamTransition(() => {
        transitionWrapper(
          miscService.getVirtualTeam(userToken, dispatch, { filterName: filterName, filterValue: props.entityId }, 'potentialPrice', goldenTeamModule),
          res => {
            if (res.status === 200 && res.data.data && res.data.data.length > 0) {
              setGoldenTeamPlayers(res.data.data[0])
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.entityId, goldenTeamModule])

  useEffect(() => {

    if (props.entityId && emergingTeamModule && emergingTeamModule !== '' && emergingTeamModule !== 'Module') {
      let filterName = props.entityType === 'league' ? 'leagueId' : 'clubId'
      startEmerginTeamTransition(() => {
        transitionWrapper(
          miscService.getVirtualTeam(userToken, dispatch, { filterName: filterName, filterValue: props.entityId }, 'potentialPrice', emergingTeamModule, true),
          res => {
            if (res.status === 200 && res.data.data && res.data.data.length > 0) {
              setEmergingTeamPlayers(res.data.data[0])
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.entityId, emergingTeamModule])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='topVirtualTeam' defaultValue='Top Virtual Team' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%', mb: 2 }} />
      <Grid container spacing={{ xs: 0, lg: 5 }} sx={{ px: { xs: 0, xl: 5 } }}>
        <Grid item xs={12} lg={4} sx={{ display: { xs: displayState === 'bestTeam' ? 'block' : 'none', lg: 'block' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, width: { xs: 40, xl: 55 }, height: '100%' }}>
                <BestTeamIcon />
              </Box>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Tooltip title={<TranslatedContent code='bestTeam' defaultValue='Best Team' description />}>
                  <Typography sx={{ color: '#04A8A3', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                    <TranslatedContent code='bestTeam' defaultValue='Best Team' />
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: 'center', width: '100%' }}>
                <StdSelect
                  value={displayState}
                  onChange={(e) => setDisplayState(e.target.value)}
                  sx={{ width: { xs: '100%', sm: 250 } }}>
                  <MenuItem id='bestTeam' key='bestTeam' value='bestTeam'>
                    <TranslatedContent code='bestTeam' defaultValue='Best Team' />
                  </MenuItem>
                  <MenuItem id='goldenTeam' key='goldenTeam' value='goldenTeam'>
                    <TranslatedContent code='goldenTeam' defaultValue='Golden Team' />
                  </MenuItem>
                  <MenuItem id='emergingTeam' key='emergingTeam' value='emergingTeam'>
                    <TranslatedContent code='emergingTeam' defaultValue='Emerging Team' />
                  </MenuItem>
                </StdSelect>
              </Box>
            </Box>
            <TopVirtualTeamModulesSelect value={bestTeamModule} onChange={setBestTeamModule} sx={{ ml: 2 }} />
          </Box>
          <TopVirtualTeamPitch entityType={props.entityType} players={bestTeamPlayers} loading={isPendingBestTeam} paramToShow='performance' />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: { xs: displayState === 'goldenTeam' ? 'block' : 'none', lg: 'block' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, width: { xs: 40, xl: 55 }, height: '100%' }}>
                <GoldenTeamIcon />
              </Box>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Tooltip title={<TranslatedContent code='goldenTeam' defaultValue='Golden Team' description />}>
                  <Typography sx={{ color: '#04A8A3', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                    <TranslatedContent code='goldenTeam' defaultValue='Golden Team' />
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: 'center', width: '100%' }}>
                <StdSelect
                  value={displayState}
                  onChange={(e) => setDisplayState(e.target.value)}
                  sx={{ width: { xs: '100%', sm: 250 } }}>
                  <MenuItem id='bestTeam' key='bestTeam' value='bestTeam'>
                    <TranslatedContent code='bestTeam' defaultValue='Best Team' />
                  </MenuItem>
                  <MenuItem id='goldenTeam' key='goldenTeam' value='goldenTeam'>
                    <TranslatedContent code='goldenTeam' defaultValue='Golden Team' />
                  </MenuItem>
                  <MenuItem id='emergingTeam' key='emergingTeam' value='emergingTeam'>
                    <TranslatedContent code='emergingTeam' defaultValue='Emerging Team' />
                  </MenuItem>
                </StdSelect>
              </Box>
            </Box>
            <TopVirtualTeamModulesSelect value={goldenTeamModule} onChange={setGoldenTeamModule} sx={{ ml: 2 }} />
          </Box>
          <TopVirtualTeamPitch entityType={props.entityType} players={goldenTeamPlayers} loading={isPendingGoldenTeam} paramToShow='potentialPrice' />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: { xs: displayState === 'emergingTeam' ? 'block' : 'none', lg: 'block' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, width: { xs: 40, xl: 55 }, height: '100%' }}>
                <EmergingTeamIcon />
              </Box>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Tooltip title={<TranslatedContent code='emergingTeam' defaultValue='Emerging Team' description />}>
                  <Typography sx={{ color: '#04A8A3', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase', mx: { xs: 1, xl: 2 } }}>
                    <TranslatedContent code='emergingTeam' defaultValue='Emerging Team' />
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: 'center', width: '100%' }}>
                <StdSelect
                  value={displayState}
                  onChange={(e) => setDisplayState(e.target.value)}
                  sx={{ width: { xs: '100%', sm: 250 } }}>
                  <MenuItem id='bestTeam' key='bestTeam' value='bestTeam'>
                    <TranslatedContent code='bestTeam' defaultValue='Best Team' />
                  </MenuItem>
                  <MenuItem id='goldenTeam' key='goldenTeam' value='goldenTeam'>
                    <TranslatedContent code='goldenTeam' defaultValue='Golden Team' />
                  </MenuItem>
                  <MenuItem id='emergingTeam' key='emergingTeam' value='emergingTeam'>
                    <TranslatedContent code='emergingTeam' defaultValue='Emerging Team' />
                  </MenuItem>
                </StdSelect>
              </Box>
            </Box>
            <TopVirtualTeamModulesSelect value={emergingTeamModule} onChange={setEmergingTeamModule} sx={{ ml: 2 }} />
          </Box>
          <TopVirtualTeamPitch entityType={props.entityType} players={emergingTeamPlayers} loading={isPendingEmergingTeam} paramToShow='age' />
        </Grid>
      </Grid>
    </Box >
  )
}

export default TopVirtualTeam