import { Box, Button, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setFiltersAge } from '../../../slices/filtersSlice'
import StdAgeFormat from '../../form/stdAgeFormat'
import StdNumericField from '../../form/stdNumericField'
import TranslatedContent from '../../translations/translatedContent'

const StdAgeFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const ageSlice = useSelector(state => state.filters.age)
  const dispatch = useDispatch()

  const [minAbsoluteAge, setMinAbsoluteAge] = useState()
  const [maxAbsoluteAge, setMaxAbsoluteAge] = useState()
  const [selectedMinAge, setSelectedMinAge] = useState()
  const [selectedMaxAge, setSelectedMaxAge] = useState()

  useEffect(() => {
    if (!ageSlice) {
      filtersService.getPlayerAges(userToken, dispatch)
        .then(res => {
          if (res.status === 200 && res.data.success) {
            dispatch(setFiltersAge(res.data.data.ages))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (ageSlice) {
      setMinAbsoluteAge(ageSlice.min)
      setMaxAbsoluteAge(ageSlice.max)
      if (isNaN(selectedMinAge)) setSelectedMinAge(ageSlice.min)
      if (isNaN(selectedMaxAge)) setSelectedMaxAge(ageSlice.max)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageSlice])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedMinAge(minAbsoluteAge)
      setSelectedMaxAge(maxAbsoluteAge)
    }
  }))

  const handleChangeMinAge = (newValue) => {
    setSelectedMinAge(newValue)
    props.onChange(prepareFilter(newValue.toString(), selectedMaxAge))
  }

  const handleChangeMaxAge = (newValue) => {
    setSelectedMaxAge(newValue)
    props.onChange(prepareFilter(selectedMinAge, newValue.toString()))
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    setSelectedMinAge(minAbsoluteAge)
    setSelectedMaxAge(maxAbsoluteAge)
    props.onChange(prepareFilter(minAbsoluteAge, maxAbsoluteAge))
  }

  const prepareFilter = (min, max) => {
    let filterContent = {}
    if (min > minAbsoluteAge) {
      filterContent[props.filterName + 'From'] = min
    }
    if (max < maxAbsoluteAge) {
      filterContent[props.filterName + 'To'] = max
    }
    let filterValues = ''
    if (filterContent[props.filterName + 'From'] || filterContent[props.filterName + 'To']) {
      filterValues = min + ' - ' + max
    }
    let filter = {
      filterName: 'age',
      filterValues: filterValues,
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='age' defaultValue='Age' />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <StdNumericField
          label={<TranslatedContent code='from' defaultValue='From' />}
          value={selectedMinAge}
          minValue={minAbsoluteAge}
          maxValue={maxAbsoluteAge}
          onChange={handleChangeMinAge}
          inputComponent={StdAgeFormat}
          step={1}
        />
        <StdNumericField
          label={<TranslatedContent code='to' defaultValue='To' />}
          value={selectedMaxAge}
          minValue={minAbsoluteAge}
          maxValue={maxAbsoluteAge}
          onChange={handleChangeMaxAge}
          inputComponent={StdAgeFormat}
          step={1}
        />
      </Box>
      <Box sx={{ display: selectedMinAge > minAbsoluteAge || selectedMaxAge < maxAbsoluteAge ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleResetFilter}>
          <TranslatedContent code='resetFilter' defaultValue='Reset Filter' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdAgeFilter