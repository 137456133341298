import CloseIcon from '@mui/icons-material/Close'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Button, ClickAwayListener, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import TranslatedContent from '../../translations/translatedContent'

const StdFilter = (props) => {

  const [open, setOpen] = useState(props.open)
  const [filterSummary, setFilterSummary] = useState(props.filterSummary)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  useEffect(() => {
    props.setOpen(open)
  }, [open])

  useEffect(() => {
    setFilterSummary(props.filterSummary)
  }, [open, props.filterSummary])

  return (
    <Fragment>
      <Box
        sx={{
          display: 'none',
          backgroundColor: '#04A8A3',
          position: 'fixed',
          right: 15,
          top: '45%',
          height: 150,
          width: 50,
          clipPath: 'polygon(0 15%, 100% 0, 100% 100%, 0 85%)',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}>
        <TuneIcon sx={{ color: '#DFDFDF', fontSize: 30 }} />
      </Box>
      <ClickAwayListener
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
        onClickAway={() => {
          if (open) {
            setOpen(false)
          }
        }}>
        <Box
          className='stdFilter'
          sx={{
            position: 'fixed',
            right: open ? 0 : '-100vw',
            transition: 'all .2s ease-in-out',
            width: { xs: '100%', sm: 500 },
            top: 0,
            bottom: 0,
            backgroundColor: '#DFDFDF',
            height: { xs: 'calc(100% - 65px)', md: '100%' },
            zIndex: 300
          }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 100px)',
            mt: 10,
            justifyContent: 'space-between',
            px: 5
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}>
                <Typography sx={{ color: '#04A8A3', fontSize: '3rem', fontWeight: 600 }}>
                  <TranslatedContent code='filters' defaultValue='Filters' />
                </Typography>
                <IconButton onClick={(e) => {
                  e.preventDefault()
                  setOpen(false)
                }}>
                  <CloseIcon sx={{ color: '#475b6b', fontSize: '5rem' }} />
                </IconButton>
              </Box>
              <Divider sx={{ backgroundColor: '#475b6b', height: 2, my: 1 }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                pr: 2,
                '&::after': {
                  content: '""',
                  display: 'block',
                  position: 'sticky',
                  bottom: 0,
                  height: 50,
                  paddingTop: -50,
                  pointerEvents: 'none',
                  width: '100%',
                  backgroundImage: 'linear-gradient(to bottom, transparent, #DFDFDF)',
                }
              }}>
                {props.children}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Divider sx={{ backgroundColor: '#475b6b', height: 2, my: 1 }} />
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: 600, fontStyle: 'italic', mb: 1 }}>
                  <TranslatedContent code='filterSummary' defaultValue='Filter Summary' />
                </Typography>
                {filterSummary.map((fs, i) => {
                  let overflow = fs.filterValues.length > 300
                  return (
                    <Box key={i}>
                      <Typography sx={{ display: 'inline', maxWidth: '100%', fontSize: '1.2rem', fontStyle: 'italic', textTransform: 'capitalize', textDecoration: 'underline' }}>
                        <TranslatedContent code={fs.filterName} />:
                      </Typography>
                      <Tooltip title={fs.filterValues} placement='top'>
                        <Typography sx={{ display: 'inline', maxWidth: '100%', fontSize: '1.2rem', fontStyle: 'italic' }}>
                          &nbsp;{fs.filterValues}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )
                })}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '80%', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', mt: 5 }}>
                <Button sx={{
                  color: 'black',
                  fontSize: '1.3rem',
                  backgroundColor: '#A1CBC2',
                  height: 30,
                  width: 150,
                  borderRadius: 28,
                  m: .5,
                  '&:hover': {
                    backgroundColor: '#A1CBC2',
                  }
                }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onApply()
                    setOpen(false)
                  }}>
                  <TranslatedContent code='applyFilters' defaultValue='Apply Filters' />
                </Button>
                <Button sx={{
                  color: '#FFFFFF',
                  fontSize: '1.3rem',
                  backgroundColor: '#15222B',
                  height: 30,
                  width: 150,
                  borderRadius: 28,
                  m: .5,
                  '&:hover': {
                    backgroundColor: '#15222B',
                  }
                }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onDelete()
                    setOpen(false)
                  }}>
                  <TranslatedContent code='deleteFilters' defaultValue='Delete Filters' />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ClickAwayListener>
    </Fragment >
  )
}

export default StdFilter