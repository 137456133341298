import { Box, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import { ReactComponent as CheckCircleIcon } from '../../icons/check_circle.svg'
import watchlistsService from '../../services/watchlistsService'
import { setRecommendedWatchlists } from '../../slices/watchlistsSlice'
import StdPlayersTable from '../players/stdPlayersTable'

const DynamicWatchlist = (props) => {

  const userToken = useSelector(state => state.user.token)
  const attachedDynamicWatchlists = useSelector(state => state.watchlists.recommended)
  const dispatch = useDispatch()

  const [watchlist, setWatchlist] = useState(props.watchlist)
  const [watchlistPlayers, setWatchlistPlayers] = useState([])
  const [isAlreadyAttached, setIsAlreadyAttached] = useState(false)
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    if (props.watchlist) {
      setWatchlist(props.watchlist)
    }
  }, [props.watchlist])

  useEffect(() => {
    if (Array.isArray(attachedDynamicWatchlists) && attachedDynamicWatchlists.length > 0) {
      let attached = false
      attachedDynamicWatchlists.every(attachedW => {
        attached |= (attachedW.id === watchlist.id && attachedW.entity === props.entityType && attachedW.entity_id === props.entityId)
        return !attached
      })
      setIsAlreadyAttached(attached)
    }
  }, [watchlist, attachedDynamicWatchlists, props.entityType, props.entityId])

  useEffect(() => {
    let sortedWp = [...Object.values(props.watchlist.players)]
    if (sorting && sorting.length > 0) {
      let sortingField = sorting[0].id
      sortedWp.sort((a, b) => {
        let order = 0
        sorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
    } else {
      sortedWp.sort((a, b) => b.potentialPrice - a.potentialPrice)
    }
    setWatchlistPlayers(sortedWp)
  }, [props.watchlist, sorting])

  const refreshRecommendedWatchlists = () => {
    if (userToken) {
      watchlistsService.getAttachedDynamicWatchlists(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setRecommendedWatchlists(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
  }

  const handleAttachWatchlistClicked = (watchlistId) => {
    watchlistsService.attachDynamicWatchlist(userToken, dispatch, watchlistId, props.entityType, props.entityId)
      .then(res => {
        if (res.status === 200) {
          refreshRecommendedWatchlists()
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', border: '2px solid #04A8A3', borderRadius: 5, p: 3, my: 3 }}>
      <IconButton disabled={isAlreadyAttached} sx={{ position: 'absolute', top: -12, right: -12 }} onClick={() => handleAttachWatchlistClicked(watchlist.id)}>
        {isAlreadyAttached ?
          <CheckCircleIcon fill='#04A8A3' width='50px' height='50px' />
          :
          <AddCircleIcon fill='#04A8A3' width='50px' height='50px' />}
      </IconButton>
      <StdPlayersTable
        title={watchlist.name}
        players={watchlistPlayers}
        rowId={(player) => player.playerId}
        rowCount={watchlistPlayers.length}
        enablePagination={false}
        enableBottomToolbar={false}
        sorting={sorting}
        onSortingChange={setSorting}
        isDynamicWatchlist
        disableFilters
      />
    </Box>
  )
}

export default DynamicWatchlist