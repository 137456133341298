import { LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import LoginFooter from '../components/login/loginFooter'
import LoginForm from '../components/login/loginForm'
import authService from '../services/authService'
import { setAuth, setToken, setUserInfo } from '../slices/userSlice'

const LoginPage = () => {

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const handleSubmit = (username, password) => {
    setIsLoading(true)
    authService.login(username, password)
      .then(res => {
        setIsLoading(false)
        if (res.status === 200 && res.data.success) {
          setErrorMessage('')
          dispatch(setAuth(res.data.success))
          dispatch(setToken(res.data.data.token))
          authService.setCookieToken(res.data.data.token)
          dispatch(setUserInfo(res.data.data))
          let returnUrl = location.state?.from?.pathname || '/dashboard'
          navigate(returnUrl)
        } else if (res.status === 401) {
          setErrorMessage('Invalid Credentials')
        } else {
          console.error('[Login] Generic error')
        }
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          setErrorMessage('Invalid Credentials')
        } else if (error.request) {
          setErrorMessage('Server Error')
        } else {
          console.error(error)
        }
      })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: { xs: 'auto', lg: 'hidden' } }}>
      <LinearProgress sx={{ display: isLoading ? 'block' : 'none' }} />
      <Box
        sx={{
          position: { xs: 'static', lg: 'absolute' },
          height: { xs: 'fit-content', lg: '100%', xxl: 'fit-content' },
          top: 0,
          bottom: 0,
          right: { xs: 0, xxl: '10vw' },
          margin: 'auto 0',
          px: 5,
          py: 10,
          backgroundColor: '#FFFFFF',
          zIndex: 5,
          boxShadow: '10px 10px 25px 5px rgba(0, 0, 0, .2)'
        }}>
        <LoginForm onSubmit={handleSubmit} errorMessage={errorMessage} />
      </Box>
      <Grid container
        sx={{
          display: { xs: 'none', lg: 'flex' },
          background: '#15222b url("/img/common/bgHome.png") no-repeat center center fixed',
          backgroundSize: 'cover',
          height: '100%',
          width: '100%',
          px: { lg: 10 },
          py: '20vh'
        }}>
        <Grid item lg={7} xl={4}
          sx={{
            display: { xs: 'none', md: 'inherit' }
          }}>
          <Typography sx={{
            color: 'white',
            fontSize: { md: '4rem', lg: '7rem' },
            fontWeight: '600',
            lineHeight: '1.25',
            letterSpacing: -2,
            textTransform: 'uppercase'
          }}>
            Asset pricing and economic analysis for the football industry
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{
        position: { xs: 'static', lg: 'fixed' },
        bottom: 0,
        width: '100%',
        height: { xs: 'unset', lg: 250 },
        backgroundColor: { xs: '#15222B', lg: '#FFFFFF' }
      }}>
        <LoginFooter />
      </Box>
    </Box>
  )
}

export default LoginPage