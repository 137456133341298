import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import playersService from '../../services/playersService'
import { transitionWrapper } from '../../services/transitionWrapper'
import StdPlayersTable from './stdPlayersTable'

const SimilarPlayers = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()
  const [_, startTransition] = useTransition()

  const [similarPlayers, setSimilarPlayers] = useState([])
  const [filteredSimilarPlayers, setFilteredSimilarPlayers] = useState(similarPlayers)
  const [nameFilter, setNameFilter] = useState()
  const [sorting, setSorting] = useState()

  useEffect(() => {
    if (props.playerId) {
      startTransition(() => {
        transitionWrapper(playersService.getSimilarPlayers(userToken, dispatch, props.playerId),
          res => {
            if (res.status === 200) {
              setSimilarPlayers(res.data.data.sort((a, b) => b.potentialPrice - a.potentialPrice))
              props.similarPlayersCallback(res.data.data)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.playerId])

  useEffect(() => {
    if (nameFilter) {
      let fp = similarPlayers.filter(p => p.name.toLowerCase().includes(nameFilter.toLowerCase()))
      setFilteredSimilarPlayers(fp)
    } else {
      setFilteredSimilarPlayers(similarPlayers)
    }
  }, [similarPlayers, nameFilter])

  useEffect(() => {
    let sortedFp = [...filteredSimilarPlayers]
    if (sorting && sorting.length > 0) {
      let sortingField = sorting[0].id
      sortedFp.sort((a, b) => {
        let order = 0
        sorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
    } else {
      sortedFp.sort((a, b) => b.potentialPrice - a.potentialPrice)
    }
    setFilteredSimilarPlayers(sortedFp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  return (
    <StdPlayersTable
      title='similarPlayers'
      players={filteredSimilarPlayers}
      rowCount={filteredSimilarPlayers.length}
      page={0}
      pageSize={filteredSimilarPlayers.length}
      sorting={sorting}
      onSortingChange={setSorting}
      enableBottomToolbar={false}
      filterByName={setNameFilter}
      disableFilters
      sx={{ ...props.sx }} />
  )
}

export default SimilarPlayers