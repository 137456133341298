import { Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import StdLineGraph from '../../components/charts/stdLineGraph'
import { currencyUomRenderer, percNumberRenderer } from '../../components/data/formatters/dataFormatters'
import StdSelect from '../../components/form/stdSelect'
import TranslatedContent from '../../components/translations/translatedContent'
import DashboardVerticalGrid from './dashboardVerticalGrid'

const DashboardKeyIndicators = (props) => {

  const emptyData = [
    { field: 'potentialPrice', name: 'fairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'residualValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'expectedPrice', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'avgLatestMicroVolatility', name: 'volatility', value: '-', valueRenderer: percNumberRenderer },
    //{ field: 'costPerPoint', value: 'COMING SOON' }
  ]

  const [data, setData] = useState(emptyData)
  const [graphTimeInterval, setGraphTimeInterval] = useState(3)

  useEffect(() => {
    if (Object.keys(props.club).length > 0) {
      let tmpData = []
      emptyData.forEach((row) => {
        tmpData.push({ field: row.field, name: row.name, value: row.value === 'COMING SOON' ? row.value : props.club[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
      })
      setData(tmpData)
    } else {
      setData(emptyData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.club])

  return (
    <Stack sx={{ ...props.sx }}>
      <Tooltip title={<TranslatedContent code='keyIndicators' defaultValue='Key Indicators' description />}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
          <TranslatedContent code='keyIndicators' defaultValue='Key Indicators' />
        </Typography>
      </Tooltip>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <DashboardVerticalGrid data={data} sx={{ my: 2 }} />
      {false && <StdSelect
        value={graphTimeInterval}
        onChange={(e) => setGraphTimeInterval(e.target.value)}
        sx={{ display: 'none', alignSelf: 'center', width: '50%' }}>
        <MenuItem id='3months' value={3}>
          3 <TranslatedContent code='months' defaultValue='Months' />
        </MenuItem>
      </StdSelect>}
      <StdLineGraph chartName='dashboardKeyIndicators' sx={{ display: 'none', height: '25rem', my: 1 }} />
    </Stack>
  )
}

export default DashboardKeyIndicators