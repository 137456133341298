import { Box, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import StdAppBar from './stdAppBar/stdAppBar'
import StdAppFooter from './stdAppBar/stdAppFooter'
import StdMenuDrawer from './stdMenuDrawer'

const Navbar = ({ children }) => {

  const location = useLocation()
  const isBreakpointDownMd = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    document.getElementById('main').scroll(0, 0)
  }, [location])

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <StdAppBar />
      <StdMenuDrawer />
      <Box
        id='main'
        component='main'
        sx={{
          height: { xs: 'calc(100vh - 130px)', md: 'calc(100vh - 65px)' },
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: '65px',
          marginBottom: { xs: '65px', md: 0 },
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: { xs: 2, sm: 5, md: 'calc(65px + 80px)' },
          paddingRight: { xs: 2, sm: 5, md: 11 },
          overflow: 'auto'
        }}>
        {children}
        <Box sx={{ display: { xs: 'block', md: 'none', minHeight: 80, width: '100%' } }} />
      </Box>
      {isBreakpointDownMd && <StdAppFooter />}
    </Box>
  )
}

export default Navbar