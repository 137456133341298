import { api_unversioned } from './commonService'

const getUserInfo = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/userinfo')
}

const getLastUpdate = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/lastUpdate')
}

const resetPassword = (token, dispatch, oldPwd, newPwd, confPwd) => {
  let formData = new FormData()
  formData.append('old_password', oldPwd)
  formData.append('password', newPwd)
  formData.append('password_confirmation', confPwd)
  return api_unversioned(token, dispatch).post('/passwd', formData)
}

const getCurrencies = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/currencies')
}

const setCurrency = (token, dispatch, curr) => {
  return api_unversioned(token, dispatch).post('/currency', { currency: curr })
}

const getLanguages = (token, dispatch) => {
  return api_unversioned(token, dispatch).get('/languages')
}

const setLanguage = (token, dispatch, lang) => {
  return api_unversioned(token, dispatch).post('/language', { lang: lang })
}

const settingsService = {
  getUserInfo,
  getLastUpdate,
  resetPassword,
  getCurrencies,
  setCurrency,
  getLanguages,
  setLanguage
}

export default settingsService