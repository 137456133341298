import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdClubsTable from '../../components/clubs/stdClubsTable'
import StdClusterFilter from '../../components/data/filters/stdClusterFilter'
import StdCountryFilter from '../../components/data/filters/stdCountryFilter'
import StdFilter from '../../components/data/filters/stdFilter'
import StdLeagueFilter from '../../components/data/filters/stdLeagueFilter'
import StdRevenueFilter from '../../components/data/filters/stdRevenueFilter'
import clubsService from '../../services/clubsService'


const ClubsPage = () => {

  const initialPage = {
    page: 1,
    per_page: 20
  }

  const defaultSort = {
    field: 'potentialPrice',
    sort: 'desc'
  }

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const nameSearchRef = useRef()

  const countryFilterRef = useRef()
  const clusterFilterRef = useRef()
  const leagueFilterRef = useRef()
  const revenueFilterRef = useRef()

  const [queryParams, setQueryParams] = useState({
    page: initialPage,
    filter: {},
    sort: defaultSort
  })

  const [clubs, setClubs] = useState([])
  const [filterOpen, setFilterOpen] = useState(false)
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 })
  const [sorting, setSorting] = useState([])
  const [filterSummary, setFilterSummary] = useState([])
  const [rowCount, setRowCount] = useState(0)

  useEffect(() => {
    clubsService.getClubs(userToken, dispatch, queryParams.page, queryParams.filter, queryParams.sort)
      .then(res => {
        if (res.status === 200 && res.data.success) {
          setClubs(res.data.data)
          setRowCount(res.data.meta.total)
        } else {
          setClubs([])
          setRowCount(0)
        }
      })
      .catch(err => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, queryParams])

  useEffect(() => {
    let newSort
    if (sorting && sorting.length > 0) {
      newSort = { field: sorting[0].id, sort: sorting[0].desc ? 'desc' : 'asc' }
    } else {
      newSort = defaultSort
    }
    setQueryParams(q => {
      return {
        ...q,
        sort: newSort,
        page: initialPage
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  useEffect(() => {
    setQueryParams(q => {
      return {
        ...q,
        page: { page: pagination.pageIndex + 1, per_page: pagination.pageSize }
      }
    })
  }, [pagination.pageIndex, pagination.pageSize])

  const handleApplyFilters = () => {
    nameSearchRef.current.resetNameFilter()
    let newFilter = {}
    filterSummary.forEach((fs) => {
      newFilter = {
        ...newFilter,
        ...fs.filterContent
      }
    })
    setQueryParams({
      ...queryParams,
      filter: newFilter,
      page: initialPage
    })
  }

  const deleteFilters = () => {
    setQueryParams({
      ...queryParams,
      page: initialPage,
      filter: {}
    })
    setFilterSummary([])
    countryFilterRef.current.reset()
    clusterFilterRef.current.reset()
    leagueFilterRef.current.reset()
    revenueFilterRef.current.reset()
  }

  const handleFilterChange = (newFilter) => {
    let newFilterSummary = [...filterSummary]
    if (newFilter) {
      let filterIndex = filterSummary.findIndex((fs) => fs.filterName === newFilter.filterName)
      if (newFilter.filterValues && newFilter.filterValues.length > 0) {
        if (filterIndex === -1) {
          newFilterSummary.push(newFilter)
        } else {
          newFilterSummary[filterIndex].filterValues = newFilter.filterValues
          newFilterSummary[filterIndex].filterContent = newFilter.filterContent
        }
      } else {
        if (filterIndex !== -1) {
          newFilterSummary.splice(filterIndex, 1)
        }
      }
    }
    setFilterSummary(newFilterSummary)
  }

  const handleFilterByName = (searchText) => {
    if (searchText && searchText !== '') {
      setFilterSummary([])
      countryFilterRef.current.reset()
      clusterFilterRef.current.reset()
      leagueFilterRef.current.reset()
      revenueFilterRef.current.reset()
      setQueryParams({
        ...queryParams,
        page: initialPage,
        filter: { name: searchText }
      })
    } else {
      setQueryParams({
        page: initialPage,
        filter: {},
        sort: defaultSort
      })
    }
  }

  return (
    <Fragment>
      <StdClubsTable
        ref={nameSearchRef}
        clubs={clubs}
        enablePagination
        manualPagination
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        page={queryParams.page.page - 1}
        pageSize={20}
        rowCount={rowCount}
        filterByName={handleFilterByName}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
      />
      <StdFilter open={filterOpen} setOpen={setFilterOpen} filterSummary={filterSummary} onApply={handleApplyFilters} onDelete={deleteFilters}>
        <StdCountryFilter ref={countryFilterRef} onChange={handleFilterChange} filterName='countryCode' sx={{ my: 2 }} />
        <StdClusterFilter ref={clusterFilterRef} onChange={handleFilterChange} filterName='cluster' sx={{ my: 2 }} />
        <StdLeagueFilter ref={leagueFilterRef} onChange={handleFilterChange} filterName='leagueId' sx={{ my: 2 }} />
        <StdRevenueFilter ref={revenueFilterRef} onChange={handleFilterChange} filterName='revenues' sx={{ my: 2 }} />
      </StdFilter>
    </Fragment>
  )
}

export default ClubsPage