import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { integerNumberRenderer, currencyUomRenderer } from '../../components/data/formatters/dataFormatters'
import StdGridCarousel from '../../components/layout/stdGridCarousel'
import TranslatedContent from '../../components/translations/translatedContent'
import DashboardKeyIndicators from '../../components/dashboard/dashboardKeyIndicators'
import DashboardReferencesTierIndicators from '../../components/dashboard/dashboardReferencesTierIndicators'
import DashboardComparableClubsIndicators from '../../components/dashboard/dashboardComparableClubsIndicators'
import DashboardStats from '../../components/dashboard/dashboardStats'
import DashboardMarketIndicators from '../../components/dashboard/dashboardMarketIndicators'
import DashboardPersonalWatchlist from '../../components/dashboard/dashboardPersonalWatchlist'
import DashboardRecommendedWatchlist from '../../components/dashboard/dashboardRecommendedWatchlist'
import DashboardAlert from '../../components/dashboard/dashboardAlert'

const DashboardPage = () => {

  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const statistics = useSelector(state => state.statistics)
  const [club, setClub] = useState({})
  const [generalData, setGeneralData] = useState([])
  const [valueData, setValueData] = useState([])
  const [salaryData, setSalaryData] = useState([])  
  useEffect(() => {
    if (user.myclub) {
      setClub(user.myclub)
    }
  }, [user.myclub])

  useEffect(() => {
      setGeneralData([{
        field: 'numOfLeagues',
        name: 'numOfLeagues',
        value: statistics?.statistics?.numOfLeagues,
        valueRenderer: integerNumberRenderer
      }, {
        field: 'numOfClubs',
        value: statistics?.statistics?.numOfClubs,
        valueRenderer: integerNumberRenderer
      }, {
        field: 'numOfPlayers',
        value: statistics?.statistics?.numOfPlayers,
        valueRenderer: integerNumberRenderer
      }, {
        field: 'under23NumOfPlayers',
        name: 'under23NumOfPlayers',
        value: statistics?.statistics?.under23NumOfPlayers,
        valueRenderer: integerNumberRenderer
      }
    ]);

    setValueData([{
      field: 'maxRevenues',
      name: 'maxRevenues',
      value: statistics?.statistics?.totalRevenues,
      valueRenderer: currencyUomRenderer
    }, {
      field: 'totalClubPotentialPrice',
      value: statistics?.statistics?.totalClubPotentialPrice,
      valueRenderer: currencyUomRenderer
    }, {
      field: 'totalPortfolioCost',
      value: statistics?.statistics?.totalPortfolioCost,
      valueRenderer: currencyUomRenderer
    }, {
      field: 'totalClubResidualValue',
      name: 'totalClubResidualValue',
      value: statistics?.statistics?.totalClubResidualValue,
      valueRenderer: currencyUomRenderer
    }
  ]);

    setSalaryData([{
      field: 'totalSalaryOptimal',
      name: 'totalSalaryOptimal',
      value: statistics?.statistics?.totalSalaryOptimal,
      valueRenderer: currencyUomRenderer
    }, {
      field: 'maxSalaryGross',
      value: statistics?.statistics?.totalSalaryGross,
      valueRenderer: currencyUomRenderer
    }, {
      field: 'totalSalaryNet',
      value: statistics?.statistics?.totalSalaryNet,
      valueRenderer: currencyUomRenderer
    }
  ]);
  }, [statistics])
  
  return (
    <Box sx={{ textAlign: 'left', width: '100%' }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ fontSize: '3rem', color: '#F4D35E', fontWeight: 600 }}>
            Overview section
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: '1.6rem', color: '#787878', fontWeight: 500 }}>
            {user.name}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />

      {/* <DashboardAlert /> */}

      <StdGridCarousel
        GridContainerProps={{
          spacing: 4
        }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 4
        }}
        CarouselProps={{
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}
        sx={{ mt: 5 }}
      >
     
        <DashboardStats title={'General'} stats={generalData}/>
        <DashboardStats title={'Value'} stats={valueData} />
        <DashboardStats title={'Salary'} stats={salaryData} />
        {/*<DashboardMarketIndicators />*/}
      </StdGridCarousel>
      

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ fontSize: '3rem', color: '#F4D35E', fontWeight: 600 }}>
            {club.name}
          </Typography>
        </Box>
        
      </Box>
      <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />
      <StdGridCarousel
        GridContainerProps={{
          spacing: 4
        }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 4
        }}
        CarouselProps={{
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } }
        }}
        sx={{ mt: 5 }}
      >
        <DashboardKeyIndicators club={club} />
        <DashboardReferencesTierIndicators clubCluster={club.cluster} />
        <DashboardComparableClubsIndicators clubId={club.clubId} clubCluster={club.cluster} />
        {/* <DashboardMarketIndicators /> */}


        
      </StdGridCarousel>

      <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={3}>
          <DashboardPersonalWatchlist />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DashboardRecommendedWatchlist title='emergingWatchlist' name='Emerging' />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DashboardRecommendedWatchlist title='expiringWatchlist' name='Expiring' />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 15 }}>
            <Button
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: 5,
                width: 200,
                height: 30,
                alignSelf: 'center',
                mt: 2,
                '&:hover': {
                  backgroundColor: '#FFFFFF'
                }
              }}
              onClick={() => navigate('/watchlists')}
              endIcon={<KeyboardArrowRightIcon />}>
              <Typography sx={{ color: '#04A8A3', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
                <TranslatedContent code='goToWatchlists' defaultValue='Go to watchlists' />
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardPage