import { Typography } from '@mui/material'

const StdRowCount = ({ cell }) => {
  let context = cell.getContext()
  const idx = context.row.index
  let page = context.table.options.state.pagination.pageIndex
  if (!page) page = 0
  let pageSize = context.table.options.state.pagination.pageSize
  if (!pageSize) pageSize = 0
  let rowCount = (1 + idx) + (pageSize * page)
  return (
    <Typography sx={{ color: '#FFFFFF', fontSize: '1.5rem', fontWeight: 400, opacity: .65 }}>
      {rowCount.toLocaleString('en-US')}
    </Typography>
  )
}

export default StdRowCount