import { Box, CircularProgress, Divider, MenuItem, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useSelector } from 'react-redux'
import StdLeagueTopClubsChart from '../charts/stdLeagueTopClubsChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'

const StdParamSelect = (props) => {

  const [value, setValue] = useState(props.selectedParam)

  useEffect(() => {
    setValue(props.selectedParam)
  }, [props.selectedParam])

  useEffect(() => {
    props.setSelectedParam(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <StdSelect
      value={value}
      onChange={(e) => setValue(e.target.value)}
      sx={{
        color: '#F4D35E',
        height: 24,
        ...props.sx
      }}>
      <MenuItem id='potentialPrice' value='potentialPrice'>
        <TranslatedContent code='potentialPrice' defaultValue='Fair Value' />
      </MenuItem>
      <MenuItem id='expectedPrice' value='expectedPrice'>
        <TranslatedContent code='expectedPrice' defaultValue='Expected Price' />
      </MenuItem>
      <MenuItem id='residualValue' value='residualValue'>
        <TranslatedContent code='residualValue' defaultValue='Residual Value' />
      </MenuItem>
      <MenuItem id='revenues' value='revenues'>
        <TranslatedContent code='revenues' defaultValue='Revenues' />
      </MenuItem>
      <MenuItem id='salaryGross' value='salaryGross'>
        <TranslatedContent code='salaryGross' defaultValue='Gross Salary' />
      </MenuItem>
      <MenuItem id='avgAge' value='avgAge'>
        <TranslatedContent code='avgAge' defaultValue='Average Age' />
      </MenuItem>
      <MenuItem id='numOfNationalPlayers' value='numOfNationalPlayers'>
        <TranslatedContent code='numOfNationalPlayers' defaultValue='National Players' />
      </MenuItem>
    </StdSelect>
  )
}

const LeagueComparablesTopClubs = (props) => {

  const userCurrency = useSelector(state => state.user.currency)
  const [isPending, startTransition] = useTransition()

  const [selectedParam, setSelectedParam] = useState('potentialPrice')
  const [selectedParamUom, setSelectedParamUom] = useState(() => getCurrencySymbol(userCurrency))
  const [data, setData] = useState([])
  const [numOfClubs, setNumOfClubs] = useState(0)

  useEffect(() => {
    if (props.league) {
      startTransition(() => {
        setData(props.league.Clubs)
        setNumOfClubs(props.league.numOfClubs)
      })
    }
  }, [props.league])

  useEffect(() => {
    switch (selectedParam) {
      case 'potentialPrice':
      case 'expectedPrice':
      case 'residualValue':
      case 'revenues':
      case 'salaryGross':
        setSelectedParamUom(getCurrencySymbol(userCurrency))
        break
      case 'avgAge':
        setSelectedParamUom('Y')
        break
      default:
        setSelectedParamUom('')
        break
    }
  }, [userCurrency, selectedParam])

  return (
    <Box sx={{ width: '100%', mt: 5, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '2rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}>
          <TranslatedContent code='performanceByIndicator' defaultValue='Performance By Indicator' />
        </Typography>
        <StdParamSelect
          selectedParam={selectedParam}
          setSelectedParam={setSelectedParam}
          sx={{ display: { xs: 'none', sm: 'inline-flex' }, width: '35%', borderBottom: 0 }} />
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: '.2rem', width: '100%' }} />
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', width: '100%', mt: 1 }}>
        <StdParamSelect
          selectedParam={selectedParam}
          setSelectedParam={setSelectedParam}
          sx={{ width: '70%' }} />
      </Box>
      {isPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
        <StdLeagueTopClubsChart chartName='leagueTopClubs' data={data} valueField={selectedParam} uom={selectedParamUom} numOfClubs={numOfClubs} sx={{ mt: { xs: 0, lg: 4, xl: 0 } }} />}
    </Box >
  )
}

export default LeagueComparablesTopClubs