import { Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StdLineGraph from '../../components/charts/stdLineGraph'
import { currencyUomRenderer, percNumberRenderer } from '../../components/data/formatters/dataFormatters'
import StdSelect from '../../components/form/stdSelect'
import TranslatedContent from '../../components/translations/translatedContent'
import statisticsService from '../../services/statisticsService'
import { addClusterStatistics } from '../../slices/statisticsSlice'
import DashboardVerticalGrid from './dashboardVerticalGrid'

const DashboardReferencesTierIndicators = (props) => {

  const emptyData = [
    { field: 'avgPotentialPrice', name: 'avgFairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'avgResidualValue', name: 'avgResidualValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'avgExpectedPrice', name: 'avgExpectedPrice', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'avgVolatility', name: 'avgVolatility', value: '-', valueRenderer: percNumberRenderer },
    //{ field: 'avgCostPerPoint', name: 'avgTierCostPerPoint', value: 'COMING SOON' },
  ]

  const userToken = useSelector(state => state.user.token)
  const statistics = useSelector(state => state.statistics)
  const dispatch = useDispatch()

  const [clusterStats, setClusterStats] = useState({})
  const [data, setData] = useState(emptyData)
  const [graphTimeInterval, setGraphTimeInterval] = useState(3)

  useEffect(() => {
    if (props.clubCluster && statistics.clusters[props.clubCluster]) {
      setClusterStats(statistics.clusters[props.clubCluster])
    }
  }, [statistics, props.clubCluster])

  useEffect(() => {
    if (props.clubCluster && !statistics.clusters[props.clubCluster]) {
      statisticsService.getClusterStatsById(userToken, dispatch, props.clubCluster)
        .then(res => {
          if (res.status === 200) {
            dispatch(addClusterStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.clubCluster])

  useEffect(() => {
    if (Object.keys(clusterStats).length > 0) {
      let tmpData = []
      emptyData.forEach((row) => {
        tmpData.push({ field: row.field, name: row.name, value: row.value === 'COMING SOON' ? row.value : clusterStats[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
      })
      setData(tmpData)
    } else {
      setData(emptyData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterStats])

  return (
    <Stack sx={{ ...props.sx }}>
      <Tooltip title={<TranslatedContent code='referencesTierIndicators' defaultValue='References Tier Indicators' description />}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 600, textTransform: 'uppercase' }}>
          <TranslatedContent code='referencesTierIndicators' defaultValue='References Tier Indicators' />
        </Typography>
      </Tooltip>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2 }} />
      <DashboardVerticalGrid data={data} sx={{ my: 2 }} />
      {false && <StdSelect
        value={graphTimeInterval}
        onChange={(e) => setGraphTimeInterval(e.target.value)}
        sx={{ display: 'none', alignSelf: 'center', width: '50%' }}>
        <MenuItem id='3months' value={3}>
          3 <TranslatedContent code='months' defaultValue='Months' />
        </MenuItem>
      </StdSelect>}
      <StdLineGraph chartName='dashboardReferencesTierIndicators' sx={{ display: 'none', height: '25rem', my: 1 }} />
    </Stack>
  )
}

export default DashboardReferencesTierIndicators