import { Box, Divider, Stack, Typography } from '@mui/material'
import TranslatedContent from '../translations/translatedContent'
import PlayerComparablesIndicators from './playerComparablesIndicators'
import PlayerComparablesTopPlayers from './playerComparablesTopPlayers'

const PlayerComparables = (props) => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{ color: '#FFFFFF', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase' }}>
        <TranslatedContent code='comparables' defaultValue='Comparables' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%' }} />
      <Stack sx={{ width: '100%' }}>
        {/* <Box sx={{ display: 'none', width: '100%' }}>
          <Box sx={{ width: '100%', height: 370, mr: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                color: '#FFFFFF',
                fontSize: '2rem',
                fontWeight: 600,
                textTransform: 'uppercase'
              }}>
                <TranslatedContent code='fairValue' defaultValue='Fair Value' />
              </Typography>
              <StdSelect value='' sx={{ display: 'none', backgroundColor: '#FFFFFF', height: 25, width: 200, border: 0 }}></StdSelect>
            </Box>
            <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
            <StdLineGraph chartName='fairValue' legend sx={{ height: 300, my: 5 }} />
          </Box>
          <Box sx={{ width: '100%', height: 300, ml: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                color: '#FFFFFF',
                fontSize: '2rem',
                fontWeight: 600,
                textTransform: 'uppercase'
              }}>
                <TranslatedContent code='fairSalary' defaultValue='Fair Salary' />
              </Typography>
              <StdSelect value='' sx={{ display: 'none', backgroundColor: '#FFFFFF', height: 25, width: 200, border: 0 }}></StdSelect>
            </Box>
            <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
            <StdLineGraph chartName='fairSalary' legend sx={{ height: 300, my: 5 }} />
          </Box>
        </Box> */}
        <PlayerComparablesIndicators player={props.player} />
        <PlayerComparablesTopPlayers player={props.player} />
      </Stack>
    </Box>
  )
}

export default PlayerComparables