import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import statisticsService from '../../services/statisticsService'
import { transitionWrapper } from '../../services/transitionWrapper'
import { addClusterStatistics, setStatistics } from '../../slices/statisticsSlice'
import StdGaugeChart from '../charts/stdGaugeChart'
import StdGaugeSpectrumChart from '../charts/stdGaugeSpectrumChart'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import StdGridCarousel from '../layout/stdGridCarousel'
import TranslatedContent from '../translations/translatedContent'

const PlayerComparablesIndicators = (props) => {

  const userToken = useSelector(state => state.user.token)
  const userCurrency = useSelector(state => state.user.currency)
  const statistics = useSelector(state => state.statistics)

  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()

  const [superStats, setSuperStats] = useState({})
  const [clubStats, setClubStats] = useState({})
  const [clusterStats, setClusterStats] = useState({})

  const [btnState, setBtnState] = useState('performance')

  useEffect(() => {
    if (props.player?.currentClubCluster && statistics.clusters[props.player.currentClubCluster]) {
      setClusterStats(statistics.clusters[props.player.currentClubCluster])
    }
    if (statistics.superStatsLoaded) {
      setSuperStats(statistics.statistics)
    }
  }, [props.player, statistics])

  useEffect(() => {
    if (props.player?.currentLeagueId) {
      startTransition(() => {
        transitionWrapper(statisticsService.getClubStats(userToken, dispatch, props.player.currentClubId),
          res => {
            if (res.status === 200) {
              setClubStats(res.data.data)
            }
          })
      })
    }
    if (props.player?.currentClubCluster && !statistics.clusters[props.player.currentClubCluster]) {
      statisticsService.getClusterStatsById(userToken, dispatch, props.player.currentClubCluster)
        .then(res => {
          if (res.status === 200) {
            dispatch(addClusterStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    if (!statistics.superStatsLoaded) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setStatistics(res.data.data))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.player])

  const handleBtnClick = (e) => {
    switch (e.target.id) {
      case 'performanceBtn':
        setBtnState('performance')
        break
      case 'valueBtn':
        setBtnState('value')
        break
      case 'salaryBtn':
        setBtnState('salary')
        break
      default:
        console.warn('[PlayerComparablesIndicators] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ width: '100%', height: { xs: 350, md: 700, lg: 350 }, my: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '2rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }}>
          <TranslatedContent code='indicators' defaultValue='Indicators' />
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button id='performanceBtn' variant='stdTab' className={btnState === 'performance' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='performance' defaultValue='Performance' />
          </Button>
          <Button id='valueBtn' variant='stdTab' className={btnState === 'value' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='value' defaultValue='Value' />
          </Button>
          <Button id='salaryBtn' variant='stdTab' className={btnState === 'salary' ? 'stdActive' : ''} onClick={handleBtnClick} sx={{ mx: 2 }}>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%' }} />
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: '100%', mt: 2, mb: -2 }}>
        <StdSelect
          value={btnState}
          onChange={(e) => setBtnState(e.target.value)}>
          <MenuItem id='performance' key='performance' value='performance'>
            <TranslatedContent code='performance' defaultValue='Performance' />
          </MenuItem>
          <MenuItem id='value' key='value' value='value'>
            <TranslatedContent code='value' defaultValue='Value' />
          </MenuItem>
          <MenuItem id='salary' key='salary' value='salary'>
            <TranslatedContent code='salary' defaultValue='Salary' />
          </MenuItem>
        </StdSelect>
      </Box>
      <StdGridCarousel
        sx={{ display: btnState === 'performance' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } },

        }}>
        <StdGaugeChart
          loading={isPending}
          chartName='performancePlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.performance,
              cluster: clusterStats.avgPerformance,
              club: clubStats.avgPerformance
            },
            {
              main: 100 - props.player?.performance
            }
          ]}
          fullScale={100}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='effectivenessPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.effectiveness,
              cluster: clusterStats.avgEffectiveness,
              club: clubStats.avgEffectiveness
            },
            {
              main: 100 - props.player?.effectiveness
            }
          ]}
          fullScale={100}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='uniquenessPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.latestUniqueness,
              cluster: clusterStats.avgUniqueness,
              club: clubStats.avgUniqueness
            },
            {
              main: 100 - props.player?.latestUniqueness
            }
          ]}
          fullScale={100}
          uom='' />
        <StdGaugeChart
          loading={isPending}
          chartName='volatilityPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.latestMicroVolatility,
              cluster: clusterStats.avgVolatility,
              club: clubStats.avgVolatility
            },
            {
              main: 100 - props.player?.latestMicroVolatility
            }
          ]}
          fullScale={100}
          uom='%' />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'value' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } },

        }}>
        <StdGaugeSpectrumChart
          loading={isPending}
          chartName='priceSpectrumPlayer'
          data={[
            {
              residualBookValue: props.player?.residualBookValue,
              potentialPrice: props.player?.potentialPrice,
              expectedPrice: props.player?.expectedPrice
            }
          ]}
          values={['residualBookValue', 'potentialPrice', 'expectedPrice']}
          fullScale={Math.max(props.player?.residualBookValue, props.player?.potentialPrice, props.player?.expectedPrice)}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='residualBookValuePlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.residualBookValue,
              cluster: clusterStats.avgResidualBookValue,
              club: clubStats.avgResidualBookValue
            },
            {
              main: superStats.maxResidualBookValue - props.player?.residualBookValue
            }
          ]}
          fullScale={superStats.maxResidualBookValue}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='potentialPricePlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.potentialPrice,
              cluster: clusterStats.avgPotentialPrice,
              club: clubStats.avgPotentialPrice
            },
            {
              main: superStats.maxPotentialPrice - props.player?.potentialPrice
            }
          ]}
          fullScale={superStats.maxPotentialPrice}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='expectedPricePlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.expectedPrice,
              cluster: clusterStats.avgExpectedPrice,
              club: clubStats.avgExpectedPrice
            },
            {
              main: superStats.maxExpectedPrice - props.player?.expectedPrice
            }
          ]}
          fullScale={superStats.maxExpectedPrice}
          uom={getCurrencySymbol(userCurrency)} />
      </StdGridCarousel>
      <StdGridCarousel
        sx={{ display: btnState === 'salary' ? 'flex' : 'none', width: '100%', height: { xs: 350, md: 600, lg: 300 } }}
        GridItemProps={{
          xs: 12,
          md: 6,
          lg: 3
        }}
        CarouselProps={{
          height: 300,
          animation: 'slide',
          autoPlay: false,
          cycleNavigation: false,
          navButtonsAlwaysVisible: true,
          indicatorIconButtonProps: { sx: { color: '#494949' } },
          activeIndicatorIconButtonProps: { sx: { color: '#FFFFFF' } },

        }}>
        <StdGaugeSpectrumChart
          loading={isPending}
          chartName='salarySpectrumPlayer'
          data={[
            {
              salaryNet: props.player?.salaryNet,
              salaryOptimal: props.player?.salaryOptimal,
              salaryPotential: props.player?.salaryPotential
            }
          ]}
          values={['salaryNet', 'salaryOptimal', 'salaryPotential']}
          fullScale={Math.max(props.player?.salaryNet, props.player?.salaryOptimal, props.player?.salaryPotential)}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryNetPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.salaryNet,
              cluster: clusterStats.avgSalaryNet,
              club: clubStats.avgSalaryNet
            },
            {
              main: superStats.maxSalaryNet - props.player?.salaryNet
            }
          ]}
          fullScale={superStats.maxSalaryNet}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryOptimalPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.salaryOptimal,
              cluster: clusterStats.avgSalaryOptimal,
              club: clubStats.avgSalaryOptimal
            },
            {
              main: superStats.maxSalaryOptimal - props.player?.salaryOptimal
            }
          ]}
          fullScale={superStats.maxSalaryOptimal}
          uom={getCurrencySymbol(userCurrency)} />
        <StdGaugeChart
          loading={isPending}
          chartName='salaryPotentialPlayerIndicator'
          type='player'
          data={[
            {
              main: props.player?.salaryPotential,
              cluster: clusterStats.avgSalaryPotential,
              club: clubStats.avgSalaryPotential
            },
            {
              main: superStats.maxSalaryPotential - props.player?.salaryPotential
            }
          ]}
          fullScale={superStats.maxSalaryPotential}
          uom={getCurrencySymbol(userCurrency)} />
      </StdGridCarousel>
    </Box>
  )
}

export default PlayerComparablesIndicators