import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const StdMenuItem = (props) => {

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <ListItem disablePadding sx={{ display: 'block', p: .5, ...props.sx }}>
      <ListItemButton
        onClick={(e) => {
          e.preventDefault()
          navigate(props.pathname)
          props.setDrawerOpen(false)
        }}
        sx={{
          backgroundColor: location.pathname.startsWith(props.pathname) ? '#04a8a3' : 'inherit',
          borderRadius: 3,
          minHeight: 48,
          justifyContent: props.drawerOpen ? 'initial' : 'center',
          px: 2.5,
          '&:hover': {
            backgroundColor: location.pathname.startsWith(props.pathname) ? '#04a8a3' : '#e2e2e2'
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.drawerOpen ? 3 : 'auto',
            justifyContent: 'center',
            color: location.pathname.startsWith(props.pathname) ? 'white' : '#04a8a3',
          }}>
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500, color: location.pathname.startsWith(props.pathname) ? 'white' : 'inherit' }}>
              {props.text}
            </Typography>
          }
          sx={{
            opacity: props.drawerOpen ? 1 : 0
          }} />
      </ListItemButton>
    </ListItem>
  )
}

export default StdMenuItem