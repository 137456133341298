import CloseIcon from '@mui/icons-material/Close'
import NorthIcon from '@mui/icons-material/North'
import RemoveIcon from '@mui/icons-material/Remove'
import SouthIcon from '@mui/icons-material/South'
import { Box, Tooltip, Typography } from '@mui/material'
import { Fragment } from 'react'
import { ReactComponent as CheckIcon } from '../../../icons/check.svg'
import UserCurrency from '../../currency/userCurrency'
import TranslatedContent from '../../translations/translatedContent'

export const dateFormatter = ({ value }) => {
  let parsedDate = new Date(value)
  return parsedDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', ' / ')
}

export const dateGridFormatter = ({ cell }) => {
  let parsedDate = new Date(cell.getValue())
  return parsedDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', ' / ')
}

const animatedHeaderSx = {
  '&:hover': {
    animation: 'text-shift 1.5s ease-in-out .2s forwards'
  },
  '@keyframes text-shift': {
    '0%': {},
    '100%': {
      transform: 'translateX(calc(var(--px-shift) * -1))'
    }
  }
}

const onAnimatedHeaderMouseEnter = (e) => {
  if (e.currentTarget.offsetWidth > e.currentTarget.parentElement.offsetWidth) {
    e.currentTarget.style.setProperty('--px-shift', (e.currentTarget.offsetWidth - e.currentTarget.parentElement.offsetWidth) + 'px')
  } else {
    e.currentTarget.style.setProperty('--px-shift', '0px')
  }
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return "" // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

// export const translatedHeaderRenderer = ({ colDef }) => (
//   <Tooltip title={<TranslatedContent code={colDef.headerName} description />} placement='bottom'>
//     <Box sx={animatedHeaderSx}
//       onMouseEnter={onAnimatedHeaderMouseEnter}>
//       <span style={{ fontWeight: 600 }}><TranslatedContent code={colDef.headerName} /></span>
//     </Box>
//   </Tooltip>
// )

export const translatedHeaderRenderer = ({ column }) => (
  <Tooltip title={<TranslatedContent code={camelize(column.columnDef.header)} description />} placement='bottom'>
    <Box component='span' sx={{ whiteSpace: 'nowrap' }}><TranslatedContent code={camelize(column.columnDef.header)} /></Box>
  </Tooltip>
)

// export const currencyHeaderRenderer = ({ colDef }) => (
//   <Tooltip title={<TranslatedContent code={colDef.headerName} description />} placement='bottom'>
//     <Box sx={animatedHeaderSx}
//       onMouseEnter={onAnimatedHeaderMouseEnter}>
//       <span style={{ fontWeight: 600 }}><TranslatedContent code={colDef.headerName} /></span>&nbsp;<span style={{ fontWeight: 600, opacity: .65 }}><UserCurrency /></span>
//     </Box>
//   </Tooltip>
// )

export const currencyHeaderRenderer = ({ column }) => (
  <Tooltip title={<TranslatedContent code={camelize(column.columnDef.header)} description />} placement='bottom'>
    <Box>
      <Fragment>
        <span style={{ fontWeight: 600 }}><TranslatedContent code={camelize(column.columnDef.header)} /></span>&nbsp;<span style={{ fontWeight: 600, opacity: .65 }}><UserCurrency /></span>
      </Fragment>
    </Box>
  </Tooltip>
)

export const booleanRenderer = ({ value }) => (
  value ? <CheckIcon fill='#F4D35E' width={16} /> : <CloseIcon sx={{ color: '#FFFFFF', opacity: .65, width: 14, height: 14 }} />
)

export const countryGridRenderer = ({ row, cell }) => (
  <Tooltip title={row.original.nationality} placement='right'>
    <img src={'/img/common/flags/code/' + (cell.getValue() ? cell.getValue() : 'OLY') + '.gif'} height='18px' width='27px' />
  </Tooltip>
)

export const tierRenderer = (numOfTiers) => {
  return ({ value }) => (
    <Fragment>
      <span>{value && value.toLocaleString('en-US')}</span>&nbsp;<span style={{ opacity: .65 }}>{' / ' + (numOfTiers && numOfTiers.toLocaleString('en-US'))}</span>
    </Fragment>
  )
}

export const tierGridRenderer = (numOfTiers) => {
  return ({ cell }) => (
    <Fragment>
      <span>{cell.getValue() && cell.getValue().toLocaleString('en-US')}</span>&nbsp;<span style={{ opacity: .65 }}>{' / ' + (numOfTiers && numOfTiers.toLocaleString('en-US'))}</span>
    </Fragment>
  )
}

export const integerValueWithPercRenderer = (perc) => {
  return ({ value }) => (
    <Fragment>
      <span>{value && value.toLocaleString('en-US')}</span>&nbsp;<span style={{ opacity: .65 }}>{'(' + (perc && perc.toLocaleString('en-US', { minimumFractionDigits: 2 })) + '%)'}</span>
    </Fragment>
  )
}

export const decimalValueWithPercRenderer = (perc) => {
  return ({ value }) => {
    if (value === null || value === undefined)
      return null
    let formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 2 })
    let decimalPointIdx = formattedValue.lastIndexOf('.')
    var intPart = formattedValue.toString().substring(0, decimalPointIdx)
    var decPart = formattedValue.toString().substring(decimalPointIdx)
    return (
      <Fragment>
        <span>{intPart}</span><span style={{ opacity: .65 }}>{decPart}</span>&nbsp;<span style={{ opacity: .65 }}>{'(' + (perc && perc.toLocaleString('en-US', { minimumFractionDigits: 2 })) + '%)'}</span>
      </Fragment>
    )
  }
}

const getPositionShortcut = (position) => {
  if (!position)
    return null
  let positionShortcut
  switch (position) {
    case 'Goalkeeper':
      positionShortcut = 'GK'
      break
    case 'Defender':
      positionShortcut = 'DF'
      break
    case 'Midfielder':
      positionShortcut = 'MD'
      break
    case 'Attacker':
      positionShortcut = 'AA'
      break
    default:
      positionShortcut = 'UN'
      break
  }
  return positionShortcut
}

const getRoleShortcut = (role) => {
  if (!role)
    return null
  let roleShortcut
  switch (role) {
    case 'Goalkeeper':
      roleShortcut = 'GK'
      break
    case 'Central Goalkeeper':
      roleShortcut = 'CGK'
      break
    case 'Defender':
      roleShortcut = 'DF'
      break
    case 'Midfielder':
      roleShortcut = 'MD'
      break
    case 'Attacker':
      roleShortcut = 'AA'
      break
    case 'Central Attacker':
      roleShortcut = 'CA'
      break
    case 'Central Defender':
      roleShortcut = 'CD'
      break
    case 'Central Midfielder':
      roleShortcut = 'CM'
      break
    case 'Centre Attacking Midfielder':
      roleShortcut = 'CAM'
      break
    case 'Centre Forward':
      roleShortcut = 'CF'
      break
    case 'Defensive Midfielder':
      roleShortcut = 'DM'
      break
    case 'Left Attacking Midfielder':
      roleShortcut = 'LAM'
      break
    case 'Left Back':
      roleShortcut = 'LB'
      break
    case 'Left Centre Back':
      roleShortcut = 'LCB'
      break
    case 'Left Midfielder':
      roleShortcut = 'LM'
      break
    case 'Left Wing Back':
      roleShortcut = 'LWB'
      break
    case 'Left Winger':
      roleShortcut = 'LW'
      break
    case 'Right Attacking Midfielder':
      roleShortcut = 'RAM'
      break
    case 'Right Back':
      roleShortcut = 'RB'
      break
    case 'Right Centre Back':
      roleShortcut = 'RCB'
      break
    case 'Right Midfielder':
      roleShortcut = 'RM'
      break
    case 'Right Wing Back':
      roleShortcut = 'RWB'
      break
    case 'Right Winger':
      roleShortcut = 'RW'
      break
    case 'Second Striker':
      roleShortcut = 'SS'
      break
    default:
      roleShortcut = 'UN'
      break
  }
  return roleShortcut
}

export const positionGridRenderer = ({ cell }) => {
  let positionShortcut = getPositionShortcut(cell.getValue())
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip title={cell.getValue()} placement='right'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFF', borderRadius: 1, width: 27, height: 27 }}>
          <Typography sx={{ color: '#15222B', fontSize: '1.4rem', fontWeight: 600 }}>
            {positionShortcut}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}


export const roleGridRenderer = ({ cell }) => {
  let roleShortcut = getRoleShortcut(cell.getValue())
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip title={cell.getValue()} placement='right'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFFFF', borderRadius: 1, width: 27, height: 27 }}>
          <Typography sx={{ color: '#15222B', fontSize: '1.2rem', fontWeight: 600 }}>
            {roleShortcut}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}

export const positionFilterRenderer = (props) => {
  let positionShortcut = getPositionShortcut(props.value)
  return (
    <Tooltip title={props.value} placement='top'>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: props.selected ? '#A1CBC2' : '#8F8F8F', borderRadius: 1, width: 27, height: 27 }}>
        <Typography sx={{ color: '#15222B', fontSize: '1.4rem', fontWeight: 600 }}>
          {positionShortcut}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export const yearsUomRenderer = ({ value }) => (
  <Fragment>
    <span>{decimalNumberRenderer({ value: value })}</span>&nbsp;<span style={{ opacity: .65 }}>{'Y'}</span>
  </Fragment>
)

export const monthsUomRenderer = ({ value }) => (
  <Fragment>
    <span>{decimalNumberRenderer({ value: value })}</span>&nbsp;<span style={{ opacity: .65 }}>{'M'}</span>
  </Fragment>
)

export const yearsToYearsMonthsUomRenderer = ({ value }) => {
  const years = Math.floor(value)
  const months = Math.floor((value % 1) * 12)
  return (
    <Fragment>
      <span>{years}</span>&nbsp;<span style={{ opacity: .65 }}>{'Y'}</span>&nbsp;<span>{months}</span>&nbsp;<span style={{ opacity: .65 }}>{'M'}</span>
    </Fragment>
  )
}

export const monthsToYearsMonthsUomRenderer = ({ value }) => {
  const years = Math.floor(value / 12)
  const months = Math.floor(value % 12)
  return (
    <Fragment>
      <span>{years}</span>&nbsp;<span style={{ opacity: .65 }}>{'Y'}</span>&nbsp;<span>{months}</span>&nbsp;<span style={{ opacity: .65 }}>{'M'}</span>
    </Fragment>
  )
}

export const currencyUomRenderer = ({ value }) => (
  <Fragment>
    <span>{decimalNumberRenderer({ value: parseFloat(value).toFixed(2) })}</span>&nbsp;<span style={{ opacity: .65 }}>{value !== undefined && value !== '' && <UserCurrency />}</span>
  </Fragment>
)

export const currencyUomWithPercRenderer = (perc) => {
  return ({ value }) => {
    if (value === null || value === undefined)
      return null
    return (
      <Fragment>
        <span>{decimalNumberRenderer({ value: value })}</span>&nbsp;<span style={{ opacity: .65 }}>{value !== undefined && value !== '' && <UserCurrency />}</span>&nbsp;<span style={{ opacity: .65 }}>{'(' + (perc && perc.toLocaleString('en-US', { minimumFractionDigits: 2 })) + '%)'}</span>
      </Fragment>
    )
  }
}

export const integerNumberRenderer = ({ value }) => (
  <Fragment>
    {value && value.toLocaleString('en-US')}
  </Fragment>
)

export const integerNumberGridRenderer = ({ cell }) => integerNumberRenderer({ value: cell.getValue() })

export const percNumberRenderer = ({ value }) => (
  <Fragment>
    {decimalNumberRenderer({ value: value })}&nbsp;{value ? '%' : ''}
  </Fragment>
)

export const percNumberGridRenderer = ({ cell }) => percNumberRenderer({ value: cell.getValue() })

export const decimalNumberRenderer = ({ value }) => {
  if (value === null || value === undefined)
    return null
  let formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 2 })
  let decimalPointIdx = formattedValue.lastIndexOf('.')
  var intPart = formattedValue.toString().substring(0, decimalPointIdx)
  var decPart = formattedValue.toString().substring(decimalPointIdx)
  return (
    <Fragment>
      <span>{intPart}</span><span style={{ opacity: .65 }}>{decPart}</span>
    </Fragment>
  )
}

export const decimalNumberGridRenderer = ({ cell }) => {
  if (cell.getValue() === null || cell.getValue() === undefined)
    return null
  let formattedValue = cell.getValue().toLocaleString('en-US', { minimumFractionDigits: 2 })
  let decimalPointIdx = formattedValue.lastIndexOf('.')
  var intPart = formattedValue.toString().substring(0, decimalPointIdx)
  var decPart = formattedValue.toString().substring(decimalPointIdx)
  return (
    <Fragment>
      <span>{intPart}</span><span style={{ opacity: .65 }}>{decPart}</span>
    </Fragment>
  )
}

export const decimalNumberWithVariationRenderer = (variation) => {
  let trendIcon
  if (variation > 0) {
    trendIcon = <NorthIcon sx={{ color: 'lightgreen', height: 16 }} />
  } else if (variation < 0) {
    trendIcon = <SouthIcon sx={{ color: 'red', height: 16 }} />
  } else {
    trendIcon = <RemoveIcon sx={{ color: '#FFFFFF', opacity: .65, height: 16 }} />
  }
  return ({ value }) => (
    <Fragment>
      <Box component='span'>{decimalNumberRenderer({ value: value })}</Box>
      &nbsp;
      <Box component='span' sx={{ verticalAlign: 'inherit' }}>
        <Tooltip title={variation + '%'} placement='right'>
          {trendIcon}
        </Tooltip>
      </Box>
    </Fragment>
  )
}