import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import TranslatedContent from '../../components/translations/translatedContent'

const DashboardVerticalGrid = (props) => {

  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  return (
    <TableContainer sx={{ ...props.sx }}>
      <Table size='small' sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          {data.map((row, idx) => {
            let rowValue = row.valueFormatter ? row.valueFormatter({ value: row.value }) : row.value
            return (
              <TableRow key={idx}>
                <TableCell sx={{ px: 0 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={<TranslatedContent code={row.name ? row.name : row.field} description />}>
                      <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <TranslatedContent code={row.name ? row.name : row.field} />
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell sx={{ color: rowValue === 'COMING SOON' ? '#D3D3D3' : '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {row.valueRenderer && rowValue !== '-' ? row.valueRenderer({ value: rowValue }) : rowValue}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DashboardVerticalGrid