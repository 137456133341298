import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setFiltersNationalities } from '../../../slices/filtersSlice'
import TranslatedContent from '../../translations/translatedContent'

const StdPassportFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const nationalities = useSelector(state => state.filters.nationalities)
  const dispatch = useDispatch()

  const [selectedNationalities, setSelectedNationalities] = useState([])

  useEffect(() => {
    if (!Array.isArray(nationalities) || nationalities.length === 0) {
      filtersService.getNationalities(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setFiltersNationalities(res.data.data.nationalities))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedNationalities([])
    }
  }))

  const handleSelectNationality = (e, nationalityCode) => {
    e.preventDefault()
    let tmpNationalities
    if (selectedNationalities.includes(nationalityCode)) {
      tmpNationalities = selectedNationalities.filter(sn => sn !== nationalityCode)
    } else {
      tmpNationalities = [...selectedNationalities, nationalityCode]
    }
    setSelectedNationalities(tmpNationalities)
    props.onChange(prepareFilter(tmpNationalities))
  }

  const handleDeselectAll = (e) => {
    e.preventDefault()
    setSelectedNationalities([])
    props.onChange(prepareFilter([]))
  }

  const prepareFilter = (nationalities) => {
    let filterContent = {}
    filterContent[props.filterName] = nationalities
    let filter = {
      filterName: 'nationality',
      filterValues: nationalities.join(', '),
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='passport' defaultValue='Passport' />
        </Typography>
      </Box>
      <Box sx={{
        height: 350,
        overflow: 'auto',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'sticky',
          bottom: 0,
          height: 50,
          marginTop: '-50px',
          pointerEvents: 'none',
          width: '100%',
          backgroundImage: 'linear-gradient(to bottom, transparent, #DFDFDF)',
        }
      }}>
        <Grid container sx={{ pb: 3 }}>
          {nationalities.map((nationality, idx) => (
            <Grid item xs={6} key={idx}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: selectedNationalities.includes(nationality.code) ? '#04A8A3' : 'transparent',
                borderRadius: 25,
                cursor: 'pointer',
                my: .2,
                marginRight: 2,
                py: .3
              }}
                onClick={(e) => handleSelectNationality(e, nationality.code)}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={'/img/common/flags/code/' + (nationality.code ? nationality.code : 'OLY') + '.gif'} alt='' height='18px' width='27px' style={{ marginLeft: 8 }} />
                  <Typography
                    sx={{
                      fontSize: '1.4rem',
                      ml: 1,
                      color: selectedNationalities.includes(nationality.code) ? '#FFFFFF' : '#000000'
                    }}>
                    {nationality.name}
                  </Typography>
                </Box>
                <Box sx={{ display: selectedNationalities.includes(nationality.code) ? 'flex' : 'none', mr: 1 }}>
                  <CloseIcon sx={{ color: '#FFFFFF', width: 15, height: 15 }} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: selectedNationalities.length > 0 ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleDeselectAll}>
          <TranslatedContent code='deselectAll' defaultValue='Deselect All' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdPassportFilter