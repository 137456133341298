import { api_versioned, getQueryParams } from './commonService'

const createWatchlist = (token, dispatch, data) => {
  return api_versioned(token, dispatch).post('/watchlists', data)
}

const updateWatchlist = (token, dispatch, id, data) => {
  return api_versioned(token, dispatch).post(`/watchlists/${id}`, { _method: 'PUT', ...data })
}

const deleteWatchlist = (token, dispatch, id) => {
  return api_versioned(token, dispatch).post(`/watchlists/${id}`, { _method: 'DELETE' })
}

const getWatchlist = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/watchlists/${id}`)
}

const getWatchlists = (token, dispatch, filter) => {
  return api_versioned(token, dispatch).get('/watchlists', { params: getQueryParams(undefined, filter, undefined) })
}

const getGenericDynamicWatchlists = (token, dispatch, filter) => {
  return api_versioned(token, dispatch).get('/recommendedwatchlists/none', { params: getQueryParams(undefined, filter, undefined) })
}

const getEntityDynamicWatchlists = (token, dispatch, entityType, entityId) => {
  return api_versioned(token, dispatch).get(`/recommendedwatchlists/${entityType}/${entityId}`)
}

const getAttachedDynamicWatchlists = (token, dispatch) => {
  return api_versioned(token, dispatch).get('/myrecommendedwatchlists')
}

const attachDynamicWatchlist = (token, dispatch, watchlistId, entityType, entityId) => {
  return api_versioned(token, dispatch).post('/recommendedwatchlist/attach', { recommended_id: watchlistId, entity: entityType, entity_id: entityId })
}

const detachDynamicWatchlist = (token, dispatch, watchlistId, entityType, entityId) => {
  return api_versioned(token, dispatch).post('/recommendedwatchlist/detach', { recommended_id: watchlistId, entity: entityType, entity_id: entityId })
}

const watchlistsService = {
  createWatchlist,
  updateWatchlist,
  deleteWatchlist,
  getWatchlist,
  getWatchlists,
  getGenericDynamicWatchlists,
  getEntityDynamicWatchlists,
  getAttachedDynamicWatchlists,
  attachDynamicWatchlist,
  detachDynamicWatchlist
}

export default watchlistsService