import CloseIcon from '@mui/icons-material/Close'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as BurgerIcon } from '../../icons/burger.svg'
import { ReactComponent as ClubsIcon } from '../../icons/clubs.svg'
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg'
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg'
import { ReactComponent as FaqsIcon } from '../../icons/faqs.svg'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import { ReactComponent as LeaguesIcon } from '../../icons/leagues.svg'
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg'
import { ReactComponent as PlayersIcon } from '../../icons/players.svg'
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg'
import { ReactComponent as WatchlistsIcon } from '../../icons/watchlists.svg'
import authService from '../../services/authService'
import { openInNewTab } from '../../services/commonService'
import { clearSession } from '../../slices/userSlice'
import TranslatedContent from '../translations/translatedContent'
import StdMenuItem from './stdMenuItem'

const StdMenuContent = (props) => {

  const user = useSelector(state => state.user)

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  useEffect(() => {
    props.setOpen(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const toggleDrawerOpen = () => {
    setOpen(!open)
    props.setOpen(!open)
  }

  const handleLogout = (e) => {
    e.preventDefault()
    authService.logout(user.token, dispatch)
      .then(() => dispatch(clearSession()))
      .catch(err => console.error(err))
      .finally(() => navigate('/login', { replace: true }))
  }

  return (
    <Box id='stdMenu'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow: open ? 'auto' : 'hidden'
      }}>
      <List sx={{ pt: open ? 0 : 1, pr: open ? .5 : 0 }}>
        <ListItem sx={{ display: { xs: 'block', md: 'none' }, paddingLeft: open ? 3 : 'auto' }}>
          <IconButton onClick={toggleDrawerOpen}>
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: open ? 'block' : 'none' }}>
          <ListItemButton
            sx={{
              borderRadius: 50,
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                  {user.name}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ display: open ? 'block' : 'none', mb: 1 }} />
        <StdMenuItem
          pathname='/dashboard'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<DashboardIcon fill={location.pathname.startsWith('/dashboard') ? '#FFFFFF' : '#04a8a3'} />}
          text={<TranslatedContent code='summaryPanel' defaultValue='Summary Panel' />}
        />
        <Divider textAlign='left' sx={{ mt: '1rem', display: open ? 'flex' : 'none' }}>
          <TranslatedContent code='search' defaultValue='Search' />
        </Divider>
        <Divider sx={{ my: 1, pt: '.7rem', display: true ? 'none' : 'block' }} />
        <StdMenuItem
          pathname='/leagues'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<LeaguesIcon fill={location.pathname.startsWith('/leagues') ? '#FFFFFF' : '#04a8a3'} height={30} width={30} />}
          text={<TranslatedContent code='leagues' defaultValue='Leagues' />}
        />
        <StdMenuItem
          pathname='/clubs'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<ClubsIcon fill={location.pathname.startsWith('/clubs') ? '#FFFFFF' : '#04A8A3'} height={30} width={30} />}
          text={<TranslatedContent code='clubs' defaultValue='Clubs' />}
        />
        <StdMenuItem
          pathname='/players'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<PlayersIcon fill={location.pathname.startsWith('/players') ? '#FFFFFF' : '#04A8A3'} height={30} width={30} />}
          text={<TranslatedContent code='players' defaultValue='Players' />}
        />
        <StdMenuItem
          pathname='/watchlists'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<WatchlistsIcon fill={location.pathname.startsWith('/watchlists') ? '#FFFFFF' : '#04A8A3'} height={30} width={30} />}
          text={<TranslatedContent code='watchlists' defaultValue='Watchlists' />}
        />
        <Divider textAlign='left' sx={{ mt: '1rem', display: open ? 'flex' : 'none' }}>
          <TranslatedContent code='settings' defaultValue='Settings' />
        </Divider>
        <StdMenuItem
          pathname='/settings'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<SettingsIcon fill={location.pathname.startsWith('/settings') ? '#FFFFFF' : '#04A8A3'} height={30} width={30} />}
          text={<TranslatedContent code='customize' defaultValue='Customize' />}
        />
        <Divider textAlign='left' sx={{ mt: '1rem', display: open ? 'flex' : 'none' }}>
          <TranslatedContent code='support' defaultValue='Support' />
        </Divider>
        <Divider sx={{ my: 1, pt: '.7rem', display: true ? 'none' : 'block' }} />
        <StdMenuItem
          pathname='/faqs'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<FaqsIcon fill={location.pathname.startsWith('/faqs') ? 'white' : '#04a8a3'} height={30} width={30} />}
          text={<TranslatedContent code='faq' defaultValue='FAQ' />}
        />
        <StdMenuItem
          pathname='/contacts'
          drawerOpen={open}
          setDrawerOpen={setOpen}
          icon={<ContactsIcon fill={location.pathname.startsWith('/contacts') ? 'white' : '#04a8a3'} height={30} width={30} />}
          text={<TranslatedContent code='contacts' defaultValue='Contacts' />}
        />
      </List>
      <List>
        <ListItem sx={{ display: { xs: 'none', md: 'block' }, paddingLeft: open ? 3 : 'auto' }}>
          <IconButton
            onClick={toggleDrawerOpen}>
            {open ? <CloseIcon /> : <BurgerIcon stroke='#707070' />}
          </IconButton>
        </ListItem>
        <ListItem sx={{ backgroundColor: '#8E8E8E', paddingLeft: open ? 3 : 'auto' }}>
          <IconButton onClick={handleLogout}>
            <LogoutIcon width={27} height={25} />
          </IconButton>
        </ListItem>
        <ListItem sx={{ backgroundColor: '#E2E2E2', alignItems: 'flex-start', paddingLeft: open ? 3 : 'auto' }}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontSize: '1.2rem' }}>
              &#169; Copyright 2023
            </Typography>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
              Standard Football Srl
            </Typography>
            <Box>
              <IconButton sx={{ color: '#000000', mx: .5 }} onClick={() => openInNewTab('https://www.linkedin.com/company/71228649')}><LinkedInIcon sx={{ width: 20, height: 20 }} /></IconButton>
              <IconButton sx={{ color: '#000000', mx: .5 }} onClick={() => openInNewTab('https://twitter.com/StdFootball')}><TwitterIcon sx={{ width: 20, height: 20 }} /></IconButton>
              <IconButton sx={{ color: '#000000', mx: .5 }} onClick={() => openInNewTab('https://www.instagram.com/stdfootball/')}><InstagramIcon sx={{ width: 20, height: 20 }} /></IconButton>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  )
}

export default StdMenuContent