import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import TranslatedContent from '../translations/translatedContent'

const StdGaugeChart = (props) => {

  am5.addLicense('AM5C387037067')

  const chartId = crypto.randomUUID()

  const [firstValue, setFirstValue] = useState()
  const [secondValue, setSecondValue] = useState()
  const [thirdValue, setThirdValue] = useState()
  const [fullScale, setFullScale] = useState()

  const convertYearsToYearsMonths = (value) => {
    let years = Math.floor(value)
    let months = Math.floor((value % 1) * 12)
    return [years, months]
  }

  useEffect(() => {
    if (props.yearsMonths) {
      let convertedFirstValue = convertYearsToYearsMonths(props.data[0].main)
      setFirstValue(convertedFirstValue[0] + ' Y ' + convertedFirstValue[1] + ' M')
      let convertedSecondValue = convertYearsToYearsMonths(props.data[0].cluster)
      setSecondValue(convertedSecondValue[0] + ' Y ' + convertedSecondValue[1] + ' M')
      let convertedThirdValue = convertYearsToYearsMonths(props.type === 'player' ? props.data[0].club : props.data[0].league)
      setThirdValue(convertedThirdValue[0] + ' Y ' + convertedThirdValue[1] + ' M')
      let convertedFullScale = convertYearsToYearsMonths(props.fullScale)
      setFullScale(convertedFullScale[0] + ' Y ' + convertedFullScale[1] + ' M')
    } else {
      setFirstValue((props.round ? Math.round(props.data[0].main) : props.data[0].main?.toLocaleString('en-US')) + ' ' + props.uom)
      setSecondValue((props.round ? Math.round(props.data[0].cluster) : props.data[0].cluster?.toLocaleString('en-US')) + ' ' + props.uom)
      setThirdValue(((props.type === 'player' ? (props.round ? Math.round(props.data[0].club) : props.data[0].club?.toLocaleString('en-US')) : (props.round ? Math.round(props.data[0].league) : props.data[0].league?.toLocaleString('en-US'))) + ' ' + props.uom))
      setFullScale(props.fullScale + ' ' + props.uom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.yearsMonths, props.data, props.fullScale])

  useLayoutEffect(() => {

    let root = am5.Root.new(chartId)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ])

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        dy: -40
      })
    )

    let mainSerie = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'main',
        startAngle: 180,
        endAngle: 360,
        radius: am5.percent(75),
        innerRadius: am5.percent(55),
      })
    )

    let mainColorSet = am5.ColorSet.new(root, {
      colors: ['#04A8A3', '#31495A']
    })

    mainSerie.set('colors', mainColorSet)
    mainSerie.slices.template.setAll({
      toggleKey: 'none',
      tooltipText: ''
    })
    mainSerie.slices.template.states.create('hover', { scale: 1 })
    mainSerie.ticks.template.setAll({
      forceHidden: true
    })
    mainSerie.labels.template.setAll({
      forceHidden: true
    })

    let clusterSerie = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'cluster',
        startAngle: 180,
        endAngle: ((360 - 180) * props.data[0].cluster / props.fullScale) + 180,
        radius: am5.percent(80),
        innerRadius: am5.percent(85)
      })
    )

    let clusterColorSet = am5.ColorSet.new(root, {
      colors: ['#F4D35E', '#15222B']
    })

    clusterSerie.set('colors', clusterColorSet)
    clusterSerie.slices.template.setAll({
      toggleKey: 'none',
      tooltipText: '{value}'
    })
    clusterSerie.slices.template.states.create('hover', { scale: 1 })
    clusterSerie.ticks.template.setAll({
      forceHidden: true
    })
    clusterSerie.labels.template.setAll({
      forceHidden: true
    })

    let thirdSerie

    if (props.type === 'club') {
      thirdSerie = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'league',
          startAngle: 180,
          endAngle: ((360 - 180) * props.data[0].league / props.fullScale) + 180,
          radius: am5.percent(90),
          innerRadius: am5.percent(95)
        })
      )
    } else if (props.type === 'player') {
      thirdSerie = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: 'club',
          startAngle: 180,
          endAngle: ((360 - 180) * props.data[0].club / props.fullScale) + 180,
          radius: am5.percent(90),
          innerRadius: am5.percent(95)
        })
      )
    }

    if (props.type === 'club' || props.type === 'player') {
      let thirdColorSet = am5.ColorSet.new(root, {
        colors: ['#A1CBC2', '#15222B']
      })
      thirdSerie.set('colors', thirdColorSet)
      thirdSerie.slices.template.setAll({
        toggleKey: 'none',
        tooltipText: '{value}'
      })
      thirdSerie.slices.template.states.create('hover', { scale: 1 })
      thirdSerie.ticks.template.setAll({
        forceHidden: true
      })
      thirdSerie.labels.template.setAll({
        forceHidden: true
      })
    }

    let serieLabel = ''
    if (props.yearsMonths) {
      let yearsMonths = convertYearsToYearsMonths(props.data[0].main)
      serieLabel = '[bold fontSize: 30px]' + yearsMonths[0] + '[fontSize: 30px] Y [bold fontSize: 30px]' + yearsMonths[1] + '[fontSize: 30px] M'
    } else {
      serieLabel = '[bold fontSize: 30px]' + Math.round(props.data[0].main).toLocaleString('en-US') + ' [fontSize: 30px]' + props.uom
    }

    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: 'center',
        centerY: am5.percent(75),
        centerX: am5.p50,
        fontFamily: 'Barlow, Roboto, sans-serif',
        text: serieLabel
      })
    )

    mainSerie.data.setAll(props.data)
    clusterSerie.data.setAll(props.data)
    if (props.type === 'club' || props.type === 'player')
      thirdSerie.data.setAll(props.data)

    mainSerie.appear(1000, 100)
    clusterSerie.appear(1000, 100)
    if (props.type === 'club' || props.type === 'player')
      thirdSerie.appear(1000, 100)

    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.yearsMonths])

  return (
    <Fragment>
      <Box sx={{ display: props.loading ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        <CircularProgress />
      </Box>
      <Box sx={{ display: props.loading ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box id={chartId}
          sx={{
            width: 350,
            height: '100%'
          }} />
        <Box sx={{ display: 'flex', position: 'relative', top: -115, width: 200, justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.2rem' }}>0</Typography>
          <Typography sx={{ color: '#FFFFFF', fontSize: '1.2rem' }}>{props.fullScale?.toLocaleString('en-US')}</Typography>
        </Box>
        <Tooltip title={<TranslatedContent code={props.chartName} description />} placement='bottom'>
          <Typography sx={{ position: 'relative', color: '#FFFFFF', fontSize: '1.4rem', lineHeight: 1.3, textAlign: 'center', whiteSpace: 'break-spaces', fontWeight: 600, zIndex: 5, mt: -14 }}>
            <TranslatedContent code={props.chartName} />
          </Typography>
        </Tooltip>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', top: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#04A8A3', width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
              <Typography sx={{ color: '#04A8A3', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                <TranslatedContent code={props.type} />:&nbsp;{firstValue}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#F4D35E', width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
              <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                <TranslatedContent code={props.type === 'league' ? 'groupAvg' : 'cluster'} />:&nbsp;{secondValue}
              </Typography>
            </Box>
            <Box sx={{ display: props.type === 'league' ? 'none' : 'flex', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#A1CBC2', width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
              <Typography sx={{ color: '#A1CBC2', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                <TranslatedContent code={props.type === 'player' ? 'club' : 'league'} />:&nbsp;{thirdValue}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#31495A', width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
              <Typography sx={{ color: '#6A7C89', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                <TranslatedContent code='absoluteMax' defaultValue='Absolute Max' />:&nbsp;{fullScale}
              </Typography>
            </Box>
            <Box sx={{ display: props.type === 'league' ? 'flex' : 'none', visibility: 'hidden', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#31495A', width: 8, height: 8, borderRadius: '50%', mr: 1 }} />
              <Typography sx={{ color: '#31495A', fontSize: '1.4rem', fontWeight: 600, textTransform: 'uppercase' }}>
                placeholder
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default StdGaugeChart