import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {

  const userAuth = useSelector(state => state.user.auth)
  const location = useLocation()

  if (location.pathname.startsWith('/login')) {
    return (userAuth ? <Navigate to='/dashboard' replace /> : children)
  } else {
    return (userAuth ? children : <Navigate to='/login' state={{ from: location }} replace />)
  }
}

export default ProtectedRoute