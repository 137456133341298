import { api_versioned, getQueryParams } from './commonService'

const getPlayers = (token, dispatch, page, filter, sort) => {
  return api_versioned(token, dispatch).get('/players', { params: getQueryParams(page, filter, sort) })
}

const getPlayer = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/players/${id}`)
}

const getSimilarPlayers = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/player/${id}/similars`)
}

const simulateTransfer = (token, dispatch, data) => {
  return api_versioned(token, dispatch).post('player/transfer/simulate', { data: data })
}

const playersService = {
  getPlayers,
  getPlayer,
  getSimilarPlayers,
  simulateTransfer
}

export default playersService