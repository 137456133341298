import { IconButton, InputAdornment, TextField } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import './stdNameSearchField.css'

const StdNameSearchField = forwardRef((props, ref) => {

  const [searchText, setSearchText] = useState({ value: '', disableListener: true })

  useEffect(() => {
    const timeout = setTimeout(() => { if (!searchText.disableListener) props.onSearch(searchText.value) }, 250)
    return () => clearTimeout(timeout)
  }, [searchText.value])

  useImperativeHandle(ref, () => ({
    resetNameFilter() {
      setSearchText({ value: '', disableListener: true })
    }
  }))

  return (
    <TextField
      className={props.className}
      value={searchText.value}
      placeholder='Search'
      onChange={(e) => setSearchText({ value: e.target.value, disableListener: false })}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              sx={{
                cursor: 'default',
                backgroundColor: '#223441',
                outline: '6px solid #15222B',
                width: 50,
                height: 50,
                '&:hover': {
                  backgroundColor: '#223441'
                }
              }}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          color: '#FFFFFF',
        }
      }}
      sx={{
        height: 50,
        border: 0,
        '& :hover': {
          border: 0
        },
        '& .MuiInputBase-root': {
          fontSize: '1.4rem',
          height: 50,
          backgroundColor: '#31495A',
          border: 0,
          paddingRight: 0
        },
        '& fieldset': {
          border: '0'
        },
        '& fieldset:hover': {
          border: '0'
        },
        input: {
          '&::placeholder': {
            fontSize: '1.4rem',
            opacity: .7
          }
        }
      }}
    />
  )
})

export default StdNameSearchField