import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

const StdNumericField = (props) => {

  const [value, setValue] = useState(props.value)
  const [minValue, setMinValue] = useState(props.minValue)
  const [maxValue, setMaxValue] = useState(props.maxValue)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    setMinValue(props.minValue)
  }, [props.minValue])

  useEffect(() => {
    setMaxValue(props.maxValue)
  }, [props.maxValue])

  const handleChange = (e) => {
    let newValue = e.target.value
    if (newValue < minValue) newValue = minValue
    if (newValue > maxValue) newValue = maxValue
    setValue(newValue)
    props.onChange(newValue)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: .5 }}>
      <Typography sx={{ color: '#707070', fontSize: '1.2rem', fontWeight: 500, mx: 1, minWidth: 30 }}>{props.label}</Typography>
      <IconButton sx={{
        height: '25px',
        width: '25px',
        mx: .5,
        backgroundColor: value === minValue ? '#707070' : '#31495A',
        cursor: value === minValue ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: value === minValue ? '#707070' : '#31495A',
        }
      }}
        onClick={() => {
          if (value !== minValue) {
            let newValue = parseInt(value) - props.step
            if (newValue < minValue) {
              newValue = minValue
            }
            handleChange({
              target: {
                value: newValue
              }
            })
            setValue(newValue)
          }
        }}>
        <KeyboardArrowLeftIcon sx={{ color: '#FFFFFF' }} />
      </IconButton>
      <TextField
        variant='standard'
        className='stdFilterField'
        size='small'
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          mx: .5
        }}
        InputProps={{
          inputComponent: props.inputComponent,
        }}
        inputProps={{
          sx: {
            color: '#04A8A3',
            textAlign: 'center',
            fontWeight: 600
          }
        }} />
      <IconButton sx={{
        height: '25px',
        width: '25px',
        mx: .5,
        backgroundColor: value === maxValue ? '#707070' : '#31495A',
        cursor: value === maxValue ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: value === maxValue ? '#707070' : '#31495A',
        }
      }}
        onClick={() => {
          if (value !== maxValue) {
            let newValue = parseInt(value) + props.step
            if (newValue > maxValue) {
              newValue = maxValue
            }
            handleChange({
              target: {
                value: newValue
              }
            })
            setValue(newValue)
          }
        }}>
        <KeyboardArrowRightIcon sx={{ color: '#FFFFFF' }} />
      </IconButton>
    </Box>
  )
}

export default StdNumericField