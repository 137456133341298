import { Box, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../services/filtersService'
import statisticsService from '../../services/statisticsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import { booleanRenderer, currencyUomRenderer, decimalValueWithPercRenderer, tierRenderer } from '../data/formatters/dataFormatters'
import StdVerticalGrid from '../data/stdVerticalGrid'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import ClubLogo from './clubLogo'
import ClubLogoSmall from './clubLogoSmall'

const ClubProfile = (props) => {

  const clubsTotal = useSelector(state => state.clubs.total)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const [numOfClubs, setNumOfClubs] = useState(clubsTotal)
  const [numOfClubClusters, setNumOfClubClusters] = useState(clubClusters.length)
  const [clubSalaryPercDeviation, setClubSalaryPercDeviation] = useState(0)

  const emptyGeneralData = [
    { field: 'shortName', name: 'clubName', value: '-' },
    { field: 'city', name: 'clubCity', value: '-' },
    { field: 'stadiumOwner', name: 'clubStadiumOwner', value: '-', valueRenderer: booleanRenderer },
    { field: 'leagueName', name: 'clubLeague', value: '-' },
    { field: 'cluster', name: 'clubTier', value: '-', valueRenderer: tierRenderer(numOfClubClusters) },
    // { field: 'lastPosition', value: 'COMING SOON' },
    // { field: 'leagueWinner', value: '-' },
    // { field: 'qualifForCups', value: '-' },
    // { field: 'relegation', value: '-' },
    { field: 'numOfPlayers', name: 'clubNumOfRegisteredPlayers', value: '-' }
  ]

  const emptyValueData = [
    { field: 'potentialPrice', name: 'clubFairValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankByValue', name: 'clubRankByValue', value: '-', valueRenderer: tierRenderer(numOfClubs) },
    { field: 'portfolioCost', name: 'clubAcquisitionCost', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankByAcquisition', name: 'clubRankByAcquisition', value: '-', valueRenderer: tierRenderer(numOfClubs) },
    { field: 'residualBookValue', name: 'clubBookValue', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'residualValue', name: 'clubResidualValue', value: '-', valueRenderer: currencyUomRenderer }
  ]

  const emptySalaryData = [
    { field: 'maxSalaryGross', name: 'clubGrossSalarySum', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'rankBySalary', name: 'clubRankBySalary', value: '-', valueRenderer: tierRenderer(numOfClubs) },
    { field: 'maxSalaryNet', name: 'clubNetSalarySum', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryNetFair', name: 'clubFairSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'salaryAbsoluteDeviation', name: 'clubFairSalaryAbsDeviation', value: '-', valueRenderer: decimalValueWithPercRenderer(clubSalaryPercDeviation) }
  ]

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [displayState, setDisplayState] = useState('general')
  const [generalData, setGeneralData] = useState(emptyGeneralData)
  const [valueData, setValueData] = useState(emptyValueData)
  const [salaryData, setSalaryData] = useState(emptySalaryData)

  useEffect(() => {
    setNumOfClubs(clubsTotal)
  }, [clubsTotal])

  useEffect(() => {
    setNumOfClubClusters(clubClusters.length)
  }, [clubClusters])

  useEffect(() => {
    if (props.club) {
      setClubSalaryPercDeviation(props.club.salaryPercDeviation)
    }
  }, [props.club])

  useEffect(() => {
    if (clubsTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    if (props.club) {
      if (Object.keys(props.club).length > 0) {
        let tmpGeneralData = []
        emptyGeneralData.forEach((row) => {
          tmpGeneralData.push({ field: row.field, name: row.name, value: row.value === 'COMING SOON' ? row.value : props.club[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setGeneralData(tmpGeneralData)
        let tmpValueData = []
        emptyValueData.forEach((row) => {
          tmpValueData.push({ field: row.field, name: row.name, value: props.club[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setValueData(tmpValueData)
        let tmpSalaryData = []
        emptySalaryData.forEach((row) => {
          tmpSalaryData.push({ field: row.field, name: row.name, value: props.club[row.field], valueRenderer: row.valueRenderer, valueFormatter: row.valueFormatter })
        })
        setSalaryData(tmpSalaryData)
      } else {
        setGeneralData(emptyGeneralData)
        setValueData(emptyValueData)
        setSalaryData(emptySalaryData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.club, numOfClubs, numOfClubClusters, clubSalaryPercDeviation])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          backgroundColor: '#31495A',
          borderRadius: '20px',
          p: { xs: 2, md: 4 },
          //height: 250,
          transition: 'height .2s ease-in-out',
          boxShadow: '0 15px 3px rgba(0, 0, 0, .3)'
        }}>
        <Box sx={{ flex: .1, height: '100%', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mx: { xs: 0, md: 2 } }}>
          <ClubLogo clubId={props.club?.clubId} show={props.club?.clubId && props.club?.clubId !== ''} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 'auto', alignItems: 'center', height: '100%' }}>
          <Box sx={{ display: { xs: 'flex', xl: 'none' }, justifyContent: 'space-between', alignItems: 'center', height: 55, width: '100%', px: 2, my: 1 }}>
            <Box sx={{ display: { xs: 'none', md: 'block', xl: 'none' } }} />
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <ClubLogoSmall clubId={props.club?.clubId} show={props.club?.clubId && props.club?.clubId !== ''} />
            </Box>
            <StdSelect
              value={displayState}
              onChange={(e) => setDisplayState(e.target.value)}
              sx={{
                display: { xs: 'inherit', xl: 'none' },
                width: { xs: 200, sm: 250 }
              }}>
              <MenuItem id='general' key='general' value='general'>
                <TranslatedContent code='general' defaultValue='General' />
              </MenuItem>
              <MenuItem id='value' key='value' value='value'>
                <TranslatedContent code='value' defaultValue='Value' />
              </MenuItem>
              <MenuItem id='salary' key='salary' value='salary'>
                <TranslatedContent code='salary' defaultValue='Salary' />
              </MenuItem>
            </StdSelect>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ display: { xs: displayState === 'general' ? 'block' : 'none', xl: 'block' }, flex: 1, mx: 2, height: '100%' }}>
              <StdVerticalGrid title={<TranslatedContent code='general' defaultValue='General' />} data={generalData} />
            </Box>
            <Box sx={{ display: { xs: displayState === 'value' ? 'block' : 'none', xl: 'block' }, flex: 1, mx: 2, height: '100%' }}>
              <StdVerticalGrid title={<TranslatedContent code='value' defaultValue='Value' />} data={valueData} />
            </Box>
            <Box sx={{ display: { xs: displayState === 'salary' ? 'block' : 'none', xl: 'block' }, flex: 1, mx: 2, height: '100%' }}>
              <StdVerticalGrid title={<TranslatedContent code='salary' defaultValue='Salary' />} data={salaryData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ClubProfile