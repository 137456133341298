import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  superStatsLoaded: false,
  statistics: null,
  leagueClusterStatsLoaded: false,
  leagueClusters: {},
  clusterStatsLoaded: false,
  clusters: {},
  leagues: {}
}

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action) {
      state.statistics = action.payload
      state.superStatsLoaded = true
    },
    addLeagueClustersStatistics(state, action) {
      state.leagueClusters[action.payload.entityId] = action.payload
    },
    addClusterStatistics(state, action) {
      state.clusters[action.payload.entityId] = action.payload
    },
    setClusterStatsLoaded(state, action) {
      state.clusterStatsLoaded = action.payload
    },
    addLeagueStatistics(state, action) {
      state.leagues[action.payload.entityId] = action.payload
    }
  },
})

export const { setStatistics, addLeagueClustersStatistics, addClusterStatistics, setClusterStatsLoaded, addLeagueStatistics } = statisticsSlice.actions