import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, ThemeProvider } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { useEffect, useState } from 'react'
import mrtTheme from '../../style/mrtTheme'
import './stdDataGrid.css'

const StdDataGrid = (props) => {

  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  useEffect(() => {
    setColumns(props.columns)
  }, [props.columns])

  return (
    <Box className='stdMaterialReactTable' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <ThemeProvider theme={mrtTheme}>
          <MaterialReactTable
            initialState={{
              density: 'compact',
              pagination: {
                pageIndex: props.page,
                pageSize: props.pageSize
              },
              columnPinning: { left: ['count', 'position', 'watchlist', 'edit', 'name', 'shortName'] },
              columnVisibility: props.columnVisibility
            }}
            state={{
              sorting: props.sorting ? props.sorting : [],
              pagination: {
                pageIndex: props.page,
                pageSize: props.pageSize
              },
              columnVisibility: props.columnVisibility,
              columnOrder: props.columnOrder
            }}
            columns={columns}
            data={data}
            getRowId={props.rowId}
            enableStickyHeader={false}
            enablePinning
            enableColumnActions
            enableFilters={false}
            enableHiding={false}
            enableTopToolbar={false}
            enableBottomToolbar={props.enableBottomToolbar}
            enableMultiSort={false}
            manualSorting
            enablePagination={props.enablePagination}
            manualPagination={props.manualPagination}
            onPaginationChange={props.onPaginationChange}
            onSortingChange={props.onSortingChange}
            enableColumnOrdering
            enableColumnDragging
            onColumnOrderChange={props.onColumnOrderChange}
            muiTablePaginationProps={{
              rowsPerPageOptions: [],
              showFirstButton: true,
              showLastButton: true,
            }}
            rowCount={props.rowCount}
            icons={{
              ArrowDownwardIcon: KeyboardArrowDownIcon
            }}
            muiTableProps={{
              sx: {
                tableLayout: 'fixed',
              }
            }}
            muiTableHeadCellProps={{
              sx: {
                verticalAlign: 'middle',
                borderBottom: '4px solid #15222B',
                '& .Mui-TableHeadCell-Content-Actions': {
                  ml: .5
                }
              }
            }}
            muiTableBodyCellProps={{
              sx: {
                px: 2,
                py: 0,
                borderBottom: 0,
                height: 31
              }
            }}
          />
        </ThemeProvider>
      </Box>
    </Box>
  )
}

export default StdDataGrid