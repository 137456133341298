import { Box, Button, Grid, Typography } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersService from '../../../services/filtersService'
import { setClubClusters } from '../../../slices/clubsSlice'
import TranslatedContent from '../../translations/translatedContent'

const StdClusterFilter = forwardRef((props, ref) => {

  const userToken = useSelector(state => state.user.token)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const dispatch = useDispatch()

  const [clusters, setClusters] = useState([])
  const [selectedClusters, setSelectedClusters] = useState([])

  useEffect(() => {
    setClusters(clubClusters)
  }, [clubClusters])

  useEffect(() => {
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedClusters([])
    }
  }))

  const handleSelectCluster = (e, cluster) => {
    e.preventDefault()
    let tmpClusters
    if (selectedClusters.includes(cluster)) {
      tmpClusters = selectedClusters.filter(sc => sc !== cluster)
    } else {
      tmpClusters = [...selectedClusters, cluster]
    }
    setSelectedClusters(tmpClusters)
    props.onChange(prepareFilter(tmpClusters))
  }

  const handleDeselectAll = (e) => {
    e.preventDefault()
    setSelectedClusters([])
    props.onChange(prepareFilter([]))
  }

  const prepareFilter = (clusters) => {
    let sortedClusters = clusters.sort((a, b) => a - b)
    let filterContent = {}
    filterContent[props.filterName] = sortedClusters
    let filter = {
      filterName: 'clusters',
      filterValues: sortedClusters.join(', '),
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='cluster' defaultValue='Cluster' />
        </Typography>
      </Box>
      <Grid container>
        {clusters.map((cluster, idx) => {
          return (
            <Grid item xs={1.71} sm={1.2} key={idx}>
              <Box sx={{
                backgroundColor: selectedClusters.includes(cluster) ? '#04A8A3' : '#707070',
                borderRadius: 25,
                cursor: 'pointer',
                textAlign: 'center',
                width: 30,
                height: 30,
                m: .2,
                py: .3
              }}
                onClick={(e) => handleSelectCluster(e, cluster)}>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    color: '#FFFFFF',
                    fontWeight: 500
                  }}>
                  {cluster}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ display: selectedClusters.length > 0 ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleDeselectAll}>
          <TranslatedContent code='deselectAll' defaultValue='Deselect All' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdClusterFilter