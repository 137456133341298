import { CssBaseline, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import AuthGuard from './components/guards/authGuard'
import ProtectedRoute from './components/guards/protectedRoute'
import Layout from './components/layout/layout'
import './index.css'
import ClubPage from './pages/clubs/club'
import ClubsPage from './pages/clubs/clubs'
import ContactsPage from './pages/contacts'
import DashboardPage from './pages/dashboard/dashboard'
import FaqsPage from './pages/faqs'
import LeaguePage from './pages/leagues/league'
import LeaguesPage from './pages/leagues/leagues'
import LoginPage from './pages/login'
import PlayerPage from './pages/players/player'
import PlayersPage from './pages/players/players'
import SettingsPage from './pages/settings'
import WatchlistsPage from './pages/watchlists/watchlists'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import theme from './style/theme'

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Provider store={store}>
        <Router>
          <AuthGuard>
            <Layout>
              <Routes>
                <Route index element={<Navigate to='/login' />} />
                <Route path='/login' element={<ProtectedRoute><LoginPage /></ProtectedRoute>} />
                <Route path='/dashboard' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
                <Route path='/leagues' element={<ProtectedRoute><LeaguesPage /></ProtectedRoute>} />
                <Route path='/leagues/:id' element={<ProtectedRoute><LeaguePage /></ProtectedRoute>} />
                <Route path='/clubs' element={<ProtectedRoute><ClubsPage /></ProtectedRoute>} />
                <Route path='/clubs/:id' element={<ProtectedRoute><ClubPage /></ProtectedRoute>} />
                <Route path='/players' element={<ProtectedRoute><PlayersPage /></ProtectedRoute>} />
                <Route path='/players/:id' element={<ProtectedRoute><PlayerPage /></ProtectedRoute>} />
                <Route path='/watchlists' element={<ProtectedRoute><WatchlistsPage /></ProtectedRoute>} />
                <Route path='/settings' element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
                <Route path='/faqs' element={<ProtectedRoute><FaqsPage /></ProtectedRoute>} />
                <Route path='/contacts' element={<ProtectedRoute><ContactsPage /></ProtectedRoute>} />
                <Route path='*' element={
                  <ProtectedRoute>
                    <Typography sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: '7rem' }}>
                      404 Not Found
                    </Typography>
                  </ProtectedRoute>
                } />
              </Routes>
            </Layout>
          </AuthGuard>
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
