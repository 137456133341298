import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import StdSelect from '../../components/form/stdSelect'
import TranslatedContent from '../../components/translations/translatedContent'
import PersonalWatchlists from './personalWatchlists'
import RecommendedWatchlists from './recommendedWatchlists'

const TabSelect = (props) => (
  <StdSelect
    value={props.tabState}
    onChange={(e) => props.setTabState(e.target.value)}
    renderValue={(value) => <TranslatedContent code={value} />}
    sx={{
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontSize: '1.2rem',
      height: 25,
      width: 150,
      border: '1px solid #04A8A3',
      borderRadius: '25px',
      '&:hover': {
        color: '#04A8A3'
      }
    }}>
    <MenuItem id='personal' key='personal' value='personal'>
      <TranslatedContent code='personal' defaultValue='Personal' />
    </MenuItem>
    <MenuItem id='recommended' key='recommended' value='recommended'>
      <TranslatedContent code='recommended' defaultValue='Recommended' />
    </MenuItem>
  </StdSelect>
)

const WatchlistsPage = () => {

  const [tabState, setTabState] = useState('personal')

  const handleTabClick = (e) => {
    switch (e.target.id) {
      case 'personalBtn':
        setTabState('personal')
        break
      case 'recommendedBtn':
        setTabState('recommended')
        break
      default:
        console.warn('[Watchlists] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ textAlign: 'left', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '3rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            mr: 2
          }}>
            <TranslatedContent code='watchlists' defaultValue='Watchlists' />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: { xs: 'none', xl: 'flex' }, flexDirection: 'row' }}>
            <Button id='personalBtn' variant='stdTab' className={tabState === 'personal' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='personal' defaultValue='Personal' />
            </Button>
            <Button id='recommendedBtn' variant='stdTab' className={tabState === 'recommended' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='recommended' defaultValue='Recommended' />
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block', xl: 'none' } }}>
            <TabSelect tabState={tabState} setTabState={setTabState} />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, width: '100%', justifyContent: 'center', mb: 2 }}>
        <TabSelect tabState={tabState} setTabState={setTabState} />
      </Box>
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <Box sx={{ display: tabState === 'personal' ? 'block' : 'none' }}>
          <PersonalWatchlists />
        </Box>
        <Box sx={{ display: tabState === 'recommended' ? 'block' : 'none' }}>
          <RecommendedWatchlists />
        </Box>
      </Box>
    </Box>
  )
}

export default WatchlistsPage