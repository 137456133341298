import { api_versioned, getQueryParams } from './commonService'

const getClubs = (token, dispatch, page, filter, sort) => {
  return api_versioned(token, dispatch).get('/clubs', { params: getQueryParams(page, filter, sort) })
}

const getClub = (token, dispatch, id) => {
  return api_versioned(token, dispatch).get(`/clubs/${id}`)
}

const clubsService = {
  getClubs,
  getClub
}

export default clubsService