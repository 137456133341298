import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import TranslatedContent from '../components/translations/translatedContent'
import { openInNewTab } from '../services/commonService'

const ContactsPage = () => {

  return (
    <Box sx={{ textAlign: 'left', height: '100%', width: '100%' }}>
      <Typography sx={{ fontSize: '3rem', color: 'white', textTransform: 'uppercase', fontWeight: '600' }} >
        <TranslatedContent code='contacts' defaultValue='Contacts' />
      </Typography>
      <Divider sx={{ backgroundColor: 'white', height: 2, my: 1 }} />
      <Box sx={{ display: 'flex', height: { xs: 'fit-content', md: '90%' }, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Grid container spacing={{ xs: 2, sm: 10 }} sx={{ width: '100%', ml: { xxl: 20 }, mr: { xxl: 40 } }}>
          <Grid item xs={12} lg={6} sx={{ pb: { xs: 8, sm: 0 } }}>
            <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 4 }}>
              <TranslatedContent code='aboutUs' defaultValue='About Us' />
            </Typography>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 500 }}>
              We concretely support a sustainable and inclusive football sport, helping to preserve the well-being of athletes, managers, and clubs.<br />
              Our work is based on a scientific approach, unique management skills, passion, and innovation. We believe in the equal dignity of each person, recognizing diversity as a key value for human development. We seek to ensure that our actions are based on integrity, responsibility, and transparency.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2} sx={{ textAlign: { xs: 'left', lg: 'right' } }}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 2 }}>
                  <TranslatedContent code='website' defaultValue='Website' />
                </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 500, cursor: 'pointer' }} onClick={() => openInNewTab('https://stdfootball.com')}>
                  https://stdfootball.com
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 2 }}>
                  <TranslatedContent code='telephone' defaultValue='Telephone' />
                </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 500 }}>
                  +39 06 8414537
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 2 }}>
                  <TranslatedContent code='email' defaultValue='E-Mail' />
                </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 500, cursor: 'pointer' }} onClick={() => window.open('mailto:info@stdfootball.com', 'mail')}>
                  info@stdfootball.com
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 2 }}>
                  <TranslatedContent code='headquarter' defaultValue='Headquarter' />
                </Typography>
                <Typography sx={{ color: '#FFFFFF', fontSize: '1.6rem', fontWeight: 500 }}>
                  Via Vitorchiano, 123<br />
                  00189 Roma RM
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <Typography sx={{ color: '#A1CBC2', fontSize: '3rem', fontWeight: 600, textTransform: 'uppercase', mb: 2 }}>Connect</Typography>
                <IconButton sx={{ color: '#FFFFFF', mx: .5 }} onClick={() => openInNewTab('https://www.linkedin.com/company/71228649')}><LinkedInIcon sx={{ height: 36, width: 36 }} /></IconButton>
                <IconButton sx={{ color: '#FFFFFF', mx: .5 }} onClick={() => openInNewTab('https://twitter.com/StdFootball')}><TwitterIcon sx={{ height: 36, width: 36 }} /></IconButton>
                <IconButton sx={{ color: '#FFFFFF', mx: .5 }} onClick={() => openInNewTab('https://www.instagram.com/stdfootball/')}><InstagramIcon sx={{ height: 36, width: 36 }} /></IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContactsPage