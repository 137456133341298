import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  translationsLoaded: false,
  lang: '',
  dictionary: []
}

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setLang(state, action) {
      state.lang = action.payload
    },
    setDictionary(state, action) {
      state.dictionary = action.payload
      state.translationsLoaded = true
    },
  },
})

export const { setLang, setDictionary } = translationsSlice.actions