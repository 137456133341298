import { Box, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { checkImage } from '../../services/commonService'

const LeagueLogo = (props) => {

  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {

    if (props.leagueId) {
      let computedImgSrc = '/img/leagues/logos/' + props.leagueId + '.png'

      checkImage(computedImgSrc)
        .then(res => {
          if (res.status === 'ok') {
            setImgSrc(computedImgSrc)
          }
        })
        .catch(err => console.error(err))
    }
  }, [props.leagueId])

  return (
    <Paper
      sx={{
        cursor: 'pointer',
        borderRadius: 5,
        height: 150,
        width: 90,
        maxWidth: 90,
        minWidth: 90,
        display: props.show ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx
      }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={imgSrc} alt='' width='75%' />
      </Box>
    </Paper>
  )
}

export default LeagueLogo