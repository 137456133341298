import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { forwardRef, useState } from 'react'
import FontAwesomeSvgIcon from '../faSvgIcon'

const StdPasswordInput = forwardRef(function StdPasswordInput(props, ref) {

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const { ...other } = props
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'
            sx={{
              color: 'rgba(0, 0, 0, 1)',
              mx: '1rem',
              transform: 'scale(.8)'
            }}>
            <FontAwesomeSvgIcon icon={faLock} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <FontAwesomeSvgIcon icon={faEyeSlash} /> : <FontAwesomeSvgIcon icon={faEye} />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...other}
      ref={ref}
    />
  )
})

export default StdPasswordInput