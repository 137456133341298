import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark'
import * as am5xy from '@amcharts/amcharts5/xy'
import { Box } from '@mui/material'
import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const StdBarChart = (props) => {

  am5.addLicense('AM5C387037067')

  const navigate = useNavigate()

  useLayoutEffect(() => {

    let root = am5.Root.new(props.chartName)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ])

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none'
    }))

    chart.zoomOutButton.set('forceHidden', true)

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: props.categoryField,
      renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 30 })
    }))
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: '1.4rem',
      fontWeight: 400,
      fontFamily: 'Barlow, Roboto, sans-serif',
      cursorOverStyle: 'pointer'
    })
    yRenderer.labels.template.events.on('click', function (e) {
      if (props.type === 'club') {
        navigate('/clubs/' + e.target.dataItem.dataContext.clubId)
      } else if (props.type === 'player') {
        navigate('/players/' + e.target.dataItem.dataContext.playerId)
      }
    })
    yRenderer.labels.template.setup = (target) => {
      target.set('background', am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 0
      }))
    }

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: 0,
      extraMax: 0.1,
      renderer: am5xy.AxisRendererX.new(root, {})
    }))
    xAxis.children.push(am5.Label.new(root, {
      text: props.uom,
      fontSize: '1.4rem',
      fontWeight: 600,
      fontFamily: 'Barlow, Roboto, sans-serif',
      x: am5.percent(50),
      centerX:am5.p50
    }))

    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: props.name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: props.valueField,
      categoryYField: props.categoryField,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'left',
        labelText: '{valueX} ' + props.uom
      })
    }))
    series.columns.template.setAll({
      height: 13
    })
    series.columns.template.adapters.add('fill', (_fill, target) => {
      return series.columns.indexOf(target) === series.columns.length - 1 ? am5.color('#04A8A3') : am5.color('#F4D35E')
    })
    series.columns.template.adapters.add('stroke', (_stroke, target) => {
      return series.columns.indexOf(target) === series.columns.length - 1 ? am5.color('#04A8A3') : am5.color('#F4D35E')
    })

    yAxis.data.setAll(props.data)
    series.data.setAll(props.data)

    let cursor = am5xy.XYCursor.new(root, {
      behavior: 'none',
      xAxis: xAxis,
      yAxis: yAxis
    })

    cursor.lineX.set('visible', false)
    cursor.lineY.set('visible', false)

    chart.set('cursor', cursor)

    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.valueField])

  return (
    <Box id={props.chartName}
      sx={{
        width: '100%',
        height: '100%',
        ...props.sx
      }}></Box>
  )
}

export default StdBarChart