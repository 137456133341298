import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import TranslatedContent from '../translations/translatedContent'

const StdVerticalGrid = (props) => {

  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }}>
      <Box sx={{ display: { xs: 'none', xl: 'flex' }, justifyContent: 'center', height: 26, minWidth: 150, mb: 2 }}>
        <Typography sx={{
          display: { xs: 'none', xl: props.title ? 'inline-block' : 'none' },
          color: '#FFFFFF',
          fontSize: '1.6rem',
          fontWeight: 600,
          textTransform: 'uppercase',
          mb: 4
        }}>
          {props.title}
        </Typography>
      </Box>
      <TableContainer
        className={props.scrollable ? 'stdVerticalGrid' : ''}
        sx={{
          overflow: props.scrollable ? 'auto' : 'hidden',
          maxHeight: '100%',
          px: 2,
          // '&::after': {
          //   content: '""',
          //   display: props.scrollable ? 'block' : 'none',
          //   position: 'sticky',
          //   bottom: 0,
          //   height: 50,
          //   marginTop: '-10px',
          //   pointerEvents: 'none',
          //   width: '100%',
          //   backgroundImage: 'linear-gradient(to bottom, transparent, #31495A)',
          // }
        }}>
        <Table size='small' sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            {data.map((row, idx) => {
              let rowValue = row.valueFormatter ? row.valueFormatter({ value: row.value }) : row.value
              if (row.field !== 'contractType') {
                return (
                  <TableRow key={idx}>
                    <TableCell sx={{ px: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={<TranslatedContent code={row.name ? row.name : row.field} description />}>
                          <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            <TranslatedContent code={row.name ? row.name : row.field} />
                          </Typography>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ color: rowValue === 'COMING SOON' ? '#D3D3D3' : '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {row.valueRenderer && rowValue !== '-' ? row.valueRenderer({ value: rowValue }) : rowValue}
                    </TableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default StdVerticalGrid