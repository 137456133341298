import { Box, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Fragment, useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TranslatedContent from '../../components/translations/translatedContent'
import StdMiniWatchlistTable from '../../components/watchlists/stdMiniWatchlistTable'
import { ReactComponent as RemoveCircleIcon } from '../../icons/remove_circle.svg'
import { transitionWrapper } from '../../services/transitionWrapper'
import watchlistsService from '../../services/watchlistsService'
import { setRecommendedWatchlists } from '../../slices/watchlistsSlice'

const RecommendedWatchlists = () => {

  const userToken = useSelector(state => state.user.token)
  const recommendedWatchlists = useSelector(state => state.watchlists.recommended)
  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()

  const [watchlists, setWatchlists] = useState(recommendedWatchlists)

  useEffect(() => {
    setWatchlists(recommendedWatchlists)
  }, [recommendedWatchlists])

  useEffect(() => {
    refreshAttachedWatchlists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  const refreshAttachedWatchlists = () => {
    startTransition(() => {
      transitionWrapper(watchlistsService.getAttachedDynamicWatchlists(userToken, dispatch),
        res => {
          if (res.status === 200) {
            dispatch(setRecommendedWatchlists(res.data.data))
          }
        })
    })
  }

  const handleDetachWatchlistClicked = (watchlistId, entityType, entityId) => {
    watchlistsService.detachDynamicWatchlist(userToken, dispatch, watchlistId, entityType, entityId)
      .then(res => {
        if (res.status === 200) {
          refreshAttachedWatchlists()
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <Fragment>
      {isPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
        <Grid container sx={{ mt: 1, mb: 2 }}>
          {watchlists?.length === 0 ?
            <Typography sx={{ color: 'white' }}>
              <TranslatedContent code='noRecommendedWatchlists' defaultValue='No watchlists attached' />
            </Typography>
            :
            watchlists?.map((rw, idx) => (
              <Grid item key={idx} xs={12} lg={6} xl={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', border: { xs: 0, sm: '2px solid #787878' }, borderRadius: 5, height: 330, width: { xs: '100%', sm: 400 }, m: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, py: 3 }}>
                  <IconButton sx={{ position: 'absolute', top: -12, right: { xs: 'unset', sm: -12 }, left: { xs: -4, sm: 'unset' } }} onClick={() => handleDetachWatchlistClicked(rw.id, rw.entity, rw.entity_id)}>
                    <RemoveCircleIcon fill={'#707070'} width='50px' height='50px' />
                  </IconButton>
                  <Typography sx={{ color: '#A1CBC2', fontSize: '1.5rem', fontWeight: 600, textTransform: 'uppercase', mt: { xs: 1, sm: 0 } }}>
                    {rw.name}
                  </Typography>
                  <Divider sx={{ backgroundColor: '#FFFFFF', height: 2, width: '100%', my: 1 }} />
                  <StdMiniWatchlistTable watchlist={rw} />
                </Box>
              </Grid>
            ))
          }
        </Grid >}
    </Fragment>
  )
}

export default RecommendedWatchlists