import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import clubsService from '../../services/clubsService'
import leaguesService from '../../services/leaguesService'
import playersService from '../../services/playersService'
import TranslatedContent from '../translations/translatedContent'

const StdSearchPanel = (props) => {

  const userToken = useSelector(state => state.user.token)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')

  const [leaguesResult, setLeaguesResult] = useState([])
  const [clubsResult, setClubsResult] = useState([])
  const [playersResult, setPlayersResult] = useState([])

  useEffect(() => {

    const performSearch = (searchText) => {
      if (searchText.length > 0) {

        let page = { page: 1, per_page: 10 }
        let filter = { name: searchText }
        let sort = { field: 'potentialPrice', sort: 'desc' }

        playersService.getPlayers(userToken, dispatch, page, filter, sort)
          .then(res => setPlayersResult(res.data.data))
          .catch(err => console.error(err))

        clubsService.getClubs(userToken, dispatch, page, filter, sort)
          .then(res => setClubsResult(res.data.data))
          .catch(err => console.error(err))

        leaguesService.getLeagues(userToken, dispatch, page, filter, sort)
          .then(res => setLeaguesResult(res.data.data))
          .catch(err => console.error(err))
      } else {
        setLeaguesResult([])
        setClubsResult([])
        setPlayersResult([])
      }
    }

    const timeout = setTimeout(() => performSearch(searchText), 250)

    return () => clearTimeout(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, searchText])

  return (
    <Fragment>
      <IconButton sx={{ alignSelf: 'flex-end', mt: 10 }}
        onClick={(e) => {
          e.preventDefault()
          setSearchText('')
          props.close()
        }}>
        <CloseIcon sx={{ color: '#04A8A3', fontSize: '5rem' }} />
      </IconButton>
      <TextField
        variant='standard'
        className='stdSearchField'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder='Search'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon viewBox='-5 -5 40 40' />
            </InputAdornment>
          ),
          sx: {
            color: '#FFFFFF',
          }
        }}
        sx={{
          my: 5,
          input: {
            '&::placeholder': {
              opacity: 1
            }
          }
        }} />
      <Box sx={{ overflow: 'auto' }}>
        <Box sx={{ display: (playersResult.length > 0 ? 'flex' : 'none'), flexDirection: 'column', my: 1 }}>
          <Typography sx={{ color: '#FFFFFF', textTransform: 'uppercase', fontWeight: 600 }}>
            <TranslatedContent code='players' defaultValue='Players' />
          </Typography>
          <TableContainer>
            <Table size='small' sx={{ tableLayout: 'fixed' }}>
              <TableBody>
                {playersResult.map((row, idx) => (
                  <TableRow key={idx} sx={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: '#223441' } }}>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'left', border: 0 }}>
                      <Typography sx={{ fontSize: '1.5rem', color: '#f4d35e', fontWeight: '600', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/players/' + row.playerId)
                          setSearchText('')
                          props.close()
                        }}>
                        {row.name + ' (' + row.currentClubName + ')'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ display: (clubsResult.length > 0 ? 'flex' : 'none'), flexDirection: 'column', my: 1 }}>
          <Typography sx={{ color: '#FFFFFF', textTransform: 'uppercase', fontWeight: 600 }}>
            <TranslatedContent code='clubs' defaultValue='Clubs' />
          </Typography>
          <TableContainer>
            <Table size='small' sx={{ tableLayout: 'fixed' }}>
              <TableBody>
                {clubsResult.map((row, idx) => (
                  <TableRow key={idx} sx={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: '#223441' } }}>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'left', border: 0 }}>
                      <Typography sx={{ fontSize: '1.5rem', color: '#f4d35e', fontWeight: '600', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/clubs/' + row.clubId)
                          setSearchText('')
                          props.close()
                        }}>
                        {row.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ display: (leaguesResult.length > 0 ? 'flex' : 'none'), flexDirection: 'column', my: 1 }}>
          <Typography sx={{ color: '#FFFFFF', textTransform: 'uppercase', fontWeight: 600 }}>
            <TranslatedContent code='leagues' defaultValue='Leagues' />
          </Typography>
          <TableContainer>
            <Table size='small' sx={{ tableLayout: 'fixed' }}>
              <TableBody>
                {leaguesResult.map((row, idx) => (
                  <TableRow key={idx} sx={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: '#223441' } }}>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'left', border: 0 }}>
                      <Typography sx={{ fontSize: '1.5rem', color: '#f4d35e', fontWeight: '600', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/leagues/' + row.leagueId)
                          setSearchText('')
                          props.close()
                        }}>
                        {row.name + ' (' + row.country + ')'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  )
}

export default StdSearchPanel