import { Box, Button, MenuItem } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrencySymbol } from '../currency/userCurrency'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'

const ClubComparablesBarChartButtons = (props) => {

  const userCurrency = useSelector(state => state.user.currency)

  const [btnState, setBtnState] = useState('potentialPrice')

  useEffect(() => {
    switch (btnState) {
      case 'potentialPrice':
      case 'residualValue':
      case 'salaryNet':
        props.onChange({ param: btnState, uom: getCurrencySymbol(userCurrency) })
        break
      case 'shareOfForeignPlayers':
      case 'shareOfU23Players':
        props.onChange({ param: btnState, uom: '%' })
        break
      case 'avgAge':
        props.onChange({ param: btnState, uom: 'Y' })
        break
      case 'numOfNationalPlayers':
        props.onChange({ param: btnState, uom: '' })
        break
      default:
        console.warn('[ClubComparablesBarChartButtons] unhandled button state: ' + btnState)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrency, btnState])

  return (
    <Stack sx={{ mt: { xs: 0, md: 3 } }}>
      <Stack spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button id='potentialPriceBtn' variant='stdTab' className={btnState === 'potentialPrice' ? 'stdActive' : ''} onClick={() => setBtnState('potentialPrice')}>
          <TranslatedContent code='potentialPrice' defaultValue='Fair Value' />
        </Button>
        <Button id='avgAgeBtn' variant='stdTab' className={btnState === 'avgAge' ? 'stdActive' : ''} onClick={() => setBtnState('avgAge')}>
          <TranslatedContent code='avgAge' defaultValue='Avg Age' />
        </Button>
        <Button id='residualValueBtn' variant='stdTab' className={btnState === 'residualValue' ? 'stdActive' : ''} onClick={() => setBtnState('residualValue')}>
          <TranslatedContent code='residualValue' defaultValue='Residual Value' />
        </Button>
        <Button id='salaryNetBtn' variant='stdTab' className={btnState === 'salaryNet' ? 'stdActive' : ''} onClick={() => setBtnState('salaryNet')}>
          <TranslatedContent code='salaryNet' defaultValue='Net Salary' />
        </Button>
        <Button id='numOfNationalPlayersBtn' variant='stdTab' className={btnState === 'numOfNationalPlayers' ? 'stdActive' : ''} onClick={() => setBtnState('numOfNationalPlayers')}>
          <TranslatedContent code='numOfNationalPlayers' defaultValue='National Players' />
        </Button>
        <Button id='shareOfForeignPlayersBtn' variant='stdTab' className={btnState === 'shareOfForeignPlayers' ? 'stdActive' : ''} onClick={() => setBtnState('shareOfForeignPlayers')} sx={{ height: 40 }}>
          <TranslatedContent code='shareOfForeignPlayers' defaultValue='Share of Foreign Players' />
        </Button>
        <Button id='shareOfU23PlayersBtn' variant='stdTab' className={btnState === 'shareOfU23Players' ? 'stdActive' : ''} onClick={() => setBtnState('shareOfU23Players')} sx={{ height: 40 }}>
          <TranslatedContent code='shareOfU23Players' defaultValue='Share of U23 Players' />
        </Button>
      </Stack>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'center' }}>
        <StdSelect
          value={btnState}
          onChange={(e) => setBtnState(e.target.value)}
          sx={{
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            fontSize: '1.2rem',
            height: 25,
            width: 200,
            border: '1px solid #04A8A3',
            borderRadius: '25px',
            '&:hover': {
              color: '#04A8A3'
            }
          }}>
          <MenuItem id='potentialPrice' key='potentialPrice' value='potentialPrice'>
            <TranslatedContent code='potentialPrice' defaultValue='Fair Value' />
          </MenuItem>
          <MenuItem id='avgAge' key='avgAge' value='avgAge'>
            <TranslatedContent code='avgAge' defaultValue='Avg Age' />
          </MenuItem>
          <MenuItem id='residualValue' key='residualValue' value='residualValue'>
            <TranslatedContent code='residualValue' defaultValue='Residual Value' />
          </MenuItem>
          <MenuItem id='salaryNet' key='salaryNet' value='salaryNet'>
            <TranslatedContent code='salaryNet' defaultValue='Net Salary' />
          </MenuItem>
          <MenuItem id='numOfNationalPlayers' key='numOfNationalPlayers' value='numOfNationalPlayers'>
            <TranslatedContent code='numOfNationalPlayers' defaultValue='National Players' />
          </MenuItem>
          <MenuItem id='shareOfForeignPlayers' key='shareOfForeignPlayers' value='shareOfForeignPlayers'>
            <TranslatedContent code='shareOfForeignPlayers' defaultValue='Share of Foreign Players' />
          </MenuItem>
          <MenuItem id='shareOfU23Players' key='shareOfU23Players' value='shareOfU23Players'>
            <TranslatedContent code='shareOfU23Players' defaultValue='Share of U23 Players' />
          </MenuItem>
        </StdSelect>
      </Box>
    </Stack>
  )
}

export default ClubComparablesBarChartButtons