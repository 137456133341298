import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material'
import { Fragment, useEffect, useRef, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import { ReactComponent as CancelCircleIcon } from '../../icons/cancel_circle.svg'
import playersService from '../../services/playersService'
import { transitionWrapper } from '../../services/transitionWrapper'
import watchlistsService from '../../services/watchlistsService'
import { editPersonalWatchlist } from '../../slices/watchlistsSlice'
import StdPlayersTable from '../players/stdPlayersTable'
import TranslatedContent from '../translations/translatedContent'

const SuggestedPlayer = (props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      minWidth: 175,
      minHeight: 25,
      maxHeight: 50,
      borderRadius: 10,
      mx: 2,
      my: { xs: .5, md: 0 },
      pl: 2,
      pr: 1
    }}>
    <Typography
      sx={{
        fontSize: '1.4rem',
        fontWeight: 500,
        mr: 2
      }}>
      {props.player.name}
    </Typography>
    <Box sx={{ display: 'flex' }}>
      <IconButton onClick={() => props.onDiscard(props.player.playerId)}>
        <CancelCircleIcon fill='#707070' width='24px' height='24px' />
      </IconButton>
      <IconButton onClick={() => props.onAddToWatchlist(props.player.playerId)}>
        <AddCircleIcon fill='#04A8A3' width='24px' height='24px' />
      </IconButton>
    </Box>
  </Box>
)

const PersonalWatchlist = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()
  const isBreakpointXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  const [watchlist, setWatchlist] = useState(props.watchlist)
  const watchlistStateRef = useRef()
  watchlistStateRef.current = watchlist

  const [watchlistPlayers, setWatchlistPlayers] = useState([])
  const [filteredWPlayers, setFilteredWPlayers] = useState(watchlistPlayers)
  const [wPlayersNameFilter, setWPlayersNameFilter] = useState()
  const [sorting, setSorting] = useState([])

  const [isPendingSuggestedPlayers, startSuggestedPlayersTransition] = useTransition()
  const [suggestedPlayers, setSuggestedPlayers] = useState([])
  const [suggestedPlayersToShow, setSuggestedPlayersToShow] = useState(0)
  const [suggestedPlayersBlacklist, setSuggestedPlayersBlacklist] = useState([])

  const [playerIdToBeRemoved, setPlayerIdToBeRemoved] = useState()

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [alertText, setAlertText] = useState('')
  const [alertConfirmAction, setAlertConfirmAction] = useState('')

  useEffect(() => {
    setWatchlist(props.watchlist)
  }, [props.watchlist])

  useEffect(() => {
    let players = Object.values(watchlist.players)
    let enrichedPlayers = []
    watchlist.filter.players
      .forEach(fp => {
        let found = players.find(p => p.playerId === fp.id)
        if (found) {
          enrichedPlayers.push({ ...found, position: fp.position })
        }
      })
    setWatchlistPlayers(enrichedPlayers)
  }, [userToken, watchlist])

  useEffect(() => {
    if (watchlistPlayers.length > 0) {
      startSuggestedPlayersTransition(() => {
        const potentialPriceSort = {
          field: 'potentialPrice',
          sort: 'desc'
        }
        let spFilter = {}
        let currentPosition = []
        let minAge = 0
        let maxAge = 0
        let minPotentialPrice = 0
        let maxPotentialPrice = 0
        let currentClubCluster = []
        watchlistPlayers.forEach((p) => {
          currentPosition.push(p.currentPosition)
          minAge = Math.min(minAge, p.age)
          maxAge = Math.max(maxAge, p.age)
          minPotentialPrice = Math.min(minPotentialPrice, p.potentialPrice)
          maxPotentialPrice = Math.max(maxPotentialPrice, p.potentialPrice)
          currentClubCluster.push(p.currentClubCluster)
        })
        spFilter = {
          currentPosition: currentPosition,
          ageFrom: minAge === maxAge ? 0 : minAge,
          ageTo: maxAge,
          potentialPriceFrom: minPotentialPrice === maxPotentialPrice ? 0 : minPotentialPrice,
          potentialPriceTo: maxPotentialPrice,
          currentClubCluster: currentClubCluster
        }
        transitionWrapper(playersService.getPlayers(userToken, dispatch, null, spFilter, potentialPriceSort),
          res => {
            if (res.status === 200) {
              setSuggestedPlayers(res.data.data)
            }
          })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, watchlistPlayers])

  useEffect(() => {
    if (isBreakpointXl) {
      setSuggestedPlayersToShow(5)
    } else {
      setSuggestedPlayersToShow(3)
    }
  }, [isBreakpointXl])

  useEffect(() => {
    if (wPlayersNameFilter) {
      let fwp = watchlistPlayers.filter(p => p.name.toLowerCase().includes(wPlayersNameFilter.toLowerCase()))
      setFilteredWPlayers(fwp)
    } else {
      setFilteredWPlayers(watchlistPlayers)
    }
  }, [watchlistPlayers, wPlayersNameFilter])

  useEffect(() => {
    if (sorting && sorting.length > 0) {
      let sortedFp = [...filteredWPlayers]
      let sortingField = sorting[0].id
      sortedFp.sort((a, b) => {
        let order = 0
        sorting[0].desc === true ? order = 1 : order = -1
        if (typeof a[sortingField] === 'string' && typeof b[sortingField] === 'string') {
          return (b[sortingField].localeCompare(a[sortingField])) * order
        } else {
          return (b[sortingField] - a[sortingField]) * order
        }
      })
      setFilteredWPlayers(sortedFp)
    } else {
      setFilteredWPlayers(watchlistPlayers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  const confirmRemovePlayerFromWatchlist = (playerId) => {
    let positionCount = 1
    let players = watchlist.filter.players.reduce(
      (res, curr) => {
        if (curr.id !== playerId) {
          res.push({ ...curr, position: positionCount++ })
        }
        return res
      }, [])
    watchlistsService.updateWatchlist(userToken, dispatch, watchlist.id, {
      players: JSON.stringify(players)
    })
      .then(res => {
        if (res.status === 200) {
          setWatchlist(res.data.data)
          dispatch(editPersonalWatchlist(res.data.data))
        }
      })
      .catch(err => console.error(err))
  }

  const handleChangeWatchlistName = (newName) => {
    watchlistsService.updateWatchlist(userToken, dispatch, watchlist.id, {
      name: newName
    })
      .then(res => {
        if (res.status === 200) {
          setWatchlist(res.data.data)
          dispatch(editPersonalWatchlist(res.data.data))
        }
      })
      .catch(err => console.error(err))
  }

  const handleChangeWatchlistPosition = (newPosition) => {
    if (Number.isNaN(newPosition) || newPosition <= 0) {
      props.reloadWatchlists()
    } else {
      watchlistsService.updateWatchlist(userToken, dispatch, watchlist.id, {
        default: newPosition === 1,
        position: newPosition
      })
        .then(res => {
          if (res.status === 200) {
            setWatchlist(res.data.data)
            dispatch(editPersonalWatchlist(res.data.data))
            props.reloadWatchlists()
          }
        })
        .catch(err => console.error(err))
    }
  }

  const handleMarkWatchlistAsDefault = () => {
    watchlistsService.updateWatchlist(userToken, dispatch, watchlist.id, {
      position: 1,
      default: true
    })
      .then(res => {
        if (res.status === 200) {
          setWatchlist(res.data.data)
          dispatch(editPersonalWatchlist(res.data.data))
          props.reloadWatchlists()
        }
      })
      .catch(err => console.error(err))
  }

  const removePlayerFromWatchlist = (playerId) => {
    if (Object.keys(watchlistStateRef.current.players).length === 1) {
      setAlertTitle('Remove Last Player')
      setAlertText('Deleting this player will delete the entire watchlist. Do you want to proceed?')
      setAlertConfirmAction('REMOVE_WATCHLIST')
      setAlertOpen(true)
    } else {
      setPlayerIdToBeRemoved(playerId)
      setAlertTitle('Remove Player')
      setAlertText('Remove player from watchlist?')
      setAlertConfirmAction('REMOVE_PLAYER')
      setAlertOpen(true)
    }
  }

  const removeWatchlist = () => {
    setAlertTitle('Remove Watchlist')
    setAlertText('Remove watchlist?')
    setAlertConfirmAction('REMOVE_WATCHLIST')
    setAlertOpen(true)
  }

  const handleAlertConfirm = () => {
    if (alertConfirmAction === 'REMOVE_PLAYER') {
      confirmRemovePlayerFromWatchlist(playerIdToBeRemoved)
    } else if (alertConfirmAction === 'REMOVE_WATCHLIST') {
      props.deleteWatchlist(watchlist.id)
    }
    setAlertOpen(false)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const handleWPlayersFilterByName = (searchText) => {
    setWPlayersNameFilter(searchText)
  }

  const handleDiscardSuggestedPlayer = (playerId) => {
    setSuggestedPlayersBlacklist([...suggestedPlayersBlacklist, playerId])
  }

  const handleAddToWatchlist = (playerId) => {
    let players = [...watchlist.filter.players]
    players.push({
      id: playerId,
      position: Number(players[players.length - 1].position) + 1
    })
    watchlistsService.updateWatchlist(userToken, dispatch, watchlist.id, {
      players: JSON.stringify(players)
    })
      .then(res => {
        if (res.status === 200) {
          setWatchlist(res.data.data)
          dispatch(editPersonalWatchlist(res.data.data))
        }
      })
      .catch(err => console.error(err))
  }

  const handleChangePlayerPosition = (playerId, newPosition) => {
    let orderedPlayers = []
    let positionOffset = 0
    watchlistStateRef.current.filter.players
      .filter(p => p.id !== playerId)
      .forEach((p, idx) => {
        if (idx + 1 === newPosition) {
          orderedPlayers.push({ id: playerId, position: newPosition })
          positionOffset = 1
        }
        orderedPlayers.push({ id: p.id, position: idx + 1 + positionOffset })
      })
    if (positionOffset === 0 && newPosition === watchlistStateRef.current.filter.players.length) {
      orderedPlayers.push({ id: playerId, position: newPosition })
    }
    watchlistsService.updateWatchlist(userToken, dispatch, watchlistStateRef.current.id, {
      players: JSON.stringify(orderedPlayers)
    })
      .then(res => {
        if (res.status === 200) {
          setWatchlist(res.data.data)
          dispatch(editPersonalWatchlist(res.data.data))
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <Fragment>
      <Box sx={{ mt: 1, mb: 5 }}>
        <StdPlayersTable
          title={watchlist.name}
          players={filteredWPlayers}
          rowId={(player) => player.playerId}
          rowCount={Object.keys(watchlistStateRef.current.players).length}
          filterByName={handleWPlayersFilterByName}
          enablePagination={false}
          enableBottomToolbar={false}
          sorting={sorting}
          onSortingChange={setSorting}
          isWatchlist
          watchlistPlayers={watchlistStateRef.current.players}
          isDefaultWatchlist={watchlist.default}
          watchlistPosition={watchlist.position}
          onChangeWatchlistPosition={handleChangeWatchlistPosition}
          onChangeWatchlistName={handleChangeWatchlistName}
          onMarkWatchlistAsDefault={handleMarkWatchlistAsDefault}
          addPlayerToWatchlist={handleAddToWatchlist}
          removePlayerFromWatchlist={removePlayerFromWatchlist}
          onChangePlayerPosition={handleChangePlayerPosition}
          removeWatchlist={removeWatchlist}
          disableFilters
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: { xs: 'center', md: 'flex-end', lg: 'center' }, justifyContent: { xs: 'center', lg: 'flex-end' }, mt: -3, mb: 5 }}>
        <Typography sx={{ color: '#04A8A3', fontSize: '1.8rem', fontWeight: 600, textTransform: 'uppercase', mr: { xs: 0, md: 3 }, mb: { xs: 1, lg: 0 } }}>
          <TranslatedContent code='suggested' defaultValue='Suggested' />
        </Typography>
        {isPendingSuggestedPlayers ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box> :
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            {suggestedPlayers
              .filter(sp => !watchlistPlayers.some(wp => wp.playerId === sp.playerId))
              .filter(sp => !suggestedPlayersBlacklist.includes(sp.playerId))
              .splice(0, suggestedPlayersToShow)
              .map((sp, idx) => <SuggestedPlayer key={idx} player={sp} onDiscard={handleDiscardSuggestedPlayer} onAddToWatchlist={handleAddToWatchlist} />)}
          </Box>}
      </Box>
      <Dialog open={alertOpen} onClose={handleAlertClose}>
        <DialogTitle>
          <TranslatedContent code={alertTitle} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TranslatedContent code={alertText} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: '#04A8A3',
              backgroundColor: 'transparent',
              width: 115,
              height: 24,
              border: '2px solid #04A8A3',
              borderRadius: 25,
              m: 2,
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#04A8A3'
              }
            }}
            onClick={handleAlertConfirm} autoFocus>
            <TranslatedContent code='confirm' defaultValue='Confirm' />
          </Button>
          <Button
            sx={{
              color: '#666666',
              backgroundColor: 'transparent',
              width: 115,
              height: 24,
              border: '2px solid #666666',
              borderRadius: 25,
              m: 2,
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#666666'
              }
            }}
            onClick={handleAlertClose}>
            <TranslatedContent code='cancel' defaultValue='Cancel' />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default PersonalWatchlist