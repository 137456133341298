import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ReactComponent as SoccerField } from '../../../icons/soccer_field.svg'
import TranslatedContent from '../../translations/translatedContent'
import { camelize } from '../formatters/dataFormatters'
import './stdRoleFilter.css'

const StdRole = (props) => (
  <Fragment>
    <Tooltip title={<TranslatedContent code={camelize(props.roleName)} defaultValue={props.roleName} />}>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
          backgroundColor: props.active ? '#04A8A3' : '#31495A',
          width: 30,
          height: 30,
          borderRadius: 1, '&:hover': { color: '#000000', backgroundColor: '#A1CBC2', cursor: 'pointer' },
          ...props.sx
        }}
        onClick={(e) => props.selectRole(e, props.roleName)}>
        <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
          {props.roleCode}
        </Typography>
      </Box>
    </Tooltip>
  </Fragment>
)

const StdRoleFilter = forwardRef((props, ref) => {

  const [selectedRoles, setSelectedRoles] = useState([])

  const rolesAreas = {
    central_defense: ['Central Defender', 'Left Centre Back', 'Right Centre Back'],
    central_midfield: ['Defensive Midfielder', 'Central Midfielder'],
    central_attack: ['Centre Attacking Midfielder', 'Left Attacking Midfielder', 'Right Attacking Midfielder', 'Second Striker'],
    left_wing: ['Left Back', 'Left Midfielder', 'Left Wing Back'],
    right_wing: ['Right Back', 'Right Midfielder', 'Right Wing Back'],
    forwarders: ['Left Winger', 'Right Winger', 'Centre Forward']
  }

  useEffect(() => {

    const checkSelectedArea = (areaId) => {
      if (rolesAreas[areaId].every(role => selectedRoles.includes(role))) {
        document.getElementById(areaId).querySelector('.role-group').classList.add('selected')
      } else {
        document.getElementById(areaId).querySelector('.role-group').classList.remove('selected')
      }
    }

    checkSelectedArea('central_defense')
    checkSelectedArea('central_midfield')
    checkSelectedArea('central_attack')
    checkSelectedArea('left_wing')
    checkSelectedArea('right_wing')
    checkSelectedArea('forwarders')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoles])

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedRoles([])
    }
  }))

  const toggleRolesAreaSelection = (areaId) => {
    let tmpRoles
    if (rolesAreas[areaId].every(role => selectedRoles.includes(role))) {
      let toBeRemoved = []
      rolesAreas[areaId].forEach(role => {
        if (selectedRoles.includes(role)) toBeRemoved.push(role)
      })
      tmpRoles = selectedRoles.filter(sr => !toBeRemoved.includes(sr))
    } else {
      let toBeAdded = []
      rolesAreas[areaId].forEach(role => {
        if (!selectedRoles.includes(role)) toBeAdded.push(role)
      })
      tmpRoles = [...selectedRoles, ...toBeAdded]
    }
    setSelectedRoles(tmpRoles)
    props.onChange(prepareFilter(tmpRoles))
  }

  const handleSelectRole = (e, role) => {
    e.preventDefault()
    let tmpRoles
    if (selectedRoles.includes(role)) {
      tmpRoles = selectedRoles.filter(sr => sr !== role)
    } else {
      tmpRoles = [...selectedRoles, role]
    }
    setSelectedRoles(tmpRoles)
    props.onChange(prepareFilter(tmpRoles))
  }

  const handleDeselectAll = (e) => {
    e.preventDefault()
    setSelectedRoles([])
    props.onChange(prepareFilter([]))
  }

  const prepareFilter = (roles) => {
    let filterContent = {}
    filterContent[props.filterName] = roles
    let filter = {
      filterName: 'role',
      filterValues: roles.join(', '),
      filterContent: filterContent
    }
    return filter
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', fontWeight: 600 }}>
          <TranslatedContent code='role' defaultValue='Role' />
        </Typography>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <SoccerField onClick={e => toggleRolesAreaSelection(e.target.parentNode.id)} />
        {/* Goalkeeper */}
        <StdRole roleCode='GK' roleName='Goalkeeper' active={selectedRoles.includes('Goalkeeper')} selectRole={handleSelectRole} sx={{ top: 108, left: 15 }} />
        {/* Defense */}
        <StdRole roleCode='CD' roleName='Central Defender' active={selectedRoles.includes('Central Defender')} selectRole={handleSelectRole} sx={{ top: 108, left: 75 }} />
        <StdRole roleCode='LCB' roleName='Left Centre Back' active={selectedRoles.includes('Left Centre Back')} selectRole={handleSelectRole} sx={{ top: 74, left: 110 }} />
        <StdRole roleCode='RCB' roleName='Right Centre Back' active={selectedRoles.includes('Right Centre Back')} selectRole={handleSelectRole} sx={{ top: 142, left: 110 }} />
        {/* Back Midfield */}
        <StdRole roleCode='DM' roleName='Defensive Midfielder' active={selectedRoles.includes('Defensive Midfielder')} selectRole={handleSelectRole} sx={{ top: 108, left: 165 }} />
        <StdRole roleCode='CM' roleName='Central Midfielder' active={selectedRoles.includes('Central Midfielder')} selectRole={handleSelectRole} sx={{ top: 108, left: 208 }} />
        {/* Forward Midfield */}
        <StdRole roleCode='CAM' roleName='Centre Attacking Midfielder' active={selectedRoles.includes('Centre Attacking Midfielder')} selectRole={handleSelectRole} sx={{ top: 108, left: 258 }} />
        <StdRole roleCode='LAM' roleName='Left Attacking Midfielder' active={selectedRoles.includes('Left Attacking Midfielder')} selectRole={handleSelectRole} sx={{ top: 70, left: 282 }} />
        <StdRole roleCode='RAM' roleName='Right Attacking Midfielder' active={selectedRoles.includes('Right Attacking Midfielder')} selectRole={handleSelectRole} sx={{ top: 146, left: 282 }} />
        <StdRole roleCode='SS' roleName='Second Striker' active={selectedRoles.includes('Second Striker')} selectRole={handleSelectRole} sx={{ top: 108, left: 304 }} />
        {/* Left Wing */}
        <StdRole roleCode='LB' roleName='Left Back' active={selectedRoles.includes('Left Back')} selectRole={handleSelectRole} sx={{ top: 17, left: 120 }} />
        <StdRole roleCode='LM' roleName='Left Midfielder' active={selectedRoles.includes('Left Midfielder')} selectRole={handleSelectRole} sx={{ top: 17, left: 196 }} />
        <StdRole roleCode='LWB' roleName='Left Wing Back' active={selectedRoles.includes('Left Wing Back')} selectRole={handleSelectRole} sx={{ top: 17, left: 282 }} />
        {/* Right Wing */}
        <StdRole roleCode='RB' roleName='Right Back' active={selectedRoles.includes('Right Back')} selectRole={handleSelectRole} sx={{ top: 202, left: 120 }} />
        <StdRole roleCode='RM' roleName='Right Midfielder' active={selectedRoles.includes('Right Midfielder')} selectRole={handleSelectRole} sx={{ top: 202, left: 196 }} />
        <StdRole roleCode='RWB' roleName='Right Wing Back' active={selectedRoles.includes('Right Wing Back')} selectRole={handleSelectRole} sx={{ top: 202, left: 282 }} />
        {/* Attack */}
        <StdRole roleCode='LW' roleName='Left Winger' active={selectedRoles.includes('Left Winger')} selectRole={handleSelectRole} sx={{ top: 17, left: 348 }} />
        <StdRole roleCode='RW' roleName='Right Winger' active={selectedRoles.includes('Right Winger')} selectRole={handleSelectRole} sx={{ top: 202, left: 348 }} />
        <StdRole roleCode='CF' roleName='Centre Forward' active={selectedRoles.includes('Centre Forward')} selectRole={handleSelectRole} sx={{ top: 108, left: 358 }} />
      </Box>
      <Box sx={{ display: selectedRoles.length > 0 ? 'flex' : 'none', justifyContent: 'center', width: '100%', mt: 2 }}>
        <Button
          sx={{
            color: '#FFFFFF',
            fontSize: '1.3rem',
            backgroundColor: '#707070',
            height: 30,
            width: 150,
            borderRadius: 28,
            '&:hover': {
              backgroundColor: '#707070',
            }
          }}
          onClick={handleDeselectAll}>
          <TranslatedContent code='deselectAll' defaultValue='Deselect All' />
        </Button>
      </Box>
    </Box>
  )
})

export default StdRoleFilter