import { Box, Divider, Grid, Typography } from '@mui/material'
import TranslatedContent from '../translations/translatedContent'
import LeagueComparablesIndicators from './leagueComparablesIndicators'
import LeagueComparablesPotentialPrice from './leagueComparablesPotentialPrice'
import LeagueComparablesTopClubs from './leagueComparablesTopClubs'

const LeagueComparables = (props) => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Typography sx={{
        color: '#FFFFFF',
        fontSize: '3rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}>
        <TranslatedContent code='comparables' defaultValue='Comparables' />
      </Typography>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 3, width: '100%' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} xl={8}>
          <LeagueComparablesPotentialPrice league={props.league} />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <LeagueComparablesTopClubs league={props.league} />
        </Grid>
        <Grid item xs={12}>
          <LeagueComparablesIndicators league={props.league} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LeagueComparables