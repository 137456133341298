import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loaded: false,
  personal: [],
  recommended: []
}

export const watchlistsSlice = createSlice({
  name: 'watchlists',
  initialState,
  reducers: {
    setPersonalWatchlists(state, action) {
      state.personal = action.payload
      state.loaded = true
    },
    deletePersonalWatchlist(state, action) {
      let idx = state.personal.findIndex((w) => w.id === action.payload)
      state.personal.splice(idx, 1)
    },
    editPersonalWatchlist(state, action) {
      let idx = state.personal.findIndex((w) => w.id === action.payload.id)
      state.personal.splice(idx, 1, action.payload)
    },
    setRecommendedWatchlists(state, action) {
      state.recommended = action.payload
    }
  },
})

export const {
  setPersonalWatchlists,
  deletePersonalWatchlist,
  editPersonalWatchlist,
  setRecommendedWatchlists
} = watchlistsSlice.actions